import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';
// Brief: Custom hook for managing SignalR connections to receive live data updates
// Constants
const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_DELAY = 2000;
// Message type definitions
interface MessageData {
  Event: number;
  Val: string;
  iVal: number;
}
export interface LiveDataMessageObject {
  Obj: number;
  Date: string;
  Idx: number;
  Src: number;
  Data: MessageData[];
}
type LiveDataMessage = string | LiveDataMessageObject;
// Connection status
type ConnectionStatus = 'disconnected' | 'connecting' | 'connected' | 'error';
// Hook options
interface HookOptions {
  autoDisconnect?: boolean;
  consoleLog?: boolean;
  onMessage?: (message: { message: LiveDataMessage; timestamp: string }) => void;
}
// Hook result interface
interface ControllersLiveDataResult {
  connection: signalR.HubConnection | null;
  connectionStatus: ConnectionStatus;
  errorMessage: string;
  connect: (url: string, apiKey: string, objectIds: string[]) => Promise<void>;
  disconnect: () => Promise<void>;
  isEventObjectMessage: (message: LiveDataMessage) => message is LiveDataMessageObject;
}

export function useControllersLiveData(options: HookOptions = {}): ControllersLiveDataResult {
  const { autoDisconnect = false, consoleLog = false } = options;
  // State: Tracks the current connection status (disconnected, connecting, connected, error)
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');

  // State: Stores the latest error message for display or debugging
  const [errorMessage, setErrorMessage] = useState<string>('');

  // State: Holds the most recent message received with its timestamp
  // const [latestMessage, setLatestMessage] = useState<{ message: LiveDataMessage; timestamp: string } | null>(null);
  // Ref: Stable reference to the SignalR HubConnection instance
  const connectionRef = useRef<signalR.HubConnection | null>(null);

  // Ref: Tracks active event listeners to prevent duplicates
  const activeListenersRef = useRef<Set<string>>(new Set());
  // Memoized logger function, enabled only if consoleLog is true
  const log = useCallback(
    (message: string, data?: any) => {
      if (consoleLog) {
        console.log(`[useControllersLiveData] ${message}`, data || '');
      }
    },
    [consoleLog]
  );
  // Type guard to check if a message is a valid LiveDataMessageObject
  const isEventObjectMessage = useCallback((message: LiveDataMessage): message is LiveDataMessageObject => {
    if (typeof message !== 'object' || message === null) return false;
    const obj = message as LiveDataMessageObject;
    return (
      typeof obj.Obj === 'number' &&
      typeof obj.Date === 'string' &&
      typeof obj.Idx === 'number' &&
      typeof obj.Src === 'number' &&
      Array.isArray(obj.Data) &&
      obj.Data.every(
        (item) => typeof item.Event === 'number' && typeof item.Val === 'string' && typeof item.iVal === 'number'
      )
    );
  }, []);
  // Handles incoming messages, parsing and updating latestMessage state
  const handleMessage = useCallback(
    (message: string) => {
      log('Received message:', message);
      const timestamp = new Date().toISOString();
      let parsedMessage: LiveDataMessage;
      try {
        parsedMessage = JSON.parse(message);
        log(isEventObjectMessage(parsedMessage) ? 'Parsed as object' : 'Parsed as string');
      } catch {
        log('JSON parse failed, treating as string');
        parsedMessage = message;
      }
      options.onMessage({ message: parsedMessage, timestamp });
    },
    [isEventObjectMessage, log]
  );
  // Disconnects the current connection and cleans up
  const disconnect = useCallback(async () => {
    const conn = connectionRef.current;
    if (!conn) {
      log('No connection to disconnect');
      return;
    }
    try {
      log('Disconnecting');
      await conn.stop();
      connectionRef.current = null;
      setConnectionStatus('disconnected');
      setErrorMessage('');
      activeListenersRef.current.clear();
      log('Disconnected successfully');
    } catch (err) {
      const errorMsg = `Disconnect error: ${(err as Error).message}`;
      console.error(errorMsg);
      setErrorMessage(errorMsg);
      setConnectionStatus('error');
    }
  }, [log]);
  // Establishes a new SignalR connection with the provided parameters
  const connect = useCallback(
    async (url: string, apiKey: string, objectIds: string[]) => {
      if (connectionRef.current?.state === signalR.HubConnectionState.Connected) {
        log('Already connected');
        return;
      }
      if (!objectIds.some((id) => id.trim())) {
        log('Invalid object IDs');
        setErrorMessage('At least one valid Object ID is required');
        return;
      }
      await disconnect(); // Ensure clean slate
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${url}?api-key=${apiKey}&objectIds=${objectIds.join(',')}`, {
          transport: signalR.HttpTransportType.ServerSentEvents,
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            const attempt = retryContext.previousRetryCount + 1;
            log(`Reconnect attempt ${attempt}/${MAX_RECONNECT_ATTEMPTS}`);
            return attempt > MAX_RECONNECT_ATTEMPTS ? null : RECONNECT_DELAY;
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();
      // Register listeners only once
      if (!activeListenersRef.current.has('ReceiveMessage')) {
        hubConnection.on('ReceiveMessage', handleMessage);
        activeListenersRef.current.add('ReceiveMessage');
      }
      hubConnection.onclose((error) => {
        log('Connection closed:', error?.message);
        connectionRef.current = null;
        setConnectionStatus('disconnected');
        setErrorMessage(error ? `Connection closed: ${error.message}` : '');
      });
      hubConnection.onreconnecting(() => {
        log('Reconnecting...');
        setConnectionStatus('connecting');
        setErrorMessage('Reconnecting...');
      });
      hubConnection.onreconnected(() => {
        log('Reconnected');
        setConnectionStatus('connected');
        setErrorMessage('');
      });
      try {
        log('Starting connection');
        await hubConnection.start();
        connectionRef.current = hubConnection;
        setConnectionStatus('connected');
        log('Connected successfully');
      } catch (err) {
        const errorMsg = `Connection failed: ${(err as Error).message}`;
        console.error(errorMsg);
        setConnectionStatus('error');
        setErrorMessage(errorMsg);
      }
    },
    [handleMessage, log, disconnect]
  );
  // Effect for managing connection cleanup on unmount if autoDisconnect is true
  useEffect(() => {
    log('Effect: Mounted');
    return () => {
      if (autoDisconnect) {
        log('Effect: Cleanup on unmount');
        disconnect();
      }
    };
  }, [autoDisconnect, disconnect, log]);
  return {
    connection: connectionRef.current,
    connectionStatus,
    errorMessage,
    connect,
    disconnect,
    isEventObjectMessage,
  };
}
