import { Box, CircularProgress } from '@mui/material';
import * as ExcelJS from 'exceljs';
import { useState } from 'react';
import localStorageService from 'shared/localStorage';
import { MobileButton } from '../../ui';
import { Icon, Text } from 'shared/ui';
import { excelIcon } from '../../assets';
import { t } from 'i18next';
import { useFormatXlsxData } from './helpers';
import { SortedSiteData } from '../../ISSExport/components/ExportToPDF';
import { ISSSite } from '../../ISSModels';
import { useSiteMaterials } from '../../hooks';
import { useAppSelector } from 'shared/hooks';

export type SiteMaterialsItems = {
  siteName: string;
  currentState: string;
  priority: string;
  totalPrice: string;
};

interface Props {
  sheetName: string;
  fileName: string;
  buttonText: string;
  headerItemsPrefix: string;
  dateAndTimeColumnIndexes?: number[];
  ISSSitesFiltered: ISSSite[];
}

export const ExportToExcel = ({ sheetName, fileName, buttonText, ISSSitesFiltered }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formatTable } = useFormatXlsxData();
  const { getMaterialLists, getSiteMaterialLists } = useSiteMaterials();
  const isRTL = localStorageService.isRTL;
  const { siteMaterials, materialLists } = useAppSelector((st) => st.ISSSlice);
  const currencyFormat = (value: number) => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const getTotalPrice = (siteId: number) => {
    const currentSiteMaterials = siteMaterials.filter((item) => item.siteID === siteId);
    let price = 0;
    currentSiteMaterials.forEach((item) => {
      const currentMaterial = materialLists.find((el) => el.id === item.materialID);
      if (!currentMaterial) return;
      price += currentMaterial.unitCost * item.quantity;
    });
    return price;
  };

  const getTableData = async () => {
    const sortedSitesData = [];
    let summaryTotalPrice = 0;
    for (const item of ISSSitesFiltered) {
      const totalPrice = getTotalPrice(item.id);
      const siteInfo = {
        siteName: item.siteName,
        currentState: item.currentState,
        priority: item.priority,
        totalPrice: currencyFormat(totalPrice),
      };
      summaryTotalPrice += totalPrice;
      sortedSitesData.push(siteInfo);
    }

    return { sortedSitesData, summaryTotalPrice };
  };

  const styleCell = (cell) => {
    cell.border = {
      top: { style: 'thin', color: { argb: 'FFC0C0C0' } },
      bottom: { style: 'thin', color: { argb: 'FFC0C0C0' } },
      left: { style: 'thin', color: { argb: 'FFC0C0C0' } },
      right: { style: 'thin', color: { argb: 'FFC0C0C0' } },
    };

    if (isRTL) {
      // isRTL ? 0 : 1
      cell.alignment = { textRotation: 0, readingOrder: isRTL ? 1 : 0, horizontal: 'right' };
    }
  };

  const startExport = async () => {
    setIsLoading(true);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName, {
      views: [{ state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2', activeCell: 'A2', rightToLeft: isRTL }],
    });

    const { sortedSitesData, summaryTotalPrice } = await getTableData();
    const tableData = sortedSitesData;
    const exports = formatTable(tableData);

    const allHeaderNames = Object.keys(exports[0]);
    const selectedIndexes = allHeaderNames;
    let headerNames = allHeaderNames;
    if (selectedIndexes.length) {
      headerNames = allHeaderNames.filter((item, index) => {
        return selectedIndexes.includes(item);
      });
    }

    worksheet.columns = headerNames.map((header, colIndex) => {
      return {
        header,
        key: header,
      };
    });
    worksheet.columns.forEach((column) => {
      column.width = column.header.length < 16 ? 16 : column.header.length;
    });

    // Format header row
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0a3e90' } };
    headerNames.forEach((header, colIndex) => {
      const colLetter = String.fromCharCode(65 + colIndex);
      const cell = worksheet.getCell(`${colLetter}1`);
      cell.value = header;
      cell.alignment = { horizontal: 'center' };
    });
    exports.forEach((row, index) => {
      const rowIndex = index + 2;
      Object.keys(row).forEach((key, colIndex) => {
        const colLetter = String.fromCharCode(65 + colIndex);
        const cell = worksheet.getCell(`${colLetter}${rowIndex}`);
        if (colIndex == 3) {
          const cleanValue = row[key].replace(/,/g, '');
          cell.numFmt = '#,##0.00';
          cell.value = Math.round(parseFloat(cleanValue) * 100) / 100;
        } else {
          cell.value = row[key];
        }

        styleCell(cell);
      });
    });

    let rowIndex = exports.length + 2;
    let colLetter = String.fromCharCode(65 + 2);
    let cell = worksheet.getCell(`${colLetter}${rowIndex}`);
    cell.value = t('summary_row_export_table');
    styleCell(cell);

    rowIndex = exports.length + 2;
    colLetter = String.fromCharCode(65 + 3);
    cell = worksheet.getCell(`${colLetter}${rowIndex}`);
    cell.numFmt = '#,##0.00';
    cell.value = Math.round(summaryTotalPrice * 100) / 100;
    styleCell(cell);

    // Enable autofilter on all columns
    const firstCellAddress = worksheet.getCell('A1').address;
    const lastColumnLetter = String.fromCharCode(65 + headerNames.length - 1);
    const lastCellAddress = worksheet.getCell(`${lastColumnLetter}1`).address;
    worksheet.autoFilter = `${firstCellAddress}:${lastCellAddress}`;
    worksheet.properties.defaultRowHeight = 15;

    // Create a Blob object from the workbook data

    await workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;

        // Programmatically trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
      })
      .catch((error) => {
        console.error('Error creating Excel file:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <MobileButton disabled={isLoading} onClick={startExport} sx={{ mb: '16px' }} btnColor="info.main">
        <Icon src={excelIcon} sx={{ mx: '4px' }} />
        <Text sx={{ color: '#1C5B9C' }}>{buttonText} </Text>
      </MobileButton>
    </>
  );
};
