import { useAppDispatch, useToast } from 'shared/hooks';

import useAxios from 'shared/api/useAxios';
import { ChangedSiteMaterials, MaterialLists, SiteMaterials } from 'features/Mobile/ISS/ISSModels';
import { setMaterialLists, setSiteMaterials } from 'features/Mobile/ISS/ISSSlice';
import { t } from 'i18next';

export const useSiteMaterials = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showSuccess } = useToast();
  return {
    getMaterialLists: () =>
      request<undefined, { data: MaterialLists[] }>({
        method: 'get',
        url: `/iss/materialLists`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setMaterialLists(data?.data));
        },
      }),
    getSiteMaterialLists: (siteId?: string) =>
      request<undefined, { data: SiteMaterials[] }>({
        method: 'get',
        url: `/iss/siteMaterials${siteId ? '?siteId=' + siteId : ''}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setSiteMaterials(data?.data));
        },
      }),
    updateSiteMaterials: (siteMaterials: ChangedSiteMaterials[]) =>
      request<undefined, { data: { result: boolean } }>({
        method: 'put',
        url: `/iss/siteMaterials`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
        payload: { siteMaterials },
      }),
    isLoadingSiteMaterials: loading,
  };
};
