import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { Icon, Loader, Row, Text } from '../../../../shared/ui';
import { ISSAutocomplete, ISSMap } from '../Components';
import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import { PRIORITY } from '../ISSSiteManagement/Constants/Index';
import { useISSData } from '../hooks';
import { MobileTextarea } from '../ui/MobileTextarea';
import { useUpdate } from './../ISSSiteManagement/hooks';
import { MobileButton, MobileInput, MobileSelect, MobileTextAndSelect } from './../ui';
import { ISSSiteCreation } from '../ISSModels';
import localStorageService from 'shared/localStorage';
import Layout from '../Components/Layout';
import { lockActiveIcon, lockIcon } from '../assets';

export const CreateSitePage = () => {
  const { t } = useExtendedTranslation();
  const { getISSProjects, getISSSites, addSite, isLoading } = useISSData();
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);
  const [mapKey, setMapKey] = useState<string>('AIzaSyD2X1AoMDzxU-lgYxkcFQFH06kWfre_Z_4');
  const [newCoordinates, setNewCoordinates] = useState<{ lat: string; lng: string } | null>(null);
  const [nameError, setNameError] = useState<string>('');
  const [projectError, setProjectError] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [loadedAutocomplete, setLoadedAutocomplete] = useState<boolean>(false);
  const push = useNavigate();
  const [isActiveLock, setIsActiveLock] = useState<boolean>(false);
  const { ISSProjects } = useAppSelector((st) => st.ISSSlice);
  const allProjects = useMemo(() => {
    return ISSProjects.map((item) => ({
      value: item.id.toString(),
      label: item.projectName,
    }));
  }, [ISSProjects]);

  const {
    updateStatus,
    siteName,
    setSiteName,

    currentPriority,
    setCurrentPriority,

    currentState,
    setCurrentState,

    coordinates,
    setCoordinates,

    currentDescription,
    setDescription,

    currentAdditionalWorks,
    setCurrentAdditionalWorks,

    currentRequiredChanges,
    setCurrentRequiredChanges,
  } = useUpdate();

  useEffect(() => {
    setTimeout(() => {
      setLoadedAutocomplete(true);
    }, 1500);
  }, []);

  useEffect(() => {
    getISSProjects();
    const projectId = searchParams.get('projectId');
    setSelectedProject(projectId);
    setCoordinates({ lat: 0, lng: 0 });
  }, []);

  const onChangeName = (value: string) => {
    setNameError('');
    setSiteName(value);
    setChanged(true);
  };
  const onChangeCurrentState = (value: string) => {
    setCurrentState(value);
    setChanged(true);
  };
  const onChangeDescription = (value: string) => {
    setDescription(value);
    setChanged(true);
  };
  const onChangeRequiredChanges = (value: string) => {
    setCurrentRequiredChanges(value);
    setChanged(true);
  };
  const onChangeAdditionalWorks = (value: string) => {
    setCurrentAdditionalWorks(value);
    setChanged(true);
  };
  const onChangeLatitude = (value: string) => {
    setNewCoordinates({ lat: value, lng: newCoordinates ? newCoordinates.lng : '0' });
    setChanged(true);
  };

  const onChangeLongitude = (value: string) => {
    setNewCoordinates({ lat: newCoordinates ? newCoordinates.lat : '0', lng: value });
    setChanged(true);
  };
  const setValidLatitude = () => {
    const filteredValue = newCoordinates.lat.replace(/[^0-9.,-]/g, '');
    const formattedValue = filteredValue.replace(',', '.');
    const lat = parseFloat(formattedValue);
    if (!isNaN(lat)) {
      setCoordinates({ lat, lng: coordinates ? coordinates.lng : 0 });
      setNewCoordinates({ lat: lat.toString(), lng: newCoordinates ? newCoordinates.lng : '0' });
    }
    setChanged(true);
  };
  const setValidLongitude = () => {
    const filteredValue = newCoordinates.lng.replace(/[^0-9.,-]/g, '');
    const formattedValue = filteredValue.replace(',', '.');
    const lng = parseFloat(formattedValue);
    if (!isNaN(lng)) {
      setCoordinates({ lat: coordinates ? coordinates.lat : 0, lng });
      setNewCoordinates({ lat: newCoordinates ? newCoordinates.lat : '0', lng: lng.toString() });
    }
    setChanged(true);
  };

  const onChangePriority = (value: number) => {
    if (value < 1 || value > 10) return;
    setCurrentPriority(value);
    setChanged(true);
  };
  const onChangeProject = (value: string) => {
    setProjectError('');
    setSelectedProject(value);
    setChanged(true);
  };
  const validationCheckName = (name: string) => {
    if (name.length < 1 || name.length > 128) {
      return false;
    }
    return true;
  };

  const validationProject = (project: string) => {
    if (!project) {
      return false;
    }
    return true;
  };

  const validation = () => {
    if (!validationCheckName(siteName)) {
      setNameError('name_field_error_add_site');
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);

      return false;
    }

    if (!validationProject(selectedProject)) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);

      setProjectError('project_field_error_add_site');
      return false;
    }

    return true;
  };

  const onChangeCoordinates = (position: { lat: number; lng: number }) => {
    setCoordinates(position);
    setNewCoordinates({ lat: position.lat.toString(), lng: position.lng.toString() });
  };

  const getCoordinates = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCoordinates({ lat: latitude, lng: longitude });
          setNewCoordinates({ lat: latitude.toString(), lng: longitude.toString() });
        },
        (error) => {
          console.error('Error getting location:', error.message);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error('Geolocation API notSupport.');
    }
  };

  const handleSaveSite = async () => {
    const isValid = validation();
    if (!isValid) return;
    let name = localStorageService.currentUserName;
    if (!name) {
      name = ' ';
    }
    const siteData: ISSSiteCreation = {
      projectId: parseInt(selectedProject),
      siteName: siteName,
      description: currentDescription,
      currentState: currentState,
      requiredChanges: currentRequiredChanges,
      additionalWorks: currentAdditionalWorks,
      createdBy: name,
      priority: currentPriority,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    };

    const result = await addSite(siteData);
    if (!result) return;
    push(`/mobile/iss/sites?projectId=${selectedProject}`);
  };
  const CURRENT_STATE: { value: string; label: string }[] = [
    {
      value: t('current_state_01_select_param'),
      label: 'current_state_01_select_param',
    },
    {
      value: t('current_state_02_select_param'),
      label: 'current_state_02_select_param',
    },
    {
      value: t('current_state_03_select_param'),
      label: 'current_state_03_select_param',
    },
    {
      value: t('current_state_04_select_param'),
      label: 'current_state_04_select_param',
    },
    {
      value: t('current_state_05_select_param'),
      label: 'current_state_05_select_param',
    },
  ];
  const toggleLock = () => {
    setIsActiveLock(!isActiveLock);
  };

  return (
    <Box>
      <Layout>
        <Header title={t('new_site_edit_site')} withConfirm={changed} />

        <Box sx={{ padding: '0 24px' }}>
          {isLoading && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
                zIndex: '30',
                position: 'fixed',
                top: '0px',
                opacity: '0.7',
                left: '0',
              }}
            >
              <Loader />
            </Box>
          )}
          <Box>
            <MobileInput
              label={`${t('site_name_edit_site')} *`}
              name="siteName"
              error={!!nameError}
              helperText={nameError}
              compact
              onChange={(value) => onChangeName(value.target.value)}
              value={siteName}
            />
          </Box>
          {/* <Box sx={{ marginTop: '20px' }}>
          <MobileSelect
            title={t('project_edit_site')}
            items={allProjects}
            error={!!projectError}
            helperText={projectError}
            noSelectLabel={t('project_placeholder_edit_site')}
            onChange={(value) => onChangeProject(value)}
            value={selectedProject}
          />
        </Box> */}
          <Row spacing={'20px'} sx={{ marginTop: '20px' }}>
            <Box width="calc(30% - 10px)" minWidth="calc(30% - 10px)" maxWidth={'calc(30% - 10px)'}>
              <MobileSelect
                title={t('priority_edit_site')}
                items={PRIORITY}
                error={false}
                noSelectLabel={t('priority_placeholder_edit_site')}
                onChange={(value) => onChangePriority(parseInt(value))}
                value={currentPriority.toString()}
              />
            </Box>
            <Box width="calc(70% - 10px)" minWidth="calc(70% - 10px)" maxWidth={'calc(70% - 10px)'}>
              {/* <MobileTextAndSelect
              title={t('current_state_edit_site')}
              items={CURRENT_STATE}
              noSelectLabel={t('current_state_placeholder_edit_site')}
              onChange={(value) => onChangeCurrentState(value)}
              value={currentState}
              error={false}
              translate
            /> */}
              <MobileSelect
                title={t('current_state_edit_site')}
                items={CURRENT_STATE}
                noSelectLabel={t('current_state_placeholder_edit_site')}
                onChange={(value) => onChangeCurrentState(value)}
                value={currentState}
                error={false}
                translate
              />
            </Box>
          </Row>
          {mapKey && (
            <Row alignItems={'flex-end'}>
              <Box width={'100%'}>
                <ISSAutocomplete
                  getCoordinates={getCoordinates}
                  mapKey={mapKey}
                  onChangeCoordinates={onChangeCoordinates}
                />
              </Box>
              <Icon onClick={toggleLock} sx={{ mx: '12px' }} src={isActiveLock ? lockActiveIcon : lockIcon} />
            </Row>
          )}
          {mapKey && loadedAutocomplete && coordinates && (
            <ISSMap
              mapKey={mapKey}
              value={coordinates}
              onChangeCoordinates={onChangeCoordinates}
              isActiveLock={isActiveLock}
            />
          )}
          {newCoordinates && (
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box width="50%">
                <MobileInput
                  label={`${t('latitude_edit_site')}`}
                  name="latitude"
                  helperText={''}
                  onChange={(value) => onChangeLatitude(value.target.value)}
                  blur={setValidLatitude}
                  value={newCoordinates.lat}
                  error={false}
                />
              </Box>
              <Row sx={{ width: '20px' }} />
              <Box width="50%">
                <MobileInput
                  label={`${t('longitude_edit_site')}`}
                  name="longitude"
                  helperText={''}
                  onChange={(value) => onChangeLongitude(value.target.value)}
                  blur={setValidLongitude}
                  value={newCoordinates.lng}
                  error={false}
                />
              </Box>
            </Box>
          )}

          <MobileInput
            label={`${t('required_changes_edit_site')}`}
            name="requiredChanges"
            error={false}
            helperText={''}
            onChange={(value) => onChangeRequiredChanges(value.target.value)}
            value={currentRequiredChanges}
            autoHeight
          />
          {/* <MobileInput
          label={`${t('additional_works_edit_site')}`}
          name="additionalWorks"
          error={false}
          helperText={''}
          onChange={(value) => onChangeAdditionalWorks(value.target.value)}
          value={currentAdditionalWorks}
          autoHeight
        /> */}
          <MobileTextarea
            label={`${t('description_edit_site')}`}
            name="description"
            error={false}
            helperText={''}
            onChange={(value) => onChangeDescription(value.target.value)}
            value={currentDescription}
          />

          <MobileButton disabled={!!nameError || !!projectError} btnColor="#1C5B9C" onClick={handleSaveSite}>
            <Text>{`${t('save_site')}`}</Text>
          </MobileButton>

          <Footer withConfirm={changed} />
        </Box>
      </Layout>
    </Box>
  );
};
