import { Box } from '@mui/material';
import { BaseInput } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { BaseSelect } from 'features/BaseSelect';

type Props = {
  title?: string;
  onChange?: (value: string) => void;
  value?: string;
  sx?: SxProps;
  type?: string;
  valueForNumberChanging?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
  items: { value: string; label: string }[];
  noSelectLabel?: string;
  translate?: boolean;
  error?: boolean;
  disabled?: boolean;
  updated?: boolean;
  helperText?: string;
  fullWidth?: boolean;
};
export const MobileSelect: FC<Props> = ({
  sx = {},
  value,
  onChange,
  noSelectLabel,
  items,
  translate,
  title,
  error,
  disabled,
  updated,
  helperText,
  fullWidth,
  ...props
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [popoverWidth, setPopoverWidth] = useState<number>(200);
  useEffect(() => {
    const updatePopoverWidth = () => {
      if (selectRef.current) {
        setPopoverWidth(selectRef.current.offsetWidth);
      }
    };

    updatePopoverWidth();

    window.addEventListener('resize', updatePopoverWidth);
    return () => {
      window.removeEventListener('resize', updatePopoverWidth);
    };
  }, []);
  return (
    <Box ref={selectRef} sx={{ width: fullWidth ? '100%' : 'auto' }}>
      <BaseSelect
        sx={{
          borderRadius: '8px',
          width: '100%',
          minWidth: '100%',

          ...sx,
        }}
        sxBtn={{
          borderRadius: '8px',
          height: '40px',
          borderColor: error ? 'red' : updated ? 'green' : 'rgba(0, 0, 0, 0.23)',
        }}
        sxLabel={{
          color: '#212529',
        }}
        error={error}
        title={title}
        value={value}
        onChange={onChange}
        items={items}
        hideAllButton
        noSelectLabel={noSelectLabel}
        hideValue
        formInput
        withoutMargin
        translate={translate}
        popoverMinWidth={`${popoverWidth}px`}
        disabled={disabled}
        helperText={helperText}
        {...props}
      />
    </Box>
  );
};
