import { Box, FormControlLabel, Switch } from '@mui/material';

import { ChangeEvent, FC } from 'react';

type Props = {
  title?: string;
  titleSelected?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value: boolean;
};
export const MobileSwitch: FC<Props> = ({ value, onChange, title, titleSelected }) => {
  return (
    <Box>
      <FormControlLabel
        control={<Switch value={value} onChange={onChange} defaultChecked={value} />}
        label={!value && titleSelected ? titleSelected : title}
      />
    </Box>
  );
};
