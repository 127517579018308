import { Box } from '@mui/material';
import { ISSSite } from 'features/Mobile/ISS/ISSModels';
import { moneyIcon, priceIcon, siteSmall } from 'features/Mobile/ISS/assets';
import { t } from 'i18next';
import { FC, useMemo } from 'react';
import { useAppSelector } from 'shared/hooks';
import { getFormattedDate } from 'shared/lib';
import { Icon, Row, Text } from 'shared/ui';
interface Props {
  site: ISSSite;
  handleClick: () => void;
}

export const SiteCard: FC<Props> = ({ site, handleClick }) => {
  const { ISSSites, siteMaterials, materialLists } = useAppSelector((st) => st.ISSSlice);
  const currencyFormat = (value: number) => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const getTotalPrice = () => {
    const currentSiteMaterials = siteMaterials.filter((item) => item.siteID === site.id);
    let price = 0;
    currentSiteMaterials.forEach((item) => {
      const currentMaterial = materialLists.find((el) => el.id === item.materialID);
      if (!currentMaterial) return;
      price += currentMaterial.unitCost * item.quantity;
    });
    return currencyFormat(price);
  };

  return (
    <Box
      sx={{
        mt: '8px',
        padding: '8px',
        border: '1px, solid',
        borderColor: '#707070',
        borderRadius: '8px',
        boxShadow: '0px 1px 3px 0px #0000001A',

        background: 'white',
      }}
      onClick={handleClick}
    >
      <Box>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={{
              maxWidth: 'calc(100% - 200px)',
            }}
          >
            <Text
              sx={{
                color: 'black',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {site.siteName}
            </Text>
          </Box>

          <Box sx={{ mx: '4px', maxWidth: 'calc(100% - 140px)' }}>
            <Row spacing={'8px'}>
              <Text
                sx={{
                  color: '#6C757D',
                  fontSize: '14px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  '@media (max-width: 380px)': {
                    display: 'none',
                  },
                }}
              >
                {t('creation_date_sites_page')}
              </Text>
              <Text
                sx={{
                  color: '#6C757D',
                  fontSize: '14px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {getFormattedDate(site.creationDate)}
              </Text>
            </Row>
          </Box>
          <Row sx={{ flexGrow: 1 }}></Row>
          <Box
            sx={{
              maxWidth: 'calc(100% - 140px)',
            }}
          >
            <Text
              sx={{
                color: 'black',
                fontWeight: '500',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                mx: '4px',
              }}
            >
              {getTotalPrice()}
            </Text>
          </Box>
          <Icon src={moneyIcon} sx={{ mx: '0px' }} />
        </Row>
        <Row spacing={'8px'} sx={{ mt: '8px' }}>
          <Box sx={{ backgroundColor: '#1C5B9C', borderRadius: '20px', padding: '2px 12px' }}>
            <Row spacing={'8px'}>
              <Text sx={{ fontSize: '14px' }}>{t('priority_sites_page')}</Text>
              <Text sx={{ fontSize: '14px' }}>{site.priority}</Text>
            </Row>
          </Box>
          {site.currentState ? (
            <Box
              sx={{
                maxWidth: 'calc(100% - 150px)',
                backgroundColor: '#28A745',
                borderRadius: '20px',
                padding: '2px 8px',
              }}
            >
              <Text
                sx={{
                  fontSize: '14px',
                  maxWidth: '100%',
                  maxHeight: '24px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {t(site.currentState)}
              </Text>
            </Box>
          ) : (
            <></>
          )}
        </Row>
      </Box>
    </Box>
  );
};
