import { FC, useState } from 'react';
import { Box, Button, InputAdornment, Link } from '@mui/material';
import { AuthInput, BaseBtn, Row, Text } from 'shared/ui';
import { HelloToast } from 'features';
import { useCheckToken, useExtendedTranslation } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { UseLogin } from 'features/Mobile/ISS/hooks';
import { MobileButton, MobileInput } from '../../ui';
import { loginIcon, passwordIcon } from '../../assets';

interface Props {
  hideTitle?: boolean;
}

export const LoginForm: FC<Props> = ({ hideTitle }) => {
  useCheckToken();
  const { t } = useExtendedTranslation();

  const [isError, setIsError] = useState(false);
  const [authInfo, setAuthInfo] = useState({ userName: '', password: '' });

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthInfo((prev) => ({ ...prev, userName: event.target.value }));
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthInfo((prev) => ({ ...prev, password: event.target.value }));
  };

  const { isLoading, loginISS } = UseLogin();

  const onClickSubmit = async () => {
    if (!authInfo.userName || !authInfo.password) {
      setIsError(true);
      return;
    }
    loginISS(authInfo);

    localStorageService.currentUserName = authInfo.userName;
    localStorageService.invalidToken = undefined;
  };

  return (
    <Box component="form" autoComplete="off" sx={{ width: '573px' }}>
      {!hideTitle && (
        <Text
          sx={{
            color: 'primary.main',
            fontSize: '32px',
            lineHeight: '48px',
            fontFamily: 'Noto Sans Hebrew',
            mb: '10px',
          }}
        >
          {t('login_title')}
        </Text>
      )}

      {/* <AuthInput
        name="login"
        value={authInfo.userName}
        onChange={handleChangeUserName}
        error={isError && !authInfo.userName}
        placeholder={`${t('login_username')}`}
        // autoComplete={'nope'}
      />
      <AuthInput
        name="password"
        value={authInfo.password}
        onChange={handleChangePassword}
        error={isError && !authInfo.password}
        placeholder={`${t('login_password')}`}
        type="password"
        // autoComplete={'nope'}
      /> */}

      <MobileInput
        label={t('login_label_login')}
        name="login"
        error={isError && !authInfo.userName}
        helperText={''}
        value={authInfo.userName}
        placeholder={`${t('login_username')}`}
        onChange={handleChangeUserName}
        startAdornment={
          <InputAdornment position="start">
            <Box component="img" src={loginIcon} />
          </InputAdornment>
        }
      />

      <MobileInput
        label={t('password_label_login')}
        name="password"
        error={isError && !authInfo.password}
        helperText={''}
        value={authInfo.password}
        placeholder={`${t('login_password')}`}
        onChange={handleChangePassword}
        type="password"
        startAdornment={
          <InputAdornment position="start">
            <Box component="img" src={passwordIcon} />
          </InputAdornment>
        }
      />

      <MobileButton
        onClick={onClickSubmit}
        loading={isLoading}
        fullWidth
        btnColor="#1C5B9C"
        sx={{
          height: '48px',
          fontSize: '24px',
          fontFamily: 'Noto Sans Hebrew',
          mt: '20px',
        }}
      >
        {t('login_button')}
      </MobileButton>
    </Box>
  );
};
