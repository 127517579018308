import { Box } from '@mui/material';
import { Icon } from 'shared/ui';
import { background } from '../../assets';
import { FC } from 'react';

interface Props {
  login?: boolean;
}

export const Background: FC<Props> = ({ login }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        minWidth: '100%',
        width: '100%',
        bottom: login ? '0' : '40px',
        height: 'auto',
        overflow: 'hidden',
        left: 0,
        zIndex: -1,
      }}
    >
      <Icon sx={{ maxWidth: '140%', minWidth: '140%', marginLeft: '-25%', height: 'auto' }} src={background} />
    </Box>
  );
};
