import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { close_white, cone } from 'shared/assets';
import { BigTitle } from 'shared/ui';

interface Props {
  text?: string;
  height?: string;
  background?: string;
  fontSize?: string;
}
export const UnderConstructionBlock: FC<Props> = ({ text, height, background, fontSize }) => {
  return (
    <Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          height: height ? height : '400px',
          background: background ? background : '#3D86BE',
          marginTop: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: '25px',
          position: 'relative',
        }}
      >
        <Box>
          <img src={cone} />
        </Box>
        <Box>
          <BigTitle sx={{ mr: '25px', color: 'white', fontSize: fontSize ? fontSize : '30px' }}>
            {text ? text : t('date_change_is_not_supported')}
          </BigTitle>
        </Box>
      </Box>
    </Box>
  );
};
