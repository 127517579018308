import { Box, CircularProgress } from '@mui/material';
import * as ExcelJS from 'exceljs';
import { useState } from 'react';
import localStorageService from 'shared/localStorage';
import { export_icon } from '../../shared/assets';
import { BaseLongClickBtn, Icon } from '../../shared/ui';
import { FieldSelectionModal } from './components/FieldSelectionModal';
export type TableTitle<T> = { title: string; key: keyof T; width: number | string };
export type TableTitleSimple = { title: string; key: string; width: number | string };
interface Props<T> {
  tableData: T[];
  sheetName: string;
  fileName: string;
  buttonText: string;
  dateColumnIndexes?: number[];
  stringColumnIndexes?: number[];
  formattedTable: {
    [x: string]: string | number;
  }[];
  tableTitles: TableTitle<T>[] | TableTitleSimple[];
  headerItemsPrefix: string;
  preHeaderItemsPrefix?: string;
  needCutHeaderName?: boolean;
  dateAndTimeColumnIndexes?: number[];
  width?: string;
  sortModel?: { field: string; sort: 'asc' | 'desc' };
}

export const BaseExportOptions = <T,>({
  tableData,
  sheetName,
  fileName,
  dateColumnIndexes,
  stringColumnIndexes,
  formattedTable,
  buttonText,
  tableTitles,
  headerItemsPrefix,
  preHeaderItemsPrefix,
  needCutHeaderName,
  dateAndTimeColumnIndexes,
  width,
  sortModel,
}: Props<T>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenFieldSelectionModal, setIsOpenFieldSelectionModal] = useState<boolean>(false);
  const isRTL = localStorageService.isRTL;
  const styleCell = (cell) => {
    cell.border = {
      top: { style: 'thin', color: { argb: 'FFC0C0C0' } },
      bottom: { style: 'thin', color: { argb: 'FFC0C0C0' } },
      left: { style: 'thin', color: { argb: 'FFC0C0C0' } },
      right: { style: 'thin', color: { argb: 'FFC0C0C0' } },
    };

    if (isRTL) {
      cell.alignment = { textRotation: 0, readingOrder: 'rtl', horizontal: 'right' };
    }
  };
  function sortTable(formattedTable, sortModel) {
    if (sortModel) {
      const { field, sort } = sortModel;

      return formattedTable.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];
        if (valueA instanceof Date && valueB instanceof Date) {
          return sort === 'asc' ? valueA.getTime() - valueB.getTime() : valueB.getTime() - valueA.getTime();
        }

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sort === 'asc' ? valueA - valueB : valueB - valueA;
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return sort === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return 0;
      });
    }

    return formattedTable;
  }

  const formatCellAsDate = (cell, colIndex) => {
    const value = cell.value;

    if (dateAndTimeColumnIndexes && dateAndTimeColumnIndexes.includes(colIndex)) {
      if (typeof value !== 'string') {
        cell.value = null;
        return;
      }

      const localDateTimeString = value;
      const date = new Date(localDateTimeString);

      if (isNaN(date.getTime())) {
        cell.value = null;
        return;
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;

      // Установлюємо значення в клітинку
      cell.value = date; // Потрібно зберігати саму дату, а не рядок
      // Форматуємо її як дату
      cell.numFmt = 'dd.mm.yyyy hh:mm';
    }
    if (dateColumnIndexes && dateColumnIndexes.includes(colIndex)) {
      if (typeof value !== 'string') {
        cell.value = null;
        return;
      }

      const localDateTimeString = value;
      const date = new Date(localDateTimeString);

      if (isNaN(date.getTime())) {
        cell.value = null;
        return;
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedDate = `${day}.${month}.${year}`;
      cell.value = date;
      // cell.value = formattedDate;
      cell.numFmt = 'dd.mm.yyyy';
    }
  };
  const formatCellAsString = (cell, colIndex) => {
    if (stringColumnIndexes.includes(colIndex)) {
      cell.numFmt = '@'; // Set the desired string format
    }
  };
  const getDataFromLocalStorage = () => {
    const data = localStorageService.itemsFieldsToExport;
    return data ? JSON.parse(data) : null;
  };
  const filterSelectedIndexesInColumns = () => {
    const data = getDataFromLocalStorage();
    const result: number[] = [];
    if (!data || !data[sheetName]) {
      tableTitles.forEach((header, index) => {
        result.push(index);
      });
      return result;
    }
    const selectedColumns = data[sheetName];
    tableTitles.forEach((header, index) => {
      if (selectedColumns[header.key]) {
        result.push(index);
      }
    });
    return result;
  };
  const startExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName, {
      views: [{ state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2', activeCell: 'A2', rightToLeft: isRTL }],
    });
    const exports = sortTable(formattedTable, sortModel);

    const allHeaderNames = Object.keys(exports[0]);
    const selectedIndexes = filterSelectedIndexesInColumns();
    let headerNames = allHeaderNames;
    if (selectedIndexes.length) {
      headerNames = allHeaderNames.filter((item, index) => {
        return selectedIndexes.includes(index);
      });
    }

    worksheet.columns = headerNames.map((header, colIndex) => {
      return {
        header,
        key: header,
      };
    });
    worksheet.columns.forEach((column) => {
      column.width = column.header.length < 16 ? 16 : column.header.length;
    });

    // Format header row
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0a3e90' } };
    headerNames.forEach((header, colIndex) => {
      const colLetter = String.fromCharCode(65 + colIndex);
      const cell = worksheet.getCell(`${colLetter}1`);
      cell.value = header;
      cell.alignment = { horizontal: 'center' };
    });

    exports.forEach((row, index) => {
      const rowIndex = index + 2; // Start inserting data from row 2
      Object.keys(row).forEach((key, colIndex) => {
        const index = selectedIndexes.findIndex((item) => item === colIndex);
        if (index < 0) return;

        const colLetter = String.fromCharCode(65 + index);
        const cell = worksheet.getCell(`${colLetter}${rowIndex}`);
        cell.value = row[key];
        styleCell(cell);
        formatCellAsDate(cell, colIndex);
        if (stringColumnIndexes) {
          formatCellAsString(cell, colIndex);
        }
      });
    });
    headerNames.forEach((header, colIndex) => {
      const colLetter = String.fromCharCode(65 + colIndex);
      const cell = worksheet.getCell(`${colLetter}1`);
      cell.value = needCutHeaderName ? header.substring(header.indexOf(' ') + 1) : header;
    });

    // Enable autofilter on all columns
    const firstCellAddress = worksheet.getCell('A1').address;
    const lastColumnLetter = String.fromCharCode(65 + headerNames.length - 1);
    const lastCellAddress = worksheet.getCell(`${lastColumnLetter}1`).address;
    worksheet.autoFilter = `${firstCellAddress}:${lastCellAddress}`;
    worksheet.properties.defaultRowHeight = 15;

    // Create a Blob object from the workbook data

    await workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;

        // Programmatically trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
      })
      .catch((error) => {
        console.error('Error creating Excel file:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const openExportMenu = () => {
    setIsOpenFieldSelectionModal(true);
  };
  const shortClick = () => {
    startExport();
    setIsOpenFieldSelectionModal(false);
  };
  const closeFieldSelectionModal = () => {
    setIsOpenFieldSelectionModal(false);
  };

  return (
    <>
      <BaseLongClickBtn
        onClick={shortClick}
        onLongClick={openExportMenu}
        disabled={!tableData.length}
        sx={{ fontFamily: 'Noto Sans Hebrew', width: width ? width : 'auto' }}
        btnColor="info.main"
      >
        <Box component="span">{buttonText}</Box>
        {isLoading ? (
          <CircularProgress sx={{ ml: '7px' }} size={20} />
        ) : (
          <>
            <Icon src={export_icon} sx={{ ml: '7px' }} />
          </>
        )}
      </BaseLongClickBtn>
      <FieldSelectionModal
        sheetName={sheetName}
        headerItemsPrefix={headerItemsPrefix}
        preHeaderItemsPrefix={preHeaderItemsPrefix}
        tableTitles={tableTitles}
        openModal={isOpenFieldSelectionModal}
        onCloseModal={closeFieldSelectionModal}
        onExport={shortClick}
      />
    </>
  );
};
