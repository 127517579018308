import { useState } from 'react';
import { ISSSiteChanges } from '../../ISSModels';
import { useISSData } from '../../hooks';
import { useToast } from 'shared/hooks';
import { t } from 'i18next';

interface Coordinates {
  lat: number;
  lng: number;
}

export const useUpdate = () => {
  const { showSuccess, showError } = useToast();
  const { editSite } = useISSData();
  const [siteId, setSiteId] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [currentPriority, setCurrentPriority] = useState<number>(1);
  const [currentState, setCurrentState] = useState<string>('');
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null);
  const [currentDescription, setDescription] = useState<string>('');
  const [currentAdditionalWorks, setCurrentAdditionalWorks] = useState<string>('');
  const [currentRequiredChanges, setCurrentRequiredChanges] = useState<string>('');

  const [updateStatus, setUpdateStatus] = useState({
    siteName: { updating: false, updated: false, error: false },
    priority: { updating: false, updated: false, error: false },
    currentState: { updating: false, updated: false, error: false },
    coordinates: { updating: false, updated: false, error: false },
    requiredChanges: { updating: false, updated: false, error: false },
    additionalWorks: { updating: false, updated: false, error: false },
    description: { updating: false, updated: false, error: false },
  });

  const validationCheckName = (name: string) => name.length >= 1 && name.length <= 128;

  const updateField = async (
    field:
      | 'siteName'
      | 'priority'
      | 'currentState'
      | 'coordinates'
      | 'requiredChanges'
      | 'additionalWorks'
      | 'description',
    newValue?: string | Coordinates
  ) => {
    setUpdateStatus((prev) => ({
      ...prev,
      [field]: { updating: true, updated: false, error: false },
    }));

    const result = await saveSiteInfo(field, newValue);

    setUpdateStatus((prev) => ({
      ...prev,
      [field]: { updating: false, updated: result, error: !result },
    }));
  };

  const saveSiteInfo = async (field: string, newValue?: string | Coordinates): Promise<boolean> => {
    if (!coordinates && field != 'coordinates') return false;
    const siteData: ISSSiteChanges = {
      siteName,
      latitude: coordinates ? coordinates.lat : 0,
      longitude: coordinates ? coordinates.lng : 0,
      currentState: currentState,
      description: currentDescription,
      priority: currentPriority,
      requiredChanges: currentRequiredChanges,
      additionalWorks: currentAdditionalWorks,
    };
    if (newValue && field === 'coordinates') {
      const coords = newValue as Coordinates;
      siteData.latitude = coords.lat;
      siteData.longitude = coords.lng;
    } else if (newValue) {
      siteData[field] = newValue;
    }
    try {
      const saveSite = await editSite({ siteID: parseInt(siteId), changes: siteData });
      return !!saveSite;
    } catch (error) {
      showError(t('error.savingSite'));
      return false;
    }
  };

  return {
    siteId,
    setSiteId,

    siteName,
    setSiteName,
    onSaveName: () => updateField('siteName'),

    currentPriority,
    setCurrentPriority,
    onSavePriority: (value: string) => updateField('priority', value),

    currentState,
    setCurrentState,
    onSaveCurrentState: (value: string) => updateField('currentState', value),

    coordinates,
    setCoordinates,
    onSaveCoordinates: (value: Coordinates) => updateField('coordinates', value),

    currentDescription,
    setDescription,
    onSaveDescription: (value: string) => updateField('description'),

    currentAdditionalWorks,
    setCurrentAdditionalWorks,
    onSaveAdditionalWorks: (value: string) => updateField('additionalWorks'),

    currentRequiredChanges,
    setCurrentRequiredChanges,
    onSaveRequiredChanges: (value: string) => updateField('requiredChanges'),

    updateStatus,
  };
};
