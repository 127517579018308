import { BaseBtn } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  loading?: boolean;
  btnColor?: string;
  sx?: SxProps;
}
export const MobileButton: FC<Props> = ({ sx = {}, ...props }) => {
  return (
    <BaseBtn
      sx={{
        borderRadius: '8px',
        width: '100%',
        height: '48px',
        mt: '16px',

        ...sx,
      }}
      {...props}
    />
  );
};
