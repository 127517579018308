import { Box, Tooltip } from '@mui/material';
import { FC, useLayoutEffect, useRef, useState } from 'react';

interface CustomTooltip {
  children: React.ReactNode;
  width: number;
}

export const TooltipForText: FC<CustomTooltip> = ({ children, width }) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [hoverStatus, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const checkWidth = () => {
    setOpen(false);
    console.log(textElementRef.current.scrollWidth, width);
    if (textElementRef.current) {
      const compare = textElementRef.current.scrollWidth > width;
      setHover(compare);
      if (compare) setOpen(true);
    }
  };
  const mouseLeave = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={children}
      onMouseEnter={() => checkWidth()}
      disableHoverListener={!hoverStatus}
      onMouseLeave={() => mouseLeave()}
      style={{ position: 'absolute', right: '0' }}
      open={open}
    >
      <div ref={textElementRef} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {children}
      </div>
    </Tooltip>
  );
};
