import { Box, useTheme } from '@mui/material';
import { BaseInput } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, MutableRefObject, useState } from 'react';

type Props = {
  label: string;
  name: string;
  error: boolean;
  helperText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blur?: (value: string) => void;
  value: string | number;
  sx?: SxProps;
  type?: string;
  valueForNumberChanging?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
  disabled?: boolean;
  updated?: boolean;
};
export const MobileTextarea: FC<Props> = ({ sx = {}, disabled, updated, ...props }) => {
  const [isBigSize, setIsBigSize] = useState<boolean>(false);

  const onBlur = () => {
    setIsBigSize(false);
  };
  const onFocus = () => {
    setIsBigSize(true);
  };
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';
  return (
    <BaseInput
      sx={{
        borderRadius: '8px',
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& > fieldset.MuiOutlinedInput-notchedOutline': {
            border: updated ? `1px solid green` : `1px solid primary.dark`,
          },
        },
        ...sx,
      }}
      isTextDirectionRtl={isRtl}
      sxLabel={{
        color: '#212529',
      }}
      blur={onBlur}
      focus={onFocus}
      medium={!isBigSize}
      large={isBigSize}
      disabled={disabled}
      {...props}
    />
  );
};
