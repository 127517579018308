const localStorageService = {
  get isRTL() {
    const valid=['en', 'he', 'ar', 'ar-sy', 'he-iss', 'en-iss', 'ar-SY-iss' ].includes(localStorage.getItem('agam_lang'))
    if(!valid) return true
    return ['he', 'ar', 'he-iss', 'ar-SY-iss'].includes(localStorage.getItem('agam_lang'));
  },

  get currentLang() {
    
    const valid=['en', 'he', 'ar', 'ar-sy', 'he-iss', 'en-iss', 'ar-SY-iss' ].includes(localStorage.getItem('agam_lang'))
    return valid ? localStorage.getItem('agam_lang') : 'he'
  },
  set currentLang(value: string) {
    localStorage.setItem('agam_lang', value);
  },

  get currentUserName() {
    return localStorage.getItem('agam_username');
  },

  set currentUserName(userName: string) {
    localStorage.setItem('agam_username', userName);
  },

  get currentUserMail() {
    return localStorage.getItem('agam_user_mail');
  },

  set currentUserMail(userName: string) {
    localStorage.setItem('agam_user_mail', userName);
  },

  get invalidToken() {
    const invalidTokenValue = localStorage.getItem('invalidToken');
    return invalidTokenValue ? JSON.parse(invalidTokenValue) : false;
  },

  set invalidToken(isInvalidToken: boolean) {
    if (!isInvalidToken) {

      localStorage.removeItem('invalidToken');
      return;
    }
    localStorage.setItem('invalidToken', JSON.stringify(isInvalidToken));
  },

  get userToken() {
    return localStorage.getItem('userToken');
  },

  set userToken(token: string) {
    if (!token) {
      localStorage.removeItem('userToken');
      return;
    }
    localStorage.setItem('userToken', token);
  },

  get userMobileToken() {
    return localStorage.getItem('userToken');
  },

  set userMobileToken(token: string) {
    if (!token) {
      localStorage.removeItem('userToken');
      return;
    }
    localStorage.setItem('userToken', token);
  },


  get replacedEquipmentReport() {
    return localStorage.getItem('replaced_equipment_report');
  },
  set replacedEquipmentReport(value: string) {
    localStorage.setItem('replaced_equipment_report', value);
  },

  get PowerProblemReport() {
    const data = localStorage.getItem('power_problem_report');
    try {
      return data && JSON.parse(data) ? data : null;
    } catch (e) {
      console.error('Invalid JSON in localStorage:', e);
      return null;
    }
  },
  set PowerProblemReport(value: string) {
    localStorage.setItem('power_problem_report', value);
  },
  get batteryStatusReport() {
    const data = localStorage.getItem('battery_status_report');
    try {
      return data && JSON.parse(data)? data : null;
    } catch (e) {
      console.error('Invalid JSON in localStorage:', e);
      return null;
    }
  },
  set batteryStatusReport(value: string) {
    localStorage.setItem('battery_status_report', value);
    
  },
  get loginDefaultPage() {
    return localStorage.getItem('dashboard_login_default');
  },
  set loginDefaultPage(value: string) {
    localStorage.setItem('dashboard_login_default', value);
  },
  get dashboardActiveCharts() {
    return localStorage.getItem('dashboard_active_charts');
  },
  set dashboardActiveCharts(value: string) {
    localStorage.setItem('dashboard_active_charts', value);
  },
  get activeTabEditController(){
    return localStorage.getItem('active_tab_edit_controller');
  },
  set activeTabEditController(value: string){
    localStorage.setItem('active_tab_edit_controller', value);
  },
  get itemsFieldsToExport(){
    const data = localStorage.getItem('items_fields_to_export');
    try {
      return data && JSON.parse(data)? data : null;
    } catch (e) {
      console.error('Invalid JSON in localStorage:', e);
      return null;
    }

  },
  set itemsFieldsToExport(value: string){
    localStorage.setItem('items_fields_to_export', value);
  },
  set resetPasswordByEmail(records:{userID:number, timestamp:number}[]){
    localStorage.setItem('password_reset_by_email', JSON.stringify(records));
  },
  get resetPasswordByEmail(){
    return JSON.parse(localStorage.getItem('password_reset_by_email'));
  },
  set resetPasswordByPhone(records:{userID:number, timestamp:number}[]){
    localStorage.setItem('password_reset_by_phone', JSON.stringify(records));
  },
  get resetPasswordByPhone(){
    return JSON.parse(localStorage.getItem('password_reset_by_phone'));
  },

  get returnUrl() {
    return localStorage.getItem('return_url');
  },

  set returnUrl(Url: string) {
    localStorage.setItem('return_url', Url);
  },

  get showAllMaterials() {
    return localStorage.getItem('show_all_materials') === 'true';
  },

  set showAllMaterials(value: boolean) {
    localStorage.setItem('show_all_materials', value.toString());
  },

  get kvish6SplitPosition() {
    return localStorage.getItem('kvish6_split_position');
  },

  set kvish6SplitPosition(value: string) {
    localStorage.setItem('kvish6_split_position', value.toString());
  },

  get baseTableSettings() {
    const storedData = localStorage.getItem('base_table_settings');
    return storedData ? JSON.parse(storedData) : {};
  },

  set baseTableSettings(records: { tablePrefix: string; data?: { fieldName: string; newWidth: string } }) {

    const currentSettings = this.baseTableSettings;

    if (records.data) {

      if (!currentSettings[records.tablePrefix]) {
        currentSettings[records.tablePrefix] = {};
      }
      currentSettings[records.tablePrefix][records.data.fieldName] = records.data.newWidth;
    } else {

      delete currentSettings[records.tablePrefix];
    }
  
    console.log(currentSettings, JSON.stringify(currentSettings));
    localStorage.setItem('base_table_settings', JSON.stringify(currentSettings));

  }
};

export default localStorageService;
