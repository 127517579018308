import { useAppDispatch, useToast } from 'shared/hooks';

import useAxios from 'shared/api/useAxios';
import {
  ChangedSiteMaterials,
  ISSPhoto,
  ISSProject,
  ISSSite,
  ISSSiteChanges,
  ISSSiteCreation,
  MaterialLists,
  NewPhotoData,
  SiteMaterials,
  UpdateDataProject,
} from 'features/Mobile/ISS/ISSModels';
import {
  setISSProjects,
  setISSSites,
  setISSPhotos,
  setMaterialLists,
  setSiteMaterials,
} from 'features/Mobile/ISS/ISSSlice';
import { t } from 'i18next';

export const useISSData = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showSuccess } = useToast();
  return {
    getISSProjects: () =>
      request<undefined, { data: ISSProject[] }>({
        method: 'get',
        url: `/iss/projects`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSProjects(data?.data));
        },
      }),
    getISSSites: () =>
      request<undefined, { data: ISSSite[] }>({
        method: 'get',
        url: `/iss/sites`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSSites(data?.data));
        },
      }),

    addSite: (creationData: ISSSiteCreation) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'post',
        url: `/iss/site`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          showSuccess(t('site_successfully_added'));
          // dispatch(setISSPhotos(data?.data));
        },
        payload: creationData,
      }),
    editSite: (creationData: { siteID: number; changes: ISSSiteChanges }) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'put',
        url: `/iss/site`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          // dispatch(setISSPhotos(data?.data));
        },
        payload: creationData,
      }),
    getCurrentProject: (id: number) =>
      request<undefined, { data: ISSProject }>({
        method: 'get',
        url: `/iss/project?projectId=${id}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
      }),
    updateCurrentProject: (updateData: UpdateDataProject) =>
      request<undefined, { data: ISSProject }>({
        method: 'put',
        url: `/iss/project`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
        payload: updateData,
      }),
    createProject: (name: string) =>
      request<undefined, { data: { id: number }; succeeded: boolean }>({
        method: 'post',
        url: `/iss/project`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
        payload: { projectName: name },
      }),
    isLoading: loading,
  };
};
