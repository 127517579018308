import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography, styled } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaders,
  GridRenderCellParams,
  gridClasses,
  useGridApiRef,
  GridSortModel,
  MuiEvent,
  GridCellEditStopParams,
  GridCellParams,
  GridCellModesModel,
  GridCellModes,
} from '@mui/x-data-grid-pro';
import { memo, useEffect, useRef, useState } from 'react';
import { useExtendedTranslation } from 'shared/hooks';
import { getFormattedDate, getFormattedDateAndTime, isHebrewFont } from 'shared/lib';
import localStorageService from 'shared/localStorage';
import { BaseTooltip, BigTitle, OutsideClickListener, Row } from 'shared/ui';
import { ActionMenu, BaseCheckbox } from './components';
import { useCalculateRowsWidth } from './hooks';
import React from 'react';
const SelectMark = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '5px',
  p: 0,
  height: '100%',
  backgroundColor: theme.palette.primary.dark,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));
export type TableTitle<T> = {
  title: string;
  key: keyof T;
  width: number | string;
  object?: string;
  editable?: boolean;
  type?: string;
  minWidth?: number;
};
export type TranslateColumn<T> = {
  key: keyof T;
  additionalKey?: string;
  preKey?: string;
  translationMap?: { [key: string]: string };
};

interface MenuListAction<T> {
  icon: string;
  title: string;
  onClick: (row: T) => void;
}
interface OptionAction<T> {
  title: string;
  onClick: (row: T) => void;
}
interface AdditionalContent<T> {
  index: number;
  hideContent?: boolean;
  content: (props: any) => JSX.Element;
}
interface Props<T> {
  tableItems: T[];
  loadedItems?: boolean;
  idField?: string;
  noItemsText: string;
  headerItemsPrefix: string;
  checkboxSelection?: boolean;
  dateColumnIndexes?: number[];
  dateTimeColumnIndexes?: number[];
  linkIndex?: number[];
  cellClick?: (row: T, index: number) => void;
  rowClick?: (row: T) => void;
  maxHeight?: string;
  height?: string;
  translateColumns?: TranslateColumn<T>[];
  tableTitles: TableTitle<T>[];
  actions?: MenuListAction<T>[];
  onRowSelectionModelChange?: (id: string[] | number[]) => void;
  options?: OptionAction<T>;
  lastRowOption?: { title: string; onClick: () => void };
  disabled?: boolean;
  selectedRowID?: number | string;
  rowSelectionOnClick?: boolean;
  sortModel?: GridSortModel;
  selectedIDs?: number[];
  hideLoader?: boolean;
  hideSelectAll?: boolean;
  searchValueForHighlight?: string;
  newHighlightText?: string;
  modifyColumnIndexByMask?: number;
  changeDataInTable?: (row: T) => void;
  underlineRow?: number;
  additionalIndex?: number;
  conditionForAddIndex?: string;
  idFields?: string[];
  additionalContent?: AdditionalContent<T>[];
  loading?: boolean;
  translateArrayColumns?: number[];
  defaultScrollWidth?: number;
  serviceCallsLogic?: boolean;
  userDetailsLogic?: boolean;
  stringColumnIndexes?: number[];
  editable?: boolean;
  disableDoubleClick?: boolean;
  highlightCheckboxes?: number[];
  localStorageSyncWidth?: boolean;
  fontSize?: string;
  rowHeight?: number;
  alignCenterColumnIndexes?: number[];
}

const Table = <T,>({
  tableItems,
  loadedItems,
  idField,
  noItemsText,
  headerItemsPrefix,
  checkboxSelection,
  dateColumnIndexes,
  dateTimeColumnIndexes,
  maxHeight,
  linkIndex,
  cellClick,
  tableTitles,
  translateColumns,
  actions,
  onRowSelectionModelChange,
  options,
  lastRowOption,
  disabled,
  height,
  rowClick,
  selectedRowID,
  rowSelectionOnClick,
  sortModel,
  selectedIDs,
  hideLoader,
  hideSelectAll,
  searchValueForHighlight,
  newHighlightText,
  modifyColumnIndexByMask,
  changeDataInTable,
  underlineRow,
  additionalIndex,
  conditionForAddIndex,
  idFields,
  additionalContent,
  translateArrayColumns,
  loading,
  defaultScrollWidth,
  serviceCallsLogic,
  userDetailsLogic,
  stringColumnIndexes,
  editable,
  disableDoubleClick,
  highlightCheckboxes,
  localStorageSyncWidth,
  fontSize,
  rowHeight,
  alignCenterColumnIndexes,
}: Props<T>) => {
  const { t } = useExtendedTranslation();
  const apiRef = useGridApiRef();
  const tableRef = useRef<HTMLTableElement | null>(null);
  const { calculateUnitWidth, calculateWidthInPixels } = useCalculateRowsWidth(tableTitles);
  useEffect(() => {
    const hasActions = actions && !!actions.length;
    const hasOptions = !!options;
    const unitWidth = calculateUnitWidth(tableRef, checkboxSelection, hasActions, hasOptions, defaultScrollWidth);
    setUnitWidth(unitWidth);
  }, [tableTitles]);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const MemoizedColumnHeaders = GridColumnHeaders;
  const [unitWidth, setUnitWidth] = useState(0);
  const [cancelSavingEditableCell, setCancelSavingEditableCell] = useState(false);
  const isRTL = localStorageService.isRTL;
  const handleDoubleClick = (row: T) => {
    if (!disableDoubleClick && linkIndex && linkIndex.length) {
      cellClick(row, linkIndex[0]);
    }
  };
  const getDirection = (value, index) => {
    if (stringColumnIndexes) {
      return stringColumnIndexes && stringColumnIndexes.includes(index) && isRTL ? 'rtl' : 'ltr';
    }

    if (isRTL) {
      return isHebrewFont(value?.toString() ?? '') ? 'rtl' : 'ltr';
    }
    return isHebrewFont(value?.toString() ?? '') ? 'ltr' : 'ltr';
  };
  const handleEvent = (row: T, index: number) => {
    cellClick(row, index);
  };
  const handleSelectRow = (event) => {
    if (rowClick) {
      rowClick(event.row);
    }
  };

  const highlightSearch = (text, term) => {
    const getPartsOfString = (mask: string) => {
      let beforeZeros = '';
      let zeros = '';
      let afterZeros = '';
      for (let i = 0; i < mask.length; i++) {
        const char = mask[i];
        if (!isNaN(parseInt(char)) && !afterZeros) {
          zeros += char;
        } else if (char !== '*') {
          if (zeros) {
            afterZeros += char;
          } else {
            beforeZeros += char;
          }
        }
      }
      return { beforeZeros, zeros, afterZeros };
    };

    if (!term.trim()) {
      return text;
    }

    if (term[term.length - 1] === '*') {
      const { beforeZeros, zeros, afterZeros } = getPartsOfString(term);
      const { beforeZeros: letters, zeros: digits, afterZeros: others } = getPartsOfString(text);

      //checking logic
      if (beforeZeros.toUpperCase() !== letters.toUpperCase()) return text;
      if (zeros.length !== digits.length) return text;
      if (!others.toUpperCase().startsWith(afterZeros.toUpperCase())) return text;

      //changing logic
      const {
        beforeZeros: newLetters,
        zeros: newDigits,
        afterZeros: afterNumbers,
      } = getPartsOfString(newHighlightText);

      const moreNumbersBecome = newDigits.length - digits.length;
      let result = '';
      if (moreNumbersBecome > -1) {
        result = `${newLetters}${moreNumbersBecome > 0 ? '0'.repeat(moreNumbersBecome) : ''}${digits}${afterNumbers}`;
      }
      if (moreNumbersBecome < 0) {
        const cutDigits = digits.substring(moreNumbersBecome * -1);
        result = `${newLetters}${cutDigits}${afterNumbers}`;
      }
      return (
        <span>
          <span style={{ color: 'rgb(36, 90, 165)', background: 'yellow', fontWeight: '700' }}>{result}</span>
          {others}
        </span>
      );
    }
    return text;
  };
  const formatValue = (params, index) => {
    let formattedValue: string = params.value;
    if (modifyColumnIndexByMask === index) {
      return highlightSearch(params.value, newHighlightText);
    }
    if (dateColumnIndexes && dateColumnIndexes.includes(index))
      return params.value ? getFormattedDate(params.value as string) : '';
    if (dateTimeColumnIndexes && dateTimeColumnIndexes.includes(index))
      return params.value ? getFormattedDateAndTime(params.value as string) : '';
    if (translateColumns) {
      translateColumns.forEach((item) => {
        if (item.translationMap && item.key === params.field) {
          formattedValue = t(item.translationMap[params.value]);
          return formattedValue;
        }
        if (item.key === params.field) {
          const key = `${item.preKey ? item.preKey : ''}${params.value || params.value === 0 ? params.value : ''}${
            (params.value || params.value === 0) && item.additionalKey ? item.additionalKey : ''
          }`;
          formattedValue = t(key);
        }
      });
    }
    if (translateArrayColumns && translateArrayColumns.includes(index)) {
      if (!params.value) return '';

      let items = params.value.split(',');
      items = items.map((item) => t(item));
      return items.join(', ');
    }
    return formattedValue;
  };
  const validRow = (row: T | any) => {
    return row.objectName.length < 5 ? 'red' : 'primary.light';
  };

  const getEventForClick = (index: number, params: GridRenderCellParams<T>) => {
    // additionalIndex?: number;
    // conditionForAddIndex?: string;
    //
    if (!linkIndex) return;
    const linkIndexes = [...linkIndex];

    if (conditionForAddIndex && params.row[conditionForAddIndex] > 0) {
      linkIndexes.push(additionalIndex);
    }
    return linkIndexes && linkIndexes.includes(index) ? () => handleEvent(params.row, index) : null;
  };
  const checkForLink = (index: number, params: GridRenderCellParams<T>) => {
    if (!linkIndex) return;
    const linkIndexes = [...linkIndex];

    if (conditionForAddIndex && params.row[conditionForAddIndex] > 0) {
      linkIndexes.push(additionalIndex);
    }
    return linkIndexes && linkIndexes.includes(index);
  };
  const getRowId = (row: T) => {
    if (idFields) {
      let result = '';
      idFields.forEach((item) => {
        result += `${row[item]}-`;
      });
      return `${result}_id}`;
    }
    return row[idField];
  };
  const defaultSortComparator = (v1: string | number, v2: string | number) => {
    if (v1 === null && v2 === null) {
      return 0;
    }
    if (v1 === null) {
      return -1;
    }
    if (v2 === null) {
      return 1;
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return v1 - v2;
    }

    const v1String = v1.toString();
    const v2String = v2.toString();

    return v1String.localeCompare(v2String);
  };
  const dateSortComparator = (v1, v2) => {
    const date1 = new Date(v1).getTime();
    const date2 = new Date(v2).getTime();
    return date1 - date2;
  };
  const getAdditionalContent = (index: number, params: GridRenderCellParams<T>) => {
    if (!additionalContent || !additionalContent.length) return formatValue(params, index);
    const contentIndex = additionalContent.findIndex((item) => item.index === index);
    if (contentIndex < 0) return formatValue(params, index);
    return (
      <Row
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: alignCenterColumnIndexes && alignCenterColumnIndexes.includes(index) ? 'center' : '',
        }}
      >
        {isRTL ? (
          <>
            <Box>{additionalContent[contentIndex].content({ row: params.row })}</Box>
            {additionalContent[contentIndex].hideContent ? <></> : <Box>{formatValue(params, index)}</Box>}
          </>
        ) : (
          <>
            {additionalContent[contentIndex].hideContent ? <></> : <Box>{formatValue(params, index)}</Box>}
            <Box>{additionalContent[contentIndex].content({ row: params.row })}</Box>
          </>
        )}
      </Row>
    );
  };
  const getTextColor = (index: number, params) => {
    if (serviceCallsLogic) {
      if (index == 9 && params.row.lastDT_100) {
        return 'red';
      }
    }
    if (userDetailsLogic) {
      if (index == 2 && !params.row.status) {
        return 'red';
      }
    }
    return underlineRow === index ? validRow(params.row) : checkForLink(index, params) ? 'primary.light' : 'black';
  };
  const getWidthFromLocalStorage = (key: string | number | symbol, defaultWidth: string | number) => {
    const baseTableSettings = localStorageService.baseTableSettings;
    if (!baseTableSettings[headerItemsPrefix]) {
      return calculateWidthInPixels(defaultWidth, unitWidth);
    }
    const width = baseTableSettings[headerItemsPrefix][key];
    return width ? width : calculateWidthInPixels(defaultWidth, unitWidth);
  };

  const getColumns = (actions: MenuListAction<T>[]) => {
    const columns = tableTitles.map((title, index) => ({
      field: title.key as string,
      headerName: `${t(title.title !== '' ? `${title.title}${headerItemsPrefix}` : '')}`,
      width: localStorageSyncWidth
        ? getWidthFromLocalStorage(title.key, title.width)
        : calculateWidthInPixels(title.width, unitWidth),
      minWidth: title.minWidth ? title.minWidth : 50,
      editable: title.editable,
      headerClassName: 'headerTable',
      sortComparator: title.type == 'date' ? dateSortComparator : defaultSortComparator,
      renderCell: (params: GridRenderCellParams<T>) => (
        <Box
          onClick={getEventForClick(index, params)}
          sx={{
            fontFamily: 'Noto Sans Hebrew',
            direction: (value) => getDirection(value, index),
            fontSize: fontSize ? fontSize : '13px',
            fontWeight: 500,
            userSelect: 'none',
            cursor: checkForLink(index, params) ? 'pointer' : 'default',
            color: getTextColor(index, params),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            tabindex: '-1',
            width: '100%',
            textAlign: alignCenterColumnIndexes && alignCenterColumnIndexes.includes(index) ? 'center' : '',
            //alignCenterColumnIndexes

            '&:hover': checkForLink(index, params)
              ? {
                  color: 'primary.dark',
                  textDecoration: 'underline',
                }
              : {},
          }}
        >
          {index < 1 && getRowId(params.row) == selectedRowID && <SelectMark />}
          {getRowId(params.row) ? (
            <BaseTooltip width={params.colDef.width}>{getAdditionalContent(index, params)}</BaseTooltip>
          ) : (
            <></>
          )}
        </Box>
      ),
    }));
    if (options) {
      const columnsWithOptions = [...columns];
      columnsWithOptions.push({
        field: 'action',
        headerName: '',
        editable: false,
        width: 80,
        minWidth: 50,
        headerClassName: 'headerTable',
        sortComparator: defaultSortComparator,
        renderCell: (params: GridRenderCellParams<T>) =>
          getRowId(params.row) ? (
            <Box
              sx={{
                cursor: 'pointer',
                color: 'primary.light',
                '&:hover': {
                  color: 'primary.dark',
                  textDecoration: 'underline',
                },
              }}
              onClick={() => options.onClick(params.row)}
            >
              {options.title}
            </Box>
          ) : (
            <Box
              sx={{
                cursor: 'pointer',
                color: 'primary.light',
                '&:hover': {
                  color: 'primary.dark',
                  textDecoration: 'underline',
                },
              }}
              onClick={() => lastRowOption.onClick()}
            >
              {lastRowOption.title}
            </Box>
          ),
      });
      return columnsWithOptions;
    }
    if (actions) {
      const columnsWithActions = [...columns];
      columnsWithActions.push({
        field: 'action',
        headerName: '',
        editable: false,
        width: 40,
        minWidth: 50,
        headerClassName: 'headerTable',
        sortComparator: defaultSortComparator,
        renderCell: (params: GridRenderCellParams<T>) => <ActionMenu actions={actions} row={params.row} />,
      });
      return columnsWithActions;
    }

    return columns;
  };

  const columns: GridColDef<T>[] = getColumns(actions);
  function CustomSortedAscendingIcon() {
    return <ArrowDropDownIcon />;
  }
  function CustomSortedDescendingIcon() {
    return <ArrowDropUpIcon />;
  }
  const CustomNoRowsOverlay = () => {
    return (
      <BigTitle align="center" sx={{ mt: tableItems.length ? '20px' : '0px' }}>
        {noItemsText}
      </BigTitle>
    );
  };
  const mySaveOnServerFunction = (originalRow: T | any, updatedRow: T | any) => {
    if (cancelSavingEditableCell) {
      setCancelSavingEditableCell(false);
      return originalRow;
    }
    if (changeDataInTable && updatedRow.objectName) {
      changeDataInTable(updatedRow);
      return updatedRow;
    }
    changeDataInTable(originalRow);
    return originalRow;
  };
  const handleCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);
  const setNextCell = (id) => {
    const sortedItems = apiRef.current.getSortedRows().slice();

    const rowsId = sortedItems.map((item) => (item as { id: string }).id);
    const currentIndex = rowsId.findIndex((item) => item === id);
    if (currentIndex === rowsId.length - 1) {
      return;
    }
    const nextId = rowsId[currentIndex + 1];
    const newCellModesModel = {};
    newCellModesModel[id] = {
      objectName: {
        mode: GridCellModes.View,
      },
    };
    newCellModesModel[nextId] = {
      objectName: {
        mode: 'edit',
      },
    };
    setCellModesModel(newCellModesModel);
  };
  const setNextCellTemp = (id) => {
    const sortedItems = apiRef.current.getSortedRows().slice();

    const rowsId = sortedItems.map((item) => (item as { id: string }).id);
    const currentIndex = rowsId.findIndex((item) => item === id);
    if (currentIndex === rowsId.length - 1) {
      return;
    }

    const nextId = rowsId[currentIndex + 1];
    const newCellModesModel = {};
    apiRef.current.setCellFocus(nextId, 'objectName');
    // newCellModesModel[id] = {
    //   objectName: {
    //     mode: GridCellModes.View,
    //   },
    // };
    // newCellModesModel[nextId] = {
    //   objectName: {
    //     mode: 'view',
    //   },
    // };
    // setCellModesModel(newCellModesModel);
  };
  const handleCellModesModelChange = React.useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);
  const handleCellKeyDown = React.useCallback((params: GridCellParams, event: MuiEvent<React.KeyboardEvent>) => {
    if (params.isEditable && event.key === 'Enter') {
      setCellModesModel((prevModel) => ({
        ...prevModel,
        [params.id]: {
          ...prevModel[params.id],
          [params.field]: { mode: GridCellModes.View },
        },
      }));

      setNextCellTemp(params.id);
    }
    if (params.isEditable && event.key === 'Tab') {
      setNextCell(params.id);
    }
    if (params.isEditable && event.key === 'Escape') {
      setCancelSavingEditableCell(true);
      setCellModesModel((prevModel) => ({
        ...prevModel,
        [params.id]: {
          ...prevModel[params.id],
          [params.field]: { mode: GridCellModes.View },
        },
      }));
    }
  }, []);

  const handleOutsideClick = React.useCallback(() => {
    if (!editable) return;
    setCellModesModel((prevModel) => {
      const newModel = {};
      Object.keys(prevModel).forEach((id) => {
        newModel[id] = {};
        Object.keys(prevModel[id]).forEach((field) => {
          newModel[id][field] = { mode: GridCellModes.View };
        });
      });
      return newModel;
    });
  }, []);

  const getRowClassName = (params) => {
    let className = '';
    //rowname

    if (serviceCallsLogic) {
      if (params.row.lastDT_OPEN) {
        className += 'serviceCall-highlight-background ';
      }
    }
    if (params.id === selectedRowID) {
      className += 'selectedRow ';
    }
    if (highlightCheckboxes && highlightCheckboxes.includes(params.id)) {
      className += 'highlightRow';
    }
    return className;
  };
  const columnWidthChange = (x) => {
    if (!localStorageSyncWidth) return;
    const newWidth = x.colDef.width;
    const fieldName = x.colDef.field;
    localStorageService.baseTableSettings = {
      tablePrefix: headerItemsPrefix,
      data: { fieldName: fieldName, newWidth: newWidth },
    };
  };
  return (
    <>
      <Box
        ref={tableRef}
        height={height ? height : 'calc(100vh - 165px)'}
        style={{
          width: '100%',
          position: 'relative',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <OutsideClickListener onOutsideClick={handleOutsideClick}>
          <>
            {disabled && <Box style={{ width: '100%', maxHeight: '256ppx', position: 'absolute', zIndex: '2' }}></Box>}
            <DataGridPro
              {...tableItems}
              rowHeight={rowHeight ? rowHeight : 52}
              getRowId={(row) => getRowId(row)}
              loading={!hideLoader && !loadedItems}
              onRowClick={handleSelectRow}
              onCellDoubleClick={(item) => handleDoubleClick(item.row)}
              checkboxSelection={checkboxSelection}
              disableRowSelectionOnClick={!rowSelectionOnClick}
              initialState={{ sorting: sortModel ? { sortModel } : null }}
              slots={{
                columnHeaders: MemoizedColumnHeaders,
                columnSortedAscendingIcon: CustomSortedAscendingIcon,
                columnSortedDescendingIcon: CustomSortedDescendingIcon,
                baseCheckbox: BaseCheckbox,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              // onCellEditStop={editStop}
              processRowUpdate={(updatedRow, originalRow) => mySaveOnServerFunction(originalRow, updatedRow)}
              apiRef={apiRef}
              density={'compact'}
              rows={tableItems}
              columns={columns}
              disableColumnMenu
              onRowSelectionModelChange={onRowSelectionModelChange}
              rowSelectionModel={selectedIDs}
              getRowClassName={getRowClassName}
              editMode="row"
              hideFooter
              onCellClick={handleCellClick}
              onCellKeyDown={handleCellKeyDown}
              onColumnWidthChange={columnWidthChange}
              sx={{
                maxHeight: maxHeight ? maxHeight : 'calc(100vh - 265px)',

                maxWidth: '100%',
                borderRadius: '12px',
                position: 'relative',
                mt: '12px',
                pr: '1px',
                bgcolor: 'info.main',
                overflowY: 'auto',
                '.MuiDataGrid-cell': {
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                },
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: '#0a3e90',
                },
                '.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainerContent': {
                  display: hideSelectAll ? 'none' : 'auto',
                },
                '.headerTable': {
                  backgroundColor: '#0a3e90',
                  color: 'white',
                  borderRight: '1px solid #7a7a7a',
                  fontWeight: 'bold',
                  pr: '2px',
                  height: '38px',
                  '.MuiDataGrid-columnSeparator': {
                    opacity: '0 !important',
                  },

                  svg: {
                    fill: 'white',
                  },
                },
                '.MuiDataGrid-virtualScrollerRenderZone': {},
                '.MuiDataGrid-virtualScroller': {
                  overflow: `${!tableItems.length && 'hidden'}`,
                  minHeight: `${loading ? '0px' : '80px'}`,
                },

                '& .MuiDataGrid-virtualScrollerContent': {
                  marginTop: '-1px',
                },
                '& .MuiDataGrid-cell': {
                  position: 'relative',
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#f0f0f0',
                },
                '.selectedRow': {
                  backgroundColor: '#d0e7ff',
                },
                '.selectedRow.MuiDataGrid-row:hover': {
                  backgroundColor: '#c0d7ff',
                },
                '.MuiDataGrid-row.Mui-selected': {
                  backgroundColor: '#d0ffd6',
                },
                '.MuiDataGrid-row.Mui-selected:hover': {
                  backgroundColor: '#c0ffca',
                },
                '.MuiDataGrid-row.Mui-selected.selectedRow': {
                  backgroundColor: '#c0e7ff',
                },
                '.MuiDataGrid-row.Mui-selected.selectedRow:hover': {
                  backgroundColor: '#b0d7ca',
                },
                '.MuiDataGrid-row.serviceCall-highlight-background': {
                  backgroundColor: '#B5FE32',
                },
                '.highlightRow .MuiCheckbox-colorPrimary .MuiBox-root': {
                  //background: 'rgba(0, 255, 0, 0.2)',
                  //opacity: '0.5'
                },
                '.highlightRow': {
                  background: 'rgba(0, 255, 0, 0.2)',
                  opacity: '0.5',
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none',
                },
              }}
            />
          </>
        </OutsideClickListener>
      </Box>
    </>
  );
};

export const BaseTable = memo(Table) as <T>(props: Props<T>) => React.ReactElement;
