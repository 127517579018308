import { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Loader } from 'shared/ui';
import { BaseSelect } from 'features';
import { t } from 'i18next';
import { useAppSelector } from 'shared/hooks';
import { useTimeZone } from 'features/TimeZoneManager/hooks';

interface Props {
  loading?: boolean;
  onChange: (value: string) => void;
  value: string;
  title: string;
  onlyActive?: boolean;
  formInput?: boolean;
  big?: boolean;
  hideResetButton?: boolean;
}

export const BaseTimezoneSelect: FC<Props> = ({
  loading,
  onChange,
  value,
  title,
  onlyActive,
  formInput,
  big,
  hideResetButton,
}) => {
  const { timeZones } = useAppSelector((st) => st.timeZone);
  const { getTimeZone } = useTimeZone();
  const onChangeTimeZone = (value: string) => {
    onChange(value);
  };

  useEffect(() => {
    getTimeZone();
  }, []);

  const timeZoneItems = useMemo(
    () => {
      if (!timeZones.length) return [];
      const result = [];
      const sortedTimeZones = [...timeZones].sort((a, b) => a.utcOffset - b.utcOffset);
      sortedTimeZones.forEach((item) => {
        const cleanZoneName = item.zoneName.includes('/') ? item.zoneName.split('/')[1] : item.zoneName;
        if (!onlyActive || item.isActive)
          result.push({
            value: item.timeZoneID.toString(),
            label: `(${item.utcOffset > 0 ? '+' : ''}${Math.floor(item.utcOffset / 3600)}:${
              Math.abs(item.utcOffset % 3600) / 60 === 0 ? '00' : '30'
            }) ${cleanZoneName} ${item.displayName}`,
          });
      });
      return result;
    },
    [timeZones]
    //tableTitles - need for replaced-equipment-report
  );

  // const customerFilterItems = customers.map((s) => ({
  //     value: s.id.toString(),
  //     label: s.customerName,
  //   }));

  // const timeZoneItems = [
  // 	{
  // 		value: "333",
  // 		label: "label1"
  // 	},
  // 	{
  // 		value: "444",
  // 		label: "label2"
  // 	}
  // ]

  return (
    <>
      {timeZoneItems && timeZoneItems.length && (
        <BaseSelect
          title={title}
          value={value}
          onChange={onChangeTimeZone}
          items={timeZoneItems}
          hideAllButton
          withSearch
          sx={{ width: '100%' }}
          hideValue
          withoutMargin
          formInput={formInput}
          big={big}
          noSelectLabel={' '}
          showOnlyResetButton={!hideResetButton}
          showHomeButton={!hideResetButton}
        />
      )}
    </>
  );
};
