import { Box } from '@mui/material';

import { t } from 'i18next';
import { Header } from '../Components/Header';
import { UnderConstructionBlock } from 'entities_';
import { Background } from '../ui';
import { Footer } from '../Components/Footer';
import Layout from '../Components/Layout';

export const TemporaryNotReady = () => {
  return (
    <Box>
      <Layout>
        <Header title={t('title_material_list_management')} />
        <Box height={'80px'}></Box>
        <UnderConstructionBlock
          fontSize={'18px'}
          height={'340px'}
          background="#1C5B9C"
          text={t('This_page_is_not_ready')}
        />
        <Background />
        <Footer />
      </Layout>
    </Box>
  );
};
