import { useAppDispatch } from 'shared/hooks';
import useAxios from 'shared/api/useAxios';
import { Kvish6ControllersData, Kvish6RealTimeData } from 'features/Kvish6Manager/Kvish6Models';

export const UseKvish6Data = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  return {
    getKvish6Data: () =>
      request<undefined, { data: Kvish6ControllersData[] }>({
        method: 'get',
        url: `/Kvish6ControllersData`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          // dispatch(setFotaTypes(data?.data));
        },
      }),
    getKvish6RealTimeData: (data: { deviceId: number; modelId: string }[]) =>
      request<undefined, { data: { deviceId: number; state: Kvish6RealTimeData }[] }>({
        method: 'post',
        url: `/device/realTime`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          // dispatch(setFotaTypes(data?.data));
        },
        payload: { devicesData: data },
      }),
    isLoading: loading,
  };
};
