import { Box } from '@mui/material';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Row } from 'shared/ui';
import {
  homeActiveMenu,
  homeMenu,
  materialActiveMenu,
  materialMenu,
  plusWhiteIcon,
  settingsActiveMenu,
  settingsMenu,
  sitesActiveMenu,
  sitesMenu,
} from '../../assets';
import { MenuButton } from './components/MenuButton';
import { FC, useState } from 'react';
import { ConfirmModal } from '../ConfirmModal';

interface Props {
  showPlus?: boolean;
  handlePlusClick?: () => void;
  withConfirm?: boolean;
}

export const Footer: FC<Props> = ({ showPlus, withConfirm, handlePlusClick }) => {
  const location = useLocation();
  const push = useNavigate();

  const [showModalConfirm, setShowModalConfirm] = useState(null);
  const [link, setLink] = useState(null);

  const handleNavigateConfirmed = () => {
    push(link);
  };

  const handleNavigate = (link: string) => {
    if (withConfirm) {
      setShowModalConfirm(true);
      setLink(link);
    } else {
      push(link);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ height: '60px' }}> </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: 0,
            width: '100%',
            height: '60px',
            background: 'white',
          }}
        >
          <Row justifyContent={'space-around'} sx={{ padding: '8px', maxWidth: '900px', margin: 'auto' }}>
            <MenuButton
              handleClick={() => handleNavigate('/mobile/iss')}
              iconSrc={homeMenu}
              activeIcon={homeActiveMenu}
              active={location.pathname === '/mobile/iss'}
              text={t('home_menu_button')}
            />
            <MenuButton
              handleClick={() => handleNavigate('/mobile/iss/projects')}
              iconSrc={sitesMenu}
              activeIcon={sitesActiveMenu}
              active={location.pathname === '/mobile/iss/projects'}
              text={t('sites_menu_button')}
              iconMargin={' -2px 0 0 0'}
            />
            <Box
              onClick={() => (showPlus ? handlePlusClick() : {})}
              sx={{ opacity: showPlus ? 1 : 0, transform: 'translate(0, -50%)', position: 'relative' }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  background: '#1C5B9C',
                  borderRadius: '8px',
                  transform: 'rotate(45deg)',
                }}
              ></Box>
              <Box
                sx={{
                  position: 'absolute',
                  height: '20px',
                  width: '20px',
                  transform: 'translate(-50%, -50%)',
                  top: '50%',
                  left: '50%',
                }}
              >
                <Icon src={plusWhiteIcon} />
              </Box>
            </Box>
            <MenuButton
              handleClick={() => handleNavigate('/mobile/iss/material-list-management')}
              iconSrc={materialMenu}
              activeIcon={materialActiveMenu}
              active={location.pathname === '/mobile/iss/material-list-management'}
              text={t('material_menu_button')}
              iconMargin={' -3px 0 0 0'}
            />
            <MenuButton
              handleClick={() => handleNavigate('/mobile/iss/settings')}
              iconSrc={settingsMenu}
              activeIcon={settingsActiveMenu}
              active={location.pathname === '/mobile/iss/settings'}
              text={t('settings_menu_button')}
            />
          </Row>
        </Box>
      </Box>
      <ConfirmModal
        cancel={() => setShowModalConfirm(false)}
        confirm={() => handleNavigateConfirmed()}
        title={t('leave_without_saving_changes')}
        okText={t('yes_confirm_modal')}
        show={showModalConfirm}
      />
    </>
  );
};
