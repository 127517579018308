import { Paragraph, TableCell, TextRun, WidthType } from 'docx';

export const TableCellWithText = (text: string, isRTL: boolean, allSize?: boolean) =>
  new TableCell({
    margins: {
      top: 100,
      bottom: 100,
      left: 100,
      right: 100,
    },
    width: allSize
      ? { size: 100, type: WidthType.PERCENTAGE } // Якщо розтягнута, займає всю ширину
      : { size: 2000, type: WidthType.DXA }, // Стандартна ширина для інших комірок
    children: [
      new Paragraph({
        bidirectional: isRTL,
        children: [
          new TextRun({
            text: text,
            rightToLeft: isRTL,
            language: { value: isRTL ? 'he-IL' : 'en' },
            font: 'Arial',
          }),
        ],
      }),
    ],
  });
