import { ContactPageSharp } from '@mui/icons-material';
import { Box } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
interface Props {
  onChangeCoordinates: ({ lat, lng }: { lat: number; lng: number }) => void;
  value: { lat: number; lng: number };
  mapKey: string;
  disabled?: boolean;
  isActiveLock: boolean;
  isFullscreen?: boolean;
}
type Marker = {
  lat: number;
  lng: number;
  text: string;
};
export const ISSMap = ({ onChangeCoordinates, value, mapKey, disabled, isActiveLock, isFullscreen }: Props) => {
  const [isEditingMap, setIsEditingMap] = useState<boolean>(true);
  const [clickedMarker, setClickedMarker] = useState<Marker | null>(null);
  const [isMapFullscreen, setIsMapFullscreen] = useState<boolean>(false);

  const defaultProps = {
    zoom: 16,
  };

  const MarkerComponent = (marker: Marker) => (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: 'red',
        width: '20px',
        height: '20px',
        marginLeft: '-10px',
        marginTop: '-10px',
        borderRadius: '50%',
      }}
    ></Box>
  );
  const [mapCenter, setMapCenter] = useState({
    lat: value.lat,
    lng: value.lng,
  });

  useEffect(() => {
    //getCoordinates();
    setNewCoordinates(value.lat, value.lng);
  }, []);

  useEffect(() => {
    setClickedMarker({ lat: value.lat, lng: value.lng, text: 'My Marker' });
    setMapCenter({ lat: value.lat, lng: value.lng });
  }, [value]);
  const setNewCoordinates = (latitude: number, longitude: number) => {
    setClickedMarker({ lat: latitude, lng: longitude, text: 'My Marker' });
    onChangeCoordinates({ lat: latitude, lng: longitude });
  };
  const handleMapClick = (result: { lat: number; lng: number; event: any }) => {
    if (disabled) return;
    if (!isEditingMap) return;
    if (isActiveLock) return;

    const ignoredElements = ['BUTTON'];
    if (ignoredElements.includes(result.event.target.tagName)) return;

    setNewCoordinates(result.lat, result.lng);
  };

  return (
    <Box
      sx={{
        height: '345px',
        width: '100%',
        mt: '8px',
        borderRadius: '40px',
        opacity: disabled ? 0.4 : 1,
      }}
    >
      {mapKey && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapKey }}
          center={mapCenter}
          defaultZoom={defaultProps.zoom}
          onClick={handleMapClick}
        >
          {clickedMarker && <MarkerComponent {...clickedMarker} />}
        </GoogleMapReact>
      )}
    </Box>
  );
};
