import { Box } from '@mui/material';
import { Icon, Row, Text } from 'shared/ui';
import { backArrow, smallLogo } from '../../assets';
import { FC, useEffect, useState } from 'react';
import { changeLanguage, t } from 'i18next';
import localStorageService from 'shared/localStorage';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../ConfirmModal';

interface Props {
  title: string;
  hideBackButton?: boolean;
  linkBack?: string;
  subTitle?: string;
  withConfirm?: boolean;
}

export const Header: FC<Props> = ({ title, hideBackButton, linkBack, subTitle, withConfirm }) => {
  const navigate = useNavigate();
  const { isRTL } = localStorageService;
  const [showModalConfirm, setShowModalConfirm] = useState(null);

  const handleBackButton = () => {
    if (withConfirm) {
      setShowModalConfirm(true);
    } else handleBack();
  };
  const push = useNavigate();
  const handleBack = () => {
    if (linkBack) {
      push(linkBack);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Box width="100%">
        <Box sx={{ height: '64px', maxWidth: '900px' }}></Box>
        <Row
          sx={{
            padding: '8px 24px',
            paddingTop: '16px',
            justifyContent: 'space-between',
            position: 'fixed',
            flexDirection: isRTL ? 'row-reverse' : 'row',
            zIndex: '200',
            background: 'white',
            width: '100%',
            top: 0,
            cursor: 'pointer',
            maxWidth: '900px',
          }}
        >
          {hideBackButton ? (
            <Box sx={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Icon src={smallLogo} />
            </Box>
          ) : (
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#F5F5F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleBackButton}
            >
              <Icon src={backArrow} />
            </Box>
          )}
          <Row spacing={'8px'} width={'calc(100% - 80px)'} sx={{ justifyContent: 'center' }}>
            <Text sx={{ color: 'primary.dark', fontSize: '20px', fontWeight: 'bold' }}>{subTitle}</Text>
            <Text
              sx={{
                color: 'primary.dark',
                fontSize: '20px',
                fontWeight: 'bold',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Text>
          </Row>
          <Box
            sx={{
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              backgroundColor: 'primary.dark',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0,
            }}
          >
            <Text fontSize={'12px'}>AZ</Text>
          </Box>
        </Row>
      </Box>
      <ConfirmModal
        cancel={() => setShowModalConfirm(false)}
        confirm={() => handleBack()}
        title={t('leave_without_saving_changes')}
        okText={t('yes_confirm_modal')}
        show={showModalConfirm}
      />
    </>
  );
};
