import { Box, Divider, IconButton, Stack } from '@mui/material';
import { UnderConstructionBlock } from 'entities_';
import { FC, useEffect, useState } from 'react';
import { close_popup, refresh, registers } from 'shared/assets';
import { Controller, ControllerRegisters } from 'shared/models';
import { BaseBtn, BigTitle, Icon, Modal, Row, Text } from 'shared/ui';
import { ControllerHeader } from '.';
import Split from '@uiw/react-split';
import { t } from 'i18next';
import { useControllers } from 'features/ControllerManager/hooks';
import { formatDtoDate, getFormattedDateAndTime } from 'shared/lib';
import { PagePreloader } from 'components';
import { BaseDataPicker } from 'features';
import { useRef } from 'react';
import { useControllerRegisters } from 'shared/api/useControllerRegister';
import { EditRealTimeControllerRegisters } from 'widgets';
import jsPDF from 'jspdf';
import { notosanshebrew } from './notosanshebrewFont';
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
  currentController: Controller;
}
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
}

export const ControllerSettingsModal: FC<ModalState> = ({ isOpenModal, closeModal, currentController }) => {
  const { getControllerWithState, isLoadingControllers, error } = useControllers();

  const [currentControllerRealTimeData, setCurrentControllerRealTimeData] = useState(null);
  const [currentControllerHistoryData, setCurrentControllerHistoryData] = useState(null);
  const [isLoadingRealTimeData, setIsLoadingRealTimeData] = useState(false);
  const [isLoadingHistoryData, setIsLoadingHistoryData] = useState(false);
  const [historyDateValue, setHistoryDateValue] = useState(formatDtoDate(new Date(Date.now())));
  const [openRegistersModal, setIsOpenRegistersModal] = useState<boolean>(false);
  const { getControllerRegisterEvents } = useControllerRegisters();
  const [controllerRegisters, setControllerRegisters] = useState<ControllerRegisters>();
  const handleCloseEditModal = () => {
    setCurrentControllerRealTimeData(null);
    setCurrentControllerHistoryData(null);
    setHistoryDateValue(formatDtoDate(new Date(Date.now())));
    closeModal();
  };
  useEffect(() => {
    if (isOpenModal) {
      reloadRealTimeData();
      reloadHistoryData();
    }
  }, [isOpenModal]);

  const reloadRealTimeData = async () => {
    setIsLoadingRealTimeData(true);
    try {
      const result = await getControllerWithState({
        customerId: currentController.customerId,
        modelId: currentController.modelId,
        dataSourceType: 0,
        devicetId: currentController.deviceId,
      });
      const controllerData = result.data[0];
      setCurrentControllerRealTimeData(controllerData.state);
    } catch (error) {
      console.error('Failed to load real-time data', error);
    } finally {
      setIsLoadingRealTimeData(false);
    }
  };

  const reloadHistoryData = async () => {
    setIsLoadingHistoryData(true);
    try {
      const result = await getControllerWithState({
        customerId: currentController.customerId,
        modelId: currentController.modelId,
        dataSourceType: 1,
        syncDate: historyDateValue,
        devicetId: currentController.deviceId,
      });

      const controllerData = result.data[0];
      setCurrentControllerHistoryData(controllerData.state);
    } catch (error) {
      console.error('Failed to load history data', error);
    } finally {
      setIsLoadingHistoryData(false);
    }
  };
  const onChangeHistoryDateValue = (name, value) => {
    setHistoryDateValue(value);
  };

  const handleOpenRegisters = async () => {
    setIsOpenRegistersModal(true);
    const result = await getControllerRegisterEvents({ deviceId: currentController.deviceId });
    setControllerRegisters(result.data);
  };

  const closeRegistersModal = () => {
    setIsOpenRegistersModal(false);
    setControllerRegisters(null);
  };

  const realTimeDataRef = useRef<HTMLDivElement>(null);
  const historyDataRef = useRef<HTMLDivElement>(null);

  const generatePDF = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      const doc = new jsPDF();
      doc.addFileToVFS('NotoSansHebrew.ttf', notosanshebrew);
      doc.addFont('NotoSansHebrew.ttf', 'NotoSansHebrew', 'normal');
      doc.setFont('NotoSansHebrew');
      const text = ref.current.innerText;

      const lines = doc.splitTextToSize(text, 180);
      doc.text(lines, 10, 10);
      doc.save('download.pdf');
    }
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <Modal open={isOpenModal} width={'1600px'} onClose={handleModalClose}>
      <Box>
        <Stack direction="row" sx={{ mb: '16px' }} justifyContent="space-between" alignItems="center">
          <BigTitle>{currentController?.objectName}</BigTitle>
          <Row sx={{ flexGrow: 1 }} />
          <BaseBtn onClick={handleOpenRegisters} sx={{ mr: '32px' }}>
            {t('show_registers_button')}
            <Icon sx={{ ml: '6px' }} src={registers} />
          </BaseBtn>
          <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        {currentController.modelId == 'I1' || currentController.modelId == 'IP' ? (
          <Box>
            <ControllerHeader currentController={currentController} />
            <Split style={{ marginTop: '20px', border: '1px solid rgb(10, 62, 144)', direction: 'ltr' }}>
              <Box
                sx={{
                  minWidth: '100px',
                  minHeight: 'calc(100vh - 300px)',
                  maxHeight: 'calc(100vh - 300px)',
                  overflowY: 'auto',
                  width: '50%',
                  position: 'relative',
                  mt: '16px',
                  ml: '8px',
                  mr: '8px',
                }}
              >
                <Row sx={{ borderBottom: '1px solid grey', paddingBottom: '4px' }}>
                  <BigTitle>{t('real_time_title_details_of_controllers')}</BigTitle>
                  <BaseBtn onClick={reloadRealTimeData} sx={{ margin: '0 4px', height: '29px' }}>
                    <Row>
                      <Icon src={refresh} />
                      <Text
                        noWrap
                        sx={{
                          fontSize: '14px',
                          height: '24px',
                          margin: '0 4px',
                          fontFamily: 'Noto Sans Hebrew !@important',
                        }}
                      >
                        {t('reload_real_time_details_of_controllers')}
                      </Text>
                    </Row>
                  </BaseBtn>
                  <Row sx={{ flexGrow: 1 }} />
                  <BaseBtn
                    onClick={() => generatePDF(realTimeDataRef)}
                    sx={{ margin: '0 4px', height: '29px' }}
                    btnColor="info.main"
                    disabled={!currentControllerRealTimeData}
                  >
                    <Row>
                      <Text
                        noWrap
                        sx={{
                          fontSize: '14px',
                          height: '24px',
                          margin: '0 4px',
                          fontFamily: 'Noto Sans Hebrew !@important',
                          color: 'primary.dark',
                        }}
                      >
                        {t('export_To_PDF_details_of_controllers')}
                      </Text>
                    </Row>
                  </BaseBtn>
                </Row>

                <PagePreloader loading={isLoadingRealTimeData} />
                {currentControllerRealTimeData && (
                  <Box>
                    {currentController.modelId == 'I1' ? (
                      <Box ref={realTimeDataRef}>
                        <Box>{`Controller Name: ${currentController.objectName}`}</Box>
                        <Box>{`Controller ID: ${currentController.deviceId}`}</Box>
                        <Box sx={{ height: '20px' }}>{`\n `}</Box>
                        <Box>{`Sync date: ${getFormattedDateAndTime(currentControllerRealTimeData.syncDate)}`}</Box>
                        <Box>{`Version: ${currentControllerRealTimeData.version}`}</Box>
                        <Box>{`1 pulse = ${currentControllerRealTimeData.literPerPulse} Liter`}</Box>
                        <Box>
                          {`Main Valve: ${
                            currentControllerRealTimeData && currentControllerRealTimeData.hasMainValve ? 'Yes' : 'No'
                          }`}
                        </Box>
                        <Box
                          style={{ whiteSpace: 'pre' }}
                        >{`${currentControllerRealTimeData?.programsValvesInfo?.infoString}`}</Box>
                      </Box>
                    ) : (
                      currentController.modelId == 'IP' && (
                        <Box>
                          <Box>{`ObjectID=${currentController.deviceId}`}</Box>
                          <Box>{`ModelID: ${currentController.modelId}`}</Box>
                          <Box>{`Sync date: ${getFormattedDateAndTime(currentControllerRealTimeData.syncDate)}`}</Box>
                          <Box>{`Program Version: ${currentControllerRealTimeData.version}`}</Box>
                          <Box sx={{ height: '20px' }}>{`\n `}</Box>
                          <Box>{`Flowmeter exist: ${currentControllerRealTimeData.flowmeterExist}`}</Box>
                          <Box>{`Main valve exist: ${currentControllerRealTimeData.mainValveExist}`}</Box>
                          <Box>{`Flow calculation: ${currentControllerRealTimeData.flowCalculation}`}</Box>
                          <Box>{`Pump #1 exist: ${currentControllerRealTimeData.pump1Exist}`}</Box>
                          <Box>{`Pump #2 exist: ${currentControllerRealTimeData.pump2Exist}`}</Box>
                          <Box sx={{ height: '20px' }}>{`\n `}</Box>
                          <Box>{`Leakage control mode: ${currentControllerRealTimeData.leakageControlMode}`}</Box>
                          <Box>{`Pulse to liter ratio: ${currentControllerRealTimeData.pulseToLiterRatio}`}</Box>
                          <Box>{`Leak detection time: ${currentControllerRealTimeData.leakDetectionTime}min`}</Box>
                          <Box>{`Leak detection percent: ${currentControllerRealTimeData.leakDetectionPercent}%`}</Box>
                          <Box>{`Uncontrolled flow detection time: ${currentControllerRealTimeData.uncontrolledFlowDetectioTime}sec`}</Box>
                          <Box>{`Uncontrolled flow detection pulses: ${currentControllerRealTimeData.uncontrolledFlowDetectionPulses}`}</Box>
                          <Box>{`Close valve delay: ${currentControllerRealTimeData.closeValveDelay}sec`}</Box>
                          <Box>{`Valve open voltage: ${currentControllerRealTimeData.valveOpenVoltage}V`}</Box>
                          <Box>{`Valve open time: ${currentControllerRealTimeData.valveOpenTime}ms`}</Box>
                          <Box>{`Valve close voltage: ${currentControllerRealTimeData.valveCloseVoltage}V`}</Box>
                          <Box>{`Valve close time: ${currentControllerRealTimeData.valveCloseTime}ms`}</Box>
                          <Box>{`Manual open time: ${currentControllerRealTimeData.manualOpenTime}sec`}</Box>
                          <Box sx={{ height: '20px' }}>{`\n `}</Box>
                          <Box>{`Main valve usage slaves (1-16): ${currentControllerRealTimeData.mainValveUsageSlaves1to16}`}</Box>
                          <Box>{`Main valve usage slaves (17-32): ${currentControllerRealTimeData.mainValveUsageSlaves17to32}`}</Box>
                          <Box>{`Pump #1 usage slaves (1-16): ${currentControllerRealTimeData.pump1UsageSlaves1to16}`}</Box>
                          <Box>{`Pump #1 usage slaves (17-32):${currentControllerRealTimeData.pump1UsageSlaves17to32}`}</Box>
                          <Box>{`Pump #2 usage slaves (1-16):${currentControllerRealTimeData.pump2UsageSlaves1to16}`}</Box>
                          <Box>{`Pump #2 usage slaves (17-32): ${currentControllerRealTimeData.pump2UsageSlaves17to32}`}</Box>
                        </Box>
                      )
                    )}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  minWidth: '150px',
                  minHeight: 'calc(100vh - 280px)',
                  maxHeight: 'calc(100vh - 280px)',
                  overflowY: 'auto',
                  flex: 1,
                  position: 'relative',
                  mt: '0px',
                  ml: '8px',
                  mr: '8px',
                }}
              >
                <Row sx={{ borderBottom: '1px solid grey', paddingBottom: '4px' }}>
                  <BigTitle sx={{ mr: '8px', ml: '8px', mt: '16px' }}>
                    {t('history_title_details_of_controllers')}
                  </BigTitle>
                  <BaseDataPicker
                    label={`${t('history_date_details_of_controllers')}`}
                    name="historyDate"
                    value={historyDateValue}
                    onChange={onChangeHistoryDateValue}
                    maxWidth={'160px'}
                  />
                  <BaseBtn onClick={reloadHistoryData} sx={{ margin: '0 8px', mt: '16px', height: '29px' }}>
                    <Row>
                      <Icon src={refresh} />
                      <Text
                        noWrap
                        sx={{
                          fontSize: '14px',
                          height: '24px',
                          margin: '0 4px',
                          fontFamily: 'Noto Sans Hebrew !@important',
                        }}
                      >
                        {t('reload_history_details_of_controllers')}
                      </Text>
                    </Row>
                  </BaseBtn>
                  <Row sx={{ flexGrow: 1 }} />
                  <BaseBtn
                    onClick={() => generatePDF(historyDataRef)}
                    sx={{ margin: '0 8px', mt: '16px', height: '29px' }}
                    btnColor="info.main"
                    disabled={!currentControllerHistoryData}
                  >
                    <Row>
                      <Text
                        noWrap
                        sx={{
                          fontSize: '14px',
                          height: '24px',
                          margin: '0 4px',
                          fontFamily: 'Noto Sans Hebrew !@important',
                          color: 'primary.dark',
                        }}
                      >
                        {t('export_To_PDF_details_of_controllers')}
                      </Text>
                    </Row>
                  </BaseBtn>
                </Row>
                <PagePreloader loading={isLoadingHistoryData} />
                {currentControllerHistoryData && (
                  <Box>
                    {' '}
                    {currentController.modelId == 'I1' ? (
                      <Box ref={historyDataRef}>
                        <Box>{`Controller Name: ${currentController.objectName}`}</Box>
                        <Box>{`Controller ID: ${currentController.deviceId}`}</Box>
                        <Box sx={{ height: '20px' }}>{`\n `}</Box>
                        <Box>{`Sync date: ${getFormattedDateAndTime(currentControllerHistoryData.syncDate)}`}</Box>
                        <Box>{`Version: ${currentControllerHistoryData.version}`}</Box>
                        <Box>{`1 pulse = ${currentControllerHistoryData.literPerPulse} Liter`}</Box>
                        <Box>
                          {`Main Valve: ${
                            currentControllerHistoryData && currentControllerHistoryData.hasMainValve ? 'Yes' : 'No'
                          }`}
                        </Box>
                        <Box
                          style={{ whiteSpace: 'pre' }}
                        >{`${currentControllerHistoryData?.programsValvesInfo?.infoString}`}</Box>
                      </Box>
                    ) : (
                      currentController.modelId == 'IP' && (
                        <Box>
                          <Box>{`ObjectID=${currentController.deviceId}`}</Box>
                          <Box>{`ModelID: ${currentController.modelId}`}</Box>
                          <Box>{`Sync date: ${getFormattedDateAndTime(currentControllerHistoryData.syncDate)}`}</Box>
                          <Box>{`Program Version: ${currentControllerHistoryData.version}`}</Box>
                          <Box sx={{ height: '20px' }}>{`\n `}</Box>
                          <Box>{`Flowmeter exist: ${currentControllerHistoryData.flowmeterExist}`}</Box>
                          <Box>{`Main valve exist: ${currentControllerHistoryData.mainValveExist}`}</Box>
                          <Box>{`Flow calculation: ${currentControllerHistoryData.flowCalculation}`}</Box>
                          <Box>{`Pump #1 exist: ${currentControllerHistoryData.pump1Exist}`}</Box>
                          <Box>{`Pump #2 exist: ${currentControllerHistoryData.pump2Exist}`}</Box>
                          <Box sx={{ height: '20px' }}>{`\n `}</Box>
                          <Box>{`Leakage control mode: ${currentControllerHistoryData.leakageControlMode}`}</Box>
                          <Box>{`Pulse to liter ratio: ${currentControllerHistoryData.pulseToLiterRatio}`}</Box>
                          <Box>{`Leak detection time: ${currentControllerHistoryData.leakDetectionTime}min`}</Box>
                          <Box>{`Leak detection percent: ${currentControllerHistoryData.leakDetectionPercent}%`}</Box>
                          <Box>{`Uncontrolled flow detection time: ${currentControllerHistoryData.uncontrolledFlowDetectioTime}sec`}</Box>
                          <Box>{`Uncontrolled flow detection pulses: ${currentControllerHistoryData.uncontrolledFlowDetectionPulses}`}</Box>
                          <Box>{`Close valve delay: ${currentControllerHistoryData.closeValveDelay}sec`}</Box>
                          <Box>{`Valve open voltage: ${currentControllerHistoryData.valveOpenVoltage}V`}</Box>
                          <Box>{`Valve open time: ${currentControllerHistoryData.valveOpenTime}ms`}</Box>
                          <Box>{`Valve close voltage: ${currentControllerHistoryData.valveCloseVoltage}V`}</Box>
                          <Box>{`Valve close time: ${currentControllerHistoryData.valveCloseTime}ms`}</Box>
                          <Box>{`Manual open time: ${currentControllerHistoryData.manualOpenTime}sec`}</Box>
                          <Box sx={{ height: '20px' }}>{`\n `}</Box>
                          <Box>{`Main valve usage slaves (1-16): ${currentControllerHistoryData.mainValveUsageSlaves1to16}`}</Box>
                          <Box>{`Main valve usage slaves (17-32): ${currentControllerHistoryData.mainValveUsageSlaves17to32}`}</Box>
                          <Box>{`Pump #1 usage slaves (1-16): ${currentControllerHistoryData.pump1UsageSlaves1to16}`}</Box>
                          <Box>{`Pump #1 usage slaves (17-32):${currentControllerHistoryData.pump1UsageSlaves17to32}`}</Box>
                          <Box>{`Pump #2 usage slaves (1-16):${currentControllerHistoryData.pump2UsageSlaves1to16}`}</Box>
                          <Box>{`Pump #2 usage slaves (17-32): ${currentControllerHistoryData.pump2UsageSlaves17to32}`}</Box>
                        </Box>
                      )
                    )}
                  </Box>
                )}
              </Box>
            </Split>
          </Box>
        ) : (
          <UnderConstructionBlock text={t('display_option_for_this_controller_is_not_yet_implemented')} />
        )}
      </Box>
      {controllerRegisters && (
        <EditRealTimeControllerRegisters
          isOpenModal={openRegistersModal}
          closeModal={closeRegistersModal}
          controllerRegisters={controllerRegisters}
          currentController={currentController}
          setControllerRegisters={setControllerRegisters}
        />
      )}
    </Modal>
  );
};
