import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from '@mui/material';
import { CustomFormControlLabel } from '../../shared/ui/CustomFormControlLabel';
import { BaseBtn, BaseInput, Icon, Row, SearchInput, Text } from '../../shared/ui';
import { PlayArrow } from '@mui/icons-material';
import { useAnchorEl, useExtendedTranslation } from '../../shared/hooks';
import { clear_filter, home } from 'shared/assets';
import { SxProps } from '@mui/system';
import { autoFillColor } from 'shared/config';
import { isHebrewFont } from 'shared/lib';
import { forwardRef } from 'react';
import localStorageService from 'shared/localStorage';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx, showHomeButton }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '29px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={showHomeButton ? home : clear_filter} />
  </IconButton>
);
interface Props {
  title: string;
  items: { value: string; label: string }[];
  value?: string;
  onChange?: (value: string) => void;
  withSearch?: boolean;
  withConfirm?: boolean;
  hideAllButton?: boolean;
  showHomeButton?: boolean;
  defaultValue?: string;
  sx?: SxProps;
  sxBtn?: SxProps;
  sxLabel?: SxProps;
  showOnlyResetButton?: boolean;
  noSelectLabel?: string;
  formInput?: boolean;
  disabled?: boolean;
  hideValue?: boolean;
  hideResetButton?: boolean;
  controlsWidth?: number;
  error?: boolean;
  helperText;
  small?: boolean;
  compact?: boolean;
  type?: string;
  width?: string;
  isTextInput?: boolean;
  playArrowHeight?: string;
  translate?: boolean;
  popoverMinWidth?: string;
  onSelect?: (value: string) => void;
  onBlur?: (value: string) => void;
}

export const BaseInputAndSelect = forwardRef<unknown, Props>(
  (
    {
      sx,
      sxBtn,
      sxLabel,
      title,
      formInput,
      items,
      value,
      onChange,
      withSearch,
      hideAllButton,
      showHomeButton,
      defaultValue,
      showOnlyResetButton,
      noSelectLabel,
      disabled,
      hideValue,
      hideResetButton,
      controlsWidth,
      error,
      helperText,
      small,
      compact,
      type,
      width,
      isTextInput,
      playArrowHeight,
      translate,
      popoverMinWidth,
      onSelect,
      onBlur,
      ...props
    },
    ref
  ) => {
    const { t } = useExtendedTranslation();
    const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
    const [innerValue, setInnerValue] = useState<string>('');
    const [valueItems, setValueItems] = useState<{ value: string; label: string }[]>(items);
    const [filterItems, setfilterItems] = useState<{ value: string; label: string }[]>(items);
    const { palette, direction } = useTheme();
    const isRTL = localStorageService.isRTL;
    const handleChange = (value: string) => {
      onChange?.(value);
      onCloseMenu();
    };
    useEffect(() => {
      setValueItems([...items]);
    }, [items]);

    useEffect(() => {
      setInnerValue(value);
    }, [value]);
    const isHebrew = isHebrewFont(value?.toString() ?? '');
    const currentFilter = useMemo(() => valueItems.find((item) => item.value === value), [value, valueItems]);
    const [search, setSearch] = useState('');
    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    useEffect(() => {
      setfilterItems(
        valueItems.filter((i) => {
          const findByLabel = i.label.toLowerCase().includes(search.toLowerCase().trim());
          const findByValue = i.value.toLowerCase().includes(search.toLowerCase().trim());
          return findByLabel || findByValue;
        })
      );
    }, [search, valueItems]);

    const handleClose = () => {
      onCloseMenu();
    };

    const clearFilterValue = () => onChange?.('');

    const homeButtonClick = () => {
      if (defaultValue) {
        handleChange(defaultValue);
      }
    };
    const isShowResetButton = () => {
      if (hideResetButton) return;
      return value && !isNaN(parseInt(value)) && (value === '87' || showOnlyResetButton);
    };
    const isShowHomeButton = () => {
      return value && !isNaN(parseInt(value)) && value !== '87' && !showOnlyResetButton;
    };
    const baseInputRef = useRef(null);
    return (
      <Box sx={{ ...sx }}>
        {formInput ? (
          <Text
            noWrap
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              textTransform: 'capitalize',
              color: palette.primary.dark,
              marginBottom: '3px',
              marginTop: '3px',
              ...sxLabel,
            }}
          >
            {title}
          </Text>
        ) : (
          <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px' }}>
            {title}
          </Text>
        )}

        <Box
          position="relative"
          sx={{
            justifyContent: value !== '' && value && !isNaN(parseInt(value)) ? 'space-between' : '',
            width: '100%',
          }}
        >
          {/* showHomeButton */}
          {showHomeButton && <>{isShowHomeButton() && <ResetButton showHomeButton onClick={homeButtonClick} />}</>}
          {<>{isShowResetButton() ? <ResetButton onClick={clearFilterValue} /> : null}</>}
          {/* {<>{value && !isNaN(parseInt(value)) ? <ResetButton onClick={clearFilterValue} /> : null}</>} */}
          {/* <ResetButton showHomeButton={!!showHomeButton} onClick={resetFilters} /> */}
          <BaseInput
            error={error}
            helperText={helperText}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            small={small}
            compact={compact}
            ref={baseInputRef}
            type={isTextInput ? 'text' : 'number'}
            sx={{
              opacity: disabled ? '0.7' : '1',
              width: width,
              fieldset: { borderColor: 'rgba(122, 122, 122, 1)' },
              ...sxBtn,
            }}
            blur={(value) => {
              onBlur && onBlur(value);
            }}
            isTextDirectionRtl={isRTL}
            disabled={disabled}
          />
          <BaseBtn
            btnColor="info.main"
            disabled={disabled}
            onClick={onOpenMenu}
            sx={{
              borderColor: '#00000000',
              opacity: '1 !important',
              height: playArrowHeight ? playArrowHeight : '20px',
              minWidth: '40px',
              px: 0,
              '&:hover': {
                borderColor: '#00000000',
                // backgroundColor: 'white',
              },
              position: 'absolute',
              right: '2px',
              top: '5px',
              zIndex: '99',
              // backgroundColor: 'white',
            }}
          >
            <PlayArrow
              sx={{
                m: '7px',
                fontSize: '14px',
                color: formInput ? palette.info.dark : 'primary.dark',
                transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
              }}
            />
          </BaseBtn>
        </Box>

        <Popover
          open={isOpen}
          anchorEl={baseInputRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={{
            position: 'absolute',
            left: '0',
            mt: '-10px',
            '& .MuiPaper-root': {
              borderRadius: '12px',
            },
          }}
        >
          <Box>
            {withSearch && (
              <SearchInput
                onfocus
                value={search}
                onChange={handleChangeSearch}
                placeholder={`${t('filter_search_placeholder')}`}
                noBorder
              />
            )}

            <Box
              sx={{
                borderTop: '1px solid grey',
                borderColor: withSearch ? 'info.dark' : 'info.main',
                minWidth: popoverMinWidth ? popoverMinWidth : '',
              }}
            >
              {filterItems.length === 0 ? (
                <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>{t('filter_no_items')}</Text>
              ) : (
                <FormControl sx={{ width: controlsWidth ? `${controlsWidth}px` : '100%' }}>
                  <RadioGroup value={value} sx={{ width: '100%' }}>
                    {!hideAllButton && (
                      <Box
                        sx={{
                          borderBottom: '1px solid black',
                          borderColor: 'primary.light',
                          px: '8px',
                          mb: '2px',
                        }}
                      >
                        <Box
                          onClick={() => handleChange('')}
                          sx={{
                            borderRadius: '10px',
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'primary.light' },
                          }}
                        >
                          <FormControlLabel
                            control={<Radio checked={value === ''} size="small" />}
                            label={
                              <Text sx={{ color: 'black', pr: '10px' }}>
                                {noSelectLabel ? noSelectLabel : t('display_all_filter_base_select')}
                              </Text>
                            }
                            sx={{
                              fontSize: '11px',
                              mx: 0,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    <>
                      <Box
                        sx={{
                          maxHeight: '500px',
                        }}
                      >
                        {defaultValue && (
                          <CustomFormControlLabel
                            sx={{
                              color: 'primary.dark',
                              fontWeight: '700',
                            }}
                            key={value}
                            onChange={() => {
                              handleChange(defaultValue);
                              onSelect && onSelect(defaultValue);
                            }}
                            value={defaultValue}
                            selected
                            labels={{
                              value: defaultValue,
                              label: items.find((item) => item.value === defaultValue)?.label || '',
                            }}
                          />
                        )}
                        {filterItems.map(({ value, label }) => (
                          <CustomFormControlLabel
                            key={value}
                            hideValue={hideValue}
                            onChange={() => {
                              handleChange(translate ? t(label) : value);
                              onSelect && onSelect(translate ? t(label) : value);
                            }}
                            value={value}
                            labels={{ value, label }}
                            translate={translate}
                          />
                        ))}
                      </Box>
                    </>
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
          </Box>
        </Popover>
      </Box>
    );
  }
);
