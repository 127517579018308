import { QuantityAndPrice } from '../../Components/MaterialsAndPricingTable';
import { ChangedSiteMaterials, SiteMaterials } from '../../ISSModels';

export const compareMaterials = (
  siteMaterials: SiteMaterials[],
  localSiteMaterials: QuantityAndPrice[],
  siteId: string
) => {
  const newItems: ChangedSiteMaterials[] = [];
  localSiteMaterials.forEach((item) => {
    const elem = siteMaterials.find((el) => el.materialID === item.id);
    if (!elem) {
      newItems.push({
        siteID: parseInt(siteId),
        materialID: item.id,
        quantity: item.quantity,
        delete: false,
      });
    } else if (elem.quantity != item.quantity) {
      newItems.push({
        siteID: parseInt(siteId),
        materialID: item.id,
        quantity: item.quantity,
        delete: false,
      });
    }
  });

  siteMaterials.forEach((siteItem) => {
    const existsInLocal = localSiteMaterials.some((localItem) => localItem.id === siteItem.materialID);

    if (!existsInLocal) {
      newItems.push({
        siteID: parseInt(siteId),
        materialID: siteItem.materialID,
        quantity: siteItem.quantity,
        delete: true,
      });
    }
  });

  return newItems;
};
