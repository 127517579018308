import { t } from 'i18next';
import { BigTitle, Layout } from '../../shared/ui';
import { Header } from '../../widgets';
import { Kvish6Manager } from 'features';

export const Kvish6 = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <Kvish6Manager />
      </Layout.Body>
    </Layout>
  );
};
