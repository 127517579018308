import localStorageService from "shared/localStorage";

export const WEEK_DAY_NAMES_ENG =  ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

export const WEEK_DAY_NAMES_HE = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];

export const getDateValues = (stamp: string | Date| number) => {
  const date = new Date(stamp);
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const year = date.getFullYear().toString();
  const hour = date.getHours().toString();
  const minutes = date.getMinutes();
  const min = minutes < 10 ? '0' + minutes : minutes.toString();
  const weekDay = date.getDay(); 
  const seconds = date.getSeconds();
  const sec = seconds < 10 ? '0' + seconds : seconds.toString();
  return { year, month, day, hour, min, weekDay, sec};
};

export const getFormattedDateAndTime = (stamp: string | Date | number) => {
  const { year, month, day, hour, min } = getDateValues(stamp);

  return `${day}.${month}.${year} ${hour}:${min}`;
};
export const getFormattedTime = (stamp: string | Date | number) => {
  const { hour, min,sec } = getDateValues(stamp);

  return `${hour}:${min}:${sec}`;
};
export const getFormattedDateAndTimeAndWeekDay = (stamp: string | Date | number) => {
  const { isRTL } = localStorageService;
  const { year, month, day, hour, min, weekDay} = getDateValues(stamp);

  return `${day}.${month}.${year} ${hour}:${min} ${isRTL ? WEEK_DAY_NAMES_HE[weekDay] : WEEK_DAY_NAMES_ENG[weekDay]}`;
};
export const getFormattedDate = (stamp: string | Date ) => {
  const { year, month, day } = getDateValues(stamp);
  return `${day}.${month}.${year}`;
};

export const formatDtoDate = (stamp: string | Date) => {
  const { year, month, day } = getDateValues(stamp);

  return `${year}-${month}-${day}`;
};

export const formatDtoDateTime = (stamp: string | Date | number) => {
  const { year, month, day, hour, min,} = getDateValues(stamp);

  return `${year}-${month}-${day}`;
};