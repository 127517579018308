import { Box, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useExtendedTranslation, useToast } from 'shared/hooks';
import { Icon, Loader, Row, Text } from '../../../../shared/ui';

import EXIF from 'exif-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import { SiteMaterialsItems } from '../ISSExport/components/ExportToExcel';
import { ISSPhoto, ISSProject, ISSSite } from '../ISSModels';
import { exportIcon, plusWhiteIcon } from '../assets';
import { useISSData, useISSPhotos, useMaterialListFormat, useSiteMaterials } from '../hooks';
import { Background, MobileButton, MobileInput, MobileSelect } from '../ui';
import { SiteCard } from './components';
import { useExport } from './helpers';
import localStorageService from 'shared/localStorage';
import { ExportToExcel } from './components/ExportToExcel';
import Layout from '../Components/Layout';

export const ISSSitesPage = () => {
  const { t } = useExtendedTranslation();
  const { updateCurrentProject, getISSSites, getCurrentProject } = useISSData();
  const { getProjectMaterialListFormats, getMaterialListFormats } = useMaterialListFormat();
  const { getCompressedPhotos } = useISSPhotos();
  const { ISSSites, materialLists, materialListFormats } = useAppSelector((st) => st.ISSSlice);
  const [searchText, setSearchText] = useState<string>('');
  const [searchParams] = useSearchParams();
  const push = useNavigate();
  const { exportToWord } = useExport();
  const { getMaterialLists, getSiteMaterialLists } = useSiteMaterials();
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<ISSProject>();
  const { showSuccess } = useToast();
  const [projectName, setProjectName] = useState('');
  const [discount, setDiscount] = useState<number>();
  const [currentProjectMLFormat, setCurrentProjectMLFormat] = useState<string>();
  const [localProjectMLFormat, setLocalProjectMLFormat] = useState<string>();
  const [showProjectNameError, setShowProjectNameError] = useState<boolean>();
  const [discountError, setDiscountError] = useState<boolean>();

  const isRTL = localStorageService.isRTL;
  const searchSites = (filteredItems: ISSSite[], searchText: string) => {
    const searched = filteredItems.filter((c) => {
      const values = Object.values(c);

      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim()));
    });

    return searched;
  };

  const ISSSitesFiltered = useMemo(() => {
    const projectID = searchParams.get('projectId');

    const searchedSites = searchSites(ISSSites, searchText);

    if (!projectID) return searchedSites;

    return searchedSites.filter((item) => item.projectID.toString() === projectID);
  }, [ISSSites, searchText]);

  const mLFormats = useMemo(() => {
    return materialListFormats.map((item) => ({
      value: item.id.toString(),
      label: item.formatName,
    }));
  }, [materialListFormats]);

  const initCurrentProject = async () => {
    const projectID = searchParams.get('projectId');
    if (projectID) {
      const result = await getCurrentProject(parseInt(projectID));
      setCurrentProject(result.data);
      setProjectName(result.data.projectName);
      setDiscount(result.data.discount);
      const projectMLFormats = await getProjectMaterialListFormats();
      const currentProjectMLFormat = projectMLFormats.data.find((item) => item.projectID.toString() == projectID);
      if (currentProjectMLFormat) {
        setLocalProjectMLFormat(currentProjectMLFormat.materialListFormatID.toString());
        setCurrentProjectMLFormat(currentProjectMLFormat.materialListFormatID.toString());
      }
    }
  };
  useEffect(() => {
    getISSSites();
    getMaterialLists();
    getSiteMaterialLists();
    getMaterialListFormats();
    initCurrentProject();
  }, []);

  const findImageSize = async (images: ISSPhoto[]) => {
    const updatedImages = await Promise.all(
      images.map((image) => {
        return new Promise<ISSPhoto>((resolve) => {
          const img = new Image();
          img.src = `data:image/jpeg;base64,${image.photoData}`;

          img.onload = () => {
            const jpegData = `data:image/jpeg;base64,${image.photoData}`;
            // const binaryData = piexif.remove(jpegData);
            const binaryData = jpegData;
            const newImg = new Image();
            newImg.src = binaryData;

            newImg.onload = () => {
              EXIF.getData(img as any, function () {
                const orientation = EXIF.getTag(this, 'Orientation');

                resolve({
                  ...image,
                  width: newImg.width,
                  height: newImg.height,
                  orientation: orientation || 1,
                });
              });
            };
          };

          img.onerror = () => {
            resolve({
              ...image,
              width: 0,
              height: 0,
              // EXIF-дані відсутні
            });
          };
        });
      })
    );

    return updatedImages;
  };

  const onChangeSearch = (value: string) => {
    setSearchText(value);
  };
  const handleSelectedSite = (id: number, idProject: number) => {
    push(`/mobile/iss/site-management?projectId=${idProject}&siteId=${id}`);
  };
  const fileGenerated = () => {
    setIsReportGenerating(false);
  };
  const handleExport = async () => {
    setIsReportGenerating(true);
    const result = await getSiteMaterialLists();
    const siteIds = ISSSitesFiltered.map((item) => item.id);
    const photos = await getCompressedPhotos(siteIds);
    //const photos = await getCompressedPhotos([10]);

    let photoToExport = [];
    if (photos) {
      photoToExport = photos.data.map((item) => {
        return {
          id: item.id,
          siteID: item.siteID,
          photoOrder: item.photoOrder,
          creationDate: item.creationDate,
          photoData: item.compressedPhotoData,
        };
      });
    }

    const sortedSitesData: { data: ISSSite; materials: SiteMaterialsItems[]; images: ISSPhoto[] }[] = [];
    for (const item of ISSSitesFiltered) {
      const siteMaterials = result.data;
      const currentMaterials = siteMaterials.filter((el) => el.siteID == item.id);
      const currentPhotos: ISSPhoto[] = photoToExport.filter((el) => el.siteID == item.id);
      const siteData = currentMaterials.map((material) => {
        const currentMaterial = materialLists.find((item) => item.id == material.materialID);
        const materialCost = parseFloat((currentMaterial.unitCost * material.quantity).toFixed(2));
        return {
          quantity: material.quantity,
          materialCode: currentMaterial.materialCode,
          unitType: currentMaterial.unitType,
          longDescription: currentMaterial.longDescription,
          unitCost: currentMaterial.unitCost,
          materialCost: materialCost,
          siteName: item.siteName,
        };
      });
      const imagesWithSize = await findImageSize(currentPhotos);
      sortedSitesData.push({ data: item, materials: siteData, images: imagesWithSize });
    }
    await exportToWord(sortedSitesData, currentProject, fileGenerated);
  };

  const handleAddSite = () => {
    const projectId = searchParams.get('projectId');
    if (projectId) {
      push(`/mobile/iss/add-site?projectId=${projectId}`);
      return;
    }
    push(`/mobile/iss/add-site`);
  };

  const onChangeProjectName = (value: string) => {
    setShowProjectNameError(false);
    setProjectName(value);
  };

  const onChangeDiscount = (value: string) => {
    setDiscountError(false);
    const discount = parseInt(value);
    if (discount > 100) {
      setDiscount(100);
      return;
    }
    setDiscount(discount);
  };

  const onChangeMaterialsListFormat = (value: string) => {
    setCurrentProjectMLFormat(value);
  };

  const updateProjectName = async () => {
    if (!projectName && !projectName.trim()) {
      setShowProjectNameError(true);
      return;
    }

    const result = await updateCurrentProject({
      projectID: currentProject.id,
      projectName: projectName,
    });
    if (result.data) {
      showSuccess(t('project_successfully_updated_toast_text'));
    }
    initCurrentProject();
  };
  const updateDiscount = async () => {
    if (isNaN(discount) || discount == null || discount < 0 || discount > 100) {
      setDiscountError(true);
      return;
    }

    const result = await updateCurrentProject({
      projectID: currentProject.id,
      discount: discount,
    });
    if (result.data) {
      showSuccess(t('project_successfully_updated_toast_text'));
    }
    initCurrentProject();
  };
  const updateProjectMLFormat = async () => {
    const result = await updateCurrentProject({
      projectID: currentProject.id,
      materialListFormatID: parseInt(currentProjectMLFormat),
    });
    if (result.data) {
      showSuccess(t('project_successfully_updated_toast_text'));
    }
    initCurrentProject();
  };
  const isChangedProject = () => {
    if (!currentProject) return;
    return !(
      currentProject.projectName == projectName &&
      currentProject.discount == discount &&
      localProjectMLFormat == currentProjectMLFormat
    );
  };
  return (
    <Box>
      <Layout>
        <Box sx={{ position: 'relative' }}>
          {isReportGenerating && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
                zIndex: '30',
                position: 'absolute',
                top: '0px',
                opacity: '0.9',
                left: '0',
              }}
            >
              <Loader />
            </Box>
          )}

          <Header
            subTitle={currentProject ? t('project_name_title') : ''}
            title={currentProject ? currentProject.projectName : t('irrigation_system_survey')}
            withConfirm={isChangedProject()}
            linkBack={'/mobile/iss/projects'}
          />
          <Box sx={{ padding: '0 24px' }}>
            {currentProject ? (
              <Box>
                <Row alignItems={'flex-end'} sx={{ mb: '16px' }}>
                  <MobileInput
                    label={`${t('project_name_label_project_management')}`}
                    name="project_name"
                    error={showProjectNameError}
                    helperText={showProjectNameError ? 'this_field_cannot_be_empty' : ''}
                    onChange={(value) => onChangeProjectName(value.target.value)}
                    value={projectName}
                    compact
                    placeholder={''}
                    fullWidth
                  />
                  <Box width="20px"></Box>

                  <MobileButton
                    disabled={currentProject.projectName == projectName || !projectName.trim()}
                    btnColor="#1C5B9C"
                    sx={{ width: '160px', height: '40px' }}
                    onClick={updateProjectName}
                  >
                    <Text>{`${t('save_project_name_project_management')}`}</Text>
                  </MobileButton>
                </Row>
                <Row alignItems={'flex-end'} sx={{ mb: '16px' }}>
                  <MobileInput
                    label={`${t('discount_label_project_management')}`}
                    name="discount"
                    error={discountError}
                    helperText={discountError ? 'this_field_cannot_be_empty' : ''}
                    onChange={(value) => onChangeDiscount(value.target.value)}
                    value={discount}
                    compact
                    placeholder={''}
                    fullWidth
                    type="number"
                  />

                  <Box width="20px"></Box>
                  <MobileButton
                    disabled={currentProject.discount == discount}
                    btnColor="#1C5B9C"
                    sx={{ width: '160px', height: '40px' }}
                    onClick={updateDiscount}
                  >
                    <Text>{`${t('save_discount_project_management')}`}</Text>
                  </MobileButton>
                </Row>
                <Row alignItems={'flex-end'} sx={{ mb: '16px' }}>
                  <MobileSelect
                    title={t('materials_list_format_label_project_management')}
                    items={mLFormats}
                    error={false}
                    noSelectLabel={''}
                    onChange={(value) => onChangeMaterialsListFormat(value)}
                    value={currentProjectMLFormat}
                    fullWidth
                  />

                  <Box width="20px"></Box>
                  <MobileButton
                    disabled={localProjectMLFormat == currentProjectMLFormat}
                    btnColor="#1C5B9C"
                    sx={{ width: '160px', height: '40px' }}
                    onClick={updateProjectMLFormat}
                  >
                    <Text>{`${t('save_materials_list_format_project_management')}`}</Text>
                  </MobileButton>
                </Row>
              </Box>
            ) : (
              <Box height={'195px'}></Box>
            )}
            <Divider sx={{ mt: '18px' }} />
            <Row justifyContent={'space-between'} alignItems={'flex-end'} sx={{ mb: '16px' }}>
              <Text sx={{ color: 'black', fontSize: '20px' }}>{t('sites_list_sites_page')}</Text>
              <MobileButton btnColor="#1C5B9C" sx={{ width: '50%', height: '40px' }} onClick={handleAddSite}>
                <Icon height={'10px'} sx={{ mx: '4px' }} src={plusWhiteIcon} />
                <Text>{`${t('add_site_site_page')}`}</Text>
              </MobileButton>
            </Row>

            <Box>
              <MobileInput
                label={``}
                name="search"
                error={false}
                helperText={''}
                onChange={(value) => onChangeSearch(value.target.value)}
                value={searchText}
                search
                compact
                placeholder={''}
              />
              <Row>
                {/* <MobileButton btnColor="#1C5B9C">
              <Text>{`${t('list_button_site_page')}`}</Text>
            </MobileButton>
            <Box width="40px"></Box>
            <MobileButton btnColor="info.main">
              <Text sx={{ color: 'black' }}>{`${t('map_button_site_page')}`}</Text>
            </MobileButton> */}
              </Row>
              {ISSSitesFiltered.length ? (
                <Box sx={{ marginTop: '16px' }}>
                  {ISSSitesFiltered.map((item, index) => (
                    <Box key={`key-${item.id}`}>
                      {<SiteCard site={item} handleClick={() => handleSelectedSite(item.id, item.projectID)} />}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
            <Row>
              <MobileButton
                sx={{ mb: '16px' }}
                btnColor="info.main"
                onClick={handleExport}
                loading={isReportGenerating}
              >
                <Icon src={exportIcon} sx={{ mx: '4px' }} />
                <Text sx={{ color: '#1C5B9C' }}>{`${t('export_word_site_page')}`}</Text>
              </MobileButton>
              <Box width="40px"></Box>
              <ExportToExcel
                ISSSitesFiltered={ISSSitesFiltered}
                sheetName={'Report'}
                fileName={'ReportISS'}
                buttonText={`${t('export_excel_export_page')}`}
                headerItemsPrefix={'_material_table'}
              />
            </Row>
          </Box>
          <Background />
          <Footer showPlus handlePlusClick={handleAddSite} />
        </Box>
      </Layout>
    </Box>
  );
};
