import { Box } from '@mui/material';
import { backArrow, chevron, closeIcon, deletePhotoIcon } from 'features/Mobile/ISS/assets';
import localStorageService from 'shared/localStorage';
import { Icon } from 'shared/ui';

interface PhotoData {
  data_url: string;
  id: number;
}
interface Props {
  imageId: number;
  photoList: PhotoData[];
  changeSelectedImage: (id: number) => void;
}

export const ImageGallery = ({ imageId, photoList, changeSelectedImage }: Props) => {
  const { isRTL } = localStorageService;
  const getImage = () => {
    const index = photoList.findIndex((item) => item.id == imageId);
    return photoList[index];
  };
  const handleClose = () => {
    changeSelectedImage(null);
  };
  const handlePreImage = () => {
    const index = photoList.findIndex((item) => item.id === imageId);
    const prevIndex = index === 0 ? photoList.length - 1 : index - 1;
    changeSelectedImage(photoList[prevIndex].id);
  };

  const handleNextImage = () => {
    const index = photoList.findIndex((item) => item.id === imageId);
    const nextIndex = index === photoList.length - 1 ? 0 : index + 1;
    changeSelectedImage(photoList[nextIndex].id);
  };
  return (
    <Box>
      <Box
        className="image-item"
        sx={{
          height: '100vh',
          background: '#000000cc',
          width: '100vw',
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'relative' }}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#F5F5F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: isRTL ? '' : '40px',
                right: isRTL ? '40px' : '',
                top: '40px',
              }}
              onClick={handleClose}
            >
              <Icon src={closeIcon} />
            </Box>
            <img
              style={{ padding: '16px 0px', maxWidth: '100%', maxHeight: '100%' }}
              height="auto"
              width="auto"
              src={getImage().data_url}
              alt=""
            />
          </Box>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#F5F5F5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              right: '40px',
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              margin: 'auto',
              transform: isRTL ? 'rotate(90deg)' : 'rotate(-90deg)',
            }}
            onClick={handleNextImage}
          >
            <Icon src={chevron} />
          </Box>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#F5F5F5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              left: '40px',
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              margin: 'auto',
              transform: isRTL ? 'rotate(-90deg)' : 'rotate(90deg)',
            }}
            onClick={handlePreImage}
          >
            <Icon src={chevron} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
