import { Box, Stack, IconButton } from '@mui/material';
import { BaseBtn, Row } from 'shared/ui';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { close_popup } from 'shared/assets';
import { BigTitle, Modal, BaseInput, Loader, BaseSearch } from 'shared/ui';
import { Filter, ControllerRegistersTable } from 'features';
import { useControllersLiveData, useFilterRegisterType, useSearch } from './hooks';
import { Controller, ControllerRegisters, ControllerRegistersEntry } from 'shared/models';
import { LiveDataMessageObject } from '../../shared/hooks/useControllersLiveData';

interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
  currentController: Controller;
  controllerRegisters: ControllerRegisters;
  setControllerRegisters: (controllerRegisters: ControllerRegisters) => void;
}
interface ModalState {
  isOpenModal: boolean;
  closeModal: () => void;
}

export const EditRealTimeControllerRegisters: FC<ModalState> = ({
  isOpenModal,
  closeModal,
  currentController,
  controllerRegisters,
  setControllerRegisters,
}) => {
  const { registerTypeFilter, onChangeRegisterTypeFilter, registerTypeFilterItems } = useFilterRegisterType();
  //const { data, isConnected, connect,  } = useLiveControllerData();
  const onMessage = (latestMessage) => {
    if (latestMessage && latestMessage.message && controllerRegisters) {
      if (typeof latestMessage.message == 'string') {
        return;
      }
      const message: LiveDataMessageObject = latestMessage.message;

      const newDataArray = message.Data;
      const newControllerRegisters: Record<number, ControllerRegistersEntry[]> = { ...controllerRegisters };
      newDataArray.forEach((item) => {
        const eventID = item.Event;
        const value = item.iVal;
        console.log('eventID=', eventID, ' value=', value);
        Object.keys(newControllerRegisters).forEach((key) => {
          const entries = newControllerRegisters[key];

          entries.forEach((entry) => {
            // console.log(entry.eventID === eventID);
            if (entry.eventID === eventID) {
              entry.value = value;
            }
          });
        });
      });
      //console.log('!!!', newControllerRegisters);
      setControllerRegisters(newControllerRegisters);
    }
  };

  const { connectionStatus, errorMessage, disconnect, connect, isEventObjectMessage } = useControllersLiveData({
    autoDisconnect: false,
    consoleLog: false,
    onMessage: onMessage,
  });

  // if (!latestMessageData) return;
  // const newDataArray = latestMessageData.Data;

  //

  const connectLiveControllerData = () => {
    // console.log('currentController=', currentController.deviceId);
    //const token = 'staging-api-key';

    const token = process.env.REACT_APP_REDIS_KEY;
    const url = `/arts/redis-notifications`;
    const objectIds = [currentController.deviceId.toString()];
    connect(url, token, objectIds);
  };
  const disconnectLiveControllerData = () => {
    //disconnect(currentController.deviceId);
    disconnect();
  };
  const handleCloseEditModal = () => {
    clearSearch();
    closeModal();
    onChangeRegisterTypeFilter('');
  };
  const filteredRegisters = useMemo(() => {
    if (!controllerRegisters) return null;
    if (!registerTypeFilter) return [].concat(...Object.values(controllerRegisters));

    return [...(controllerRegisters[registerTypeFilter] || [])];
  }, [controllerRegisters, registerTypeFilter]);
  const {
    changeSearch,
    searchValue,
    clearSearch,
    onPreviousItemSearch,
    onNextItemSearch,
    numberOfResults,
    itemNumber,
    currentSearchID,
  } = useSearch(filteredRegisters);

  useEffect(() => {
    if (isOpenModal && currentController) {
      connectLiveControllerData();
    } else {
      disconnectLiveControllerData();
    }
  }, [isOpenModal]);

  useEffect(() => {
    return () => {
      disconnectLiveControllerData();
    };
  }, []);

  return (
    <Modal open={isOpenModal} onClose={handleCloseEditModal}>
      <Box>
        <Stack direction="row" sx={{ mb: '16px' }} justifyContent="space-between" alignItems="center">
          <BigTitle>{currentController?.objectName}</BigTitle>
          <IconButton onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
          <Box width={'25%'}>
            <Filter
              title={`${t('registers_type_filter')}`}
              value={registerTypeFilter}
              onChange={onChangeRegisterTypeFilter}
              items={registerTypeFilterItems}
              hideAllButton
              sx={{ width: '100%' }}
              messageEmptyFilter="registers_all_type_filter"
              placeholder={`${t('registers_type_clear_data_modal')}`}
            />
            <BaseInput
              label={`${t('hwId_registers_modal_label')}`}
              name="hwId"
              disabled
              helperText=""
              value={currentController?.hwId}
              small
            />
            <BaseInput
              label={`${t('device_id_registers_modal_label')}`}
              name="deviceId"
              disabled
              helperText=""
              value={currentController?.deviceId}
              small
            />
            <BaseInput
              label={`${t('customer_id_registers_modal_label')}`}
              name="customerId"
              disabled
              helperText=""
              value={currentController?.customerId}
              small
            />
            <BaseInput
              label={`${t('customer_name_registers_modal_label')}`}
              name="customerName"
              disabled
              helperText=""
              value={currentController?.customerName}
              small
            />
          </Box>
          <Box width={'75%'} height={'calc(100vh - 265px)'}>
            <Box sx={{ mb: '16px' }}>
              <BaseSearch
                numberOfResults={numberOfResults}
                itemNumber={itemNumber}
                onChange={changeSearch}
                value={searchValue}
                onClear={clearSearch}
                onPrevious={onPreviousItemSearch}
                onNext={onNextItemSearch}
              />
            </Box>
            {controllerRegisters ? (
              filteredRegisters && (
                <ControllerRegistersTable
                  registerTypeFilter={registerTypeFilter}
                  filteredRegisters={filteredRegisters}
                  searchValue={searchValue}
                  currentSearchID={currentSearchID}
                />
              )
            ) : (
              <Box height={'calc(100vh - 465px)'}>
                <Loader />
              </Box>
            )}
          </Box>
        </Row>
      </Box>
    </Modal>
  );
};
