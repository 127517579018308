import { TableTitle, TranslateColumn } from 'components/BaseTable';
import { Kvish6ControllersData } from '../Kvish6Models';

export const TABLE_TITLES: TableTitle<Kvish6ControllersData>[] = [
  { title: 'global_Status_RT', key: 'globalStatus_RT', width: '25%', minWidth: 30 },
  { title: 'controller_Name', key: 'controllerName', width: '175%', minWidth: 30 },
  { title: 'is_Generator_Present', key: 'isGeneratorPresent', width: '100%', minWidth: 30 },
  { title: 'is_Power_Connected_RT', key: 'isPowerConnected_RT', width: '50%', minWidth: 30 },
  { title: 'light_On_RT', key: 'isLightOn_RT', width: '25%', minWidth: 30 },
  { title: 'is_Door_Close_RT', key: 'isDoorClose_RT', width: '25%', minWidth: 30 },
  { title: 'toll_Gate', key: 'tollGate', width: '75%', minWidth: 30 },
  { title: 'junction', key: 'junction', width: '75%', minWidth: 30 },
  // { title: 'toll_Gate_1', key: 'tollGate1', width: '75%', minWidth: 30 },
  // { title: 'toll_Gate_2', key: 'tollGate2', width: '75%', minWidth: 30 },
  // { title: 'junction_1', key: 'junction1', width: '75%', minWidth: 30 },
  // { title: 'junction_2', key: 'junction2', width: '75%', minWidth: 30 },
  { title: 'TMS_Hub', key: 'tmS_Hub', width: '75%', minWidth: 30 },
  { title: 'current_Time_RT', key: 'currentTime_RT', width: '50%', minWidth: 30 },
  { title: 'is_Light_On_RT', key: 'lightOn_RT', width: '50%', minWidth: 30 },
  { title: 'Light_Off_RT', key: 'lightOff_RT', width: '50%', minWidth: 30 },
  { title: 'CCTV', key: 'cctv', width: '75%', minWidth: 30 },
  { title: 'Electric_Contract_Number', key: 'electric_ContractNumber', width: '75%', minWidth: 30 },
  { title: 'Controller_Note', key: 'controllerNote', width: '100%', minWidth: 30 },

  // { title: 'Controller_ID', key: 'controllerID', width: 60, minWidth: 30 },
  // { title: 'HWID_Ex', key: 'hwiD_EX', width: 60, minWidth: 30 },
  // { title: 'Model_ID', key: 'modelID', width: '75%', minWidth: 30 },

  // { title: 'Is_Light_Control_Present', key: 'isLightControlPresent', width: '75%', minWidth: 30 },

  // { title: 'Junction_3', key: 'junction3', width: '75%', minWidth: 30 },
  // { title: 'Sensor_1', key: 'sensor1', width: '75%', minWidth: 30 },
  // { title: 'Sensor_2', key: 'sensor2', width: '75%', minWidth: 30 },
  // { title: 'Signing', key: 'signing', width: '75%', minWidth: 30 },
  // { title: 'Bagatz_Maim', key: 'bagatZ_MAIM', width: '75%', minWidth: 30 },
  // { title: 'Electric_Customer_Name', key: 'electric_CustomerName', width: '75', minWidth: 30 },

  // { title: 'Electric_Counter', key: 'electric_Counter', width: '75%', minWidth: 30 },
  // { title: 'Traffic_Junction', key: 'trafficJunction', width: '75%', minWidth: 30 },
  // { title: 'Is_Controled', key: 'isControled', width: '100%', minWidth: 30 },
  // { title: 'Electric_Customer_Number', key: 'electric_CustomerNumber', width: '100%', minWidth: 30 },
  // { title: 'District_Name', key: 'districtName', width: '100%', minWidth: 30 },
  // { title: 'Electric_District_Name', key: 'electricDistrictName', width: '100%', minWidth: 30 },
  // { title: 'Settlement_Name', key: 'settlementName', width: '100%', minWidth: 30 },
];

export const TRANSLATE_COLUMNS: TranslateColumn<Kvish6ControllersData>[] = [
  // { key: 'isControled', additionalKey: '' },
  { key: 'isGeneratorPresent', additionalKey: '_generator_present' },
  // { key: 'isLightControlPresent', additionalKey: '' },
  { key: 'isPowerConnected_RT', additionalKey: '_power_connected' },

  //{ key: 'isLightOn_RT', additionalKey: '_light_on' },
];
