import { useTranslation } from 'react-i18next';
import { SiteMaterialsItems } from '../components/ExportToExcel';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();

  const formatTable = (arr: SiteMaterialsItems[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('siteName_material_table')]: item.siteName,
        [t('materialCode_material_table')]: item.materialCode,
        [t('unitType_material_table')]: item.unitType,
        [t('longDescription_material_table')]: item.longDescription,
        [t('quantity_material_table')]: item.quantity,
        [t('unitCost_material_table')]: item.unitCost,
        [t('materialCost_material_table')]: item.materialCost,
      };
    });

    return exportArr;
  };

  return { formatTable };
};
