import { MobileButton } from '../../ui';
import { Icon, Text } from 'shared/ui';
import { excelIcon } from '../../assets';
import { SiteMaterialsItems } from './ExportToExcel';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';

export interface SortedSiteData {
  siteName: string;
  data: SiteMaterialsItems[];
}

interface Props {
  sortedSitesData: SortedSiteData[];
}

export const ExportToPDF = ({ sortedSitesData }: Props) => {
  return (
    <>
      {sortedSitesData.map((item, index) => (
        <Box key={index}>
          <Box>{item.siteName}</Box>
          <Box>
            <Table
              sx={{
                border: '1px solid rgba(224, 224, 224, 1) ',
                tableLayout: 'fixed',
                width: '100%',
              }}
              data-title={item.siteName}
            >
              <TableHead>
                <TableRow sx={{ background: '#FAFAFA', maxWidth: '100%' }}>
                  <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '10%' }}>
                    <Text sx={{ color: 'secondary.dark' }}>{t('materialCode_material_table')}</Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '10%' }}>
                    <Text sx={{ color: 'secondary.dark' }}>{t('unitType_material_table')}</Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '50%' }}>
                    <Text sx={{ color: 'secondary.dark' }}>{t('longDescription_material_table')}</Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '10%' }}>
                    <Text sx={{ color: 'secondary.dark' }}>{t('quantity_material_table')}</Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '10%' }}>
                    <Text sx={{ color: 'secondary.dark' }}>{t('unitCost_material_table')}</Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '10%' }}>
                    <Text sx={{ color: 'secondary.dark' }}>{t('materialCost_material_table')}</Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.data.map((material, index) => (
                  <TableRow key={`key-${index}`} sx={{ background: index % 2 ? '#FAFAFA' : 'white', maxWidth: '100%' }}>
                    <TableCell
                      sx={{
                        height: '60px',
                        padding: '2px',
                        position: 'relative',
                        width: '10%',
                        paddingLeft: '8px',
                      }}
                    >
                      <Box>{material.materialCode}</Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '60px',
                        padding: '2px',
                        position: 'relative',
                        width: '10%',
                        paddingLeft: '8px',
                      }}
                    >
                      <Box>{material.unitType}</Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '60px',
                        padding: '2px',
                        position: 'relative',
                        width: '50%',
                        paddingLeft: '8px',
                      }}
                    >
                      <Box>{material.longDescription}</Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '60px',
                        padding: '2px',
                        position: 'relative',
                        width: '10%',
                        paddingLeft: '8px',
                      }}
                    >
                      <Box>{material.quantity}</Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '60px',
                        padding: '2px',
                        position: 'relative',
                        width: '10%',
                        paddingLeft: '8px',
                      }}
                    >
                      <Box>{material.unitCost}</Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        height: '60px',
                        padding: '2px',
                        position: 'relative',
                        width: '10%',
                        paddingLeft: '8px',
                      }}
                    >
                      <Box>{material.materialCost}</Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      ))}
    </>
  );
};
