import { Box, Divider } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useExtendedTranslation, useToast } from 'shared/hooks';
import { Icon, Loader, Row, Text } from '../../../../shared/ui';
import { AddMaterialsModal, ISSAutocomplete, ISSMap, MaterialsAndPricingTable, PhotoEdit } from '../Components';
import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import { QuantityAndPrice } from '../Components/MaterialsAndPricingTable';
import { lockActiveIcon, lockIcon, navigation, plusIcon } from '../assets';
import { useISSData, useISSPhotos, useMaterialListFormat, useSiteMaterials } from '../hooks';
import { MobileTextarea } from '../ui/MobileTextarea';
import { MobileButton, MobileInput, MobileSelect, MobileTextAndSelect } from './../ui';

import { compareMaterials } from './Helpers';
import { useUpdate } from './hooks';
import { PRIORITY } from './Constants/Index';
import Layout from '../Components/Layout';
export const ISSSiteManagement = () => {
  const { t } = useExtendedTranslation();
  const { getISSProjects, getISSSites, editSite, isLoading } = useISSData();
  const { getMaterialLists, getSiteMaterialLists, updateSiteMaterials, isLoadingSiteMaterials } = useSiteMaterials();
  const { isLoadingPhoto, getISSPhotos, getCompressedPhotos, updateISSPhotos } = useISSPhotos();

  const { ISSProjects, ISSSites, ISSPhoto, siteMaterials, materialLists } = useAppSelector((st) => st.ISSSlice);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [siteOldName, setSiteOldName] = useState<string>('');
  const [mapKey, setMapKey] = useState<string>('AIzaSyD2X1AoMDzxU-lgYxkcFQFH06kWfre_Z_4');
  const [localSiteMaterials, setLocalSiteMaterials] = useState<QuantityAndPrice[]>([]);
  const [newCoordinates, setNewCoordinates] = useState<{ lat: string; lng: string } | null>(null);
  const [addMaterialsOpenModal, setAddMaterialsOpenModal] = useState<boolean>(false);
  const [isActiveLock, setIsActiveLock] = useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const { showSuccess, showError } = useToast();
  const { getProjectMaterialListFormats } = useMaterialListFormat();
  const [loadedAutocomplete, setLoadedAutocomplete] = useState<boolean>(false);
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const [currentProjectMLFormat, setCurrentProjectMLFormat] = useState<number>();

  const {
    siteId,
    setSiteId,
    updateStatus,
    siteName,
    setSiteName,
    onSaveName,

    currentPriority,
    setCurrentPriority,
    onSavePriority,

    currentState,
    setCurrentState,
    onSaveCurrentState,

    coordinates,
    setCoordinates,
    onSaveCoordinates,

    currentDescription,
    setDescription,
    onSaveDescription,

    currentAdditionalWorks,
    setCurrentAdditionalWorks,
    onSaveAdditionalWorks,

    currentRequiredChanges,
    setCurrentRequiredChanges,
    onSaveRequiredChanges,
  } = useUpdate();

  const [localPhotos, setLocalPhotos] = useState<{ data_url: string }[]>([]);
  useEffect(() => {
    setTimeout(() => {
      setLoadedAutocomplete(true);
    }, 1500);
  }, []);

  const toggleLock = () => {
    setIsActiveLock(!isActiveLock);
  };

  const CURRENT_STATE: { value: string; label: string }[] = [
    {
      value: t('current_state_01_select_param'),
      label: 'current_state_01_select_param',
    },
    {
      value: t('current_state_02_select_param'),
      label: 'current_state_02_select_param',
    },
    {
      value: t('current_state_03_select_param'),
      label: 'current_state_03_select_param',
    },
    {
      value: t('current_state_04_select_param'),
      label: 'current_state_04_select_param',
    },
    {
      value: t('current_state_05_select_param'),
      label: 'current_state_05_select_param',
    },
  ];

  const setDefaultParam = async (siteId: string) => {
    const currentSite = ISSSites.find((el) => {
      return el.id === parseInt(siteId);
    });
    if (!currentSite) return;
    setSelectedProject(currentSite.projectID.toString());
    setSiteOldName(currentSite.siteName);
    setSiteName(currentSite.siteName);
    setCurrentState(currentSite.currentState);
    setDescription(currentSite.description);
    setCurrentRequiredChanges(currentSite.requiredChanges);
    setCurrentAdditionalWorks(currentSite.additionalWorks);
    setCurrentPriority(currentSite.priority);
    setCoordinates({ lat: currentSite.latitude, lng: currentSite.longitude });
    setNewCoordinates({ lat: currentSite.latitude.toString(), lng: currentSite.longitude.toString() });
  };

  const initialData = async (siteId: string) => {
    setSiteId(siteId);
    getISSSites();
    setDefaultParam(siteId);
    getSiteMaterialLists(siteId);
    const projectMLFormats = await getProjectMaterialListFormats();
    const projectId = searchParams.get('projectId');
    const currentProjectMLFormat = projectMLFormats.data.find((item) => item.projectID.toString() == projectId);
    if (currentProjectMLFormat) {
      setCurrentProjectMLFormat(currentProjectMLFormat.materialListFormatID);
    }
    //await getISSPhotos(siteId);
    await getCompressedPhotos([parseInt(siteId)]);
    setLocalPhotos(ISSPhoto.map((item) => ({ data_url: item.compressedPhotoData })));
  };
  useEffect(() => {
    getISSProjects();
    getMaterialLists();
    const siteId = searchParams.get('siteId');
    const projectId = searchParams.get('projectId');

    setSelectedProject(projectId);
    if (siteId) {
      initialData(siteId);
    }
  }, []);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (materialLists.length && siteMaterials.length) {
      const siteMaterialsItems = siteMaterials.map((s) => {
        const currentMaterial = materialLists.find((item) => item.id == s.materialID);

        return {
          quantity: s.quantity,
          materialCode: currentMaterial.materialCode,
          id: currentMaterial.id,
          unitType: currentMaterial.unitType,
          shortDescription: currentMaterial.shortDescription,
          unitCost: currentMaterial.unitCost,
        };
      });
      setLocalSiteMaterials(siteMaterialsItems);
    } else {
      setLocalSiteMaterials([]);
    }
  }, [siteMaterials, materialLists]);

  useEffect(() => {
    const siteId = searchParams.get('siteId');
    setDefaultParam(siteId);
  }, [ISSSites]);

  const onChangeName = (value: string) => {
    setSiteName(value);
  };
  const onChangeCurrentState = (value: string) => {
    setCurrentState(value);
    onSaveCurrentState(value);
  };
  const onChangeDescription = (value: string) => {
    setDescription(value);
  };
  const onChangeRequiredChanges = (value: string) => {
    setCurrentRequiredChanges(value);
  };
  const onChangeAdditionalWorks = (value: string) => {
    setCurrentAdditionalWorks(value);
  };
  const onChangeLatitude = (value: string) => {
    setNewCoordinates({ lat: value, lng: newCoordinates ? newCoordinates.lng : '0' });
  };

  const onChangeLongitude = (value: string) => {
    setNewCoordinates({ lat: newCoordinates ? newCoordinates.lat : '0', lng: value });
  };
  const setValidLatitude = () => {
    const filteredValue = newCoordinates.lat.replace(/[^0-9.,-]/g, '');
    const formattedValue = filteredValue.replace(',', '.');
    const lat = parseFloat(formattedValue);
    if (!isNaN(lat)) {
      setCoordinates({ lat, lng: coordinates ? coordinates.lng : 0 });
      onSaveCoordinates({ lat, lng: coordinates ? coordinates.lng : 0 });
      setNewCoordinates({ lat: lat.toString(), lng: newCoordinates ? newCoordinates.lng : '0' });
    }
  };
  const setValidLongitude = () => {
    const filteredValue = newCoordinates.lng.replace(/[^0-9.,-]/g, '');
    const formattedValue = filteredValue.replace(',', '.');
    const lng = parseFloat(formattedValue);
    if (!isNaN(lng)) {
      setCoordinates({ lat: coordinates ? coordinates.lat : 0, lng });
      onSaveCoordinates({ lat: coordinates ? coordinates.lat : 0, lng });
      setNewCoordinates({ lat: newCoordinates ? newCoordinates.lat : '0', lng: lng.toString() });
    }
  };

  const onChangePriority = (value: number) => {
    if (value < 1 || value > 10) return;
    setCurrentPriority(value);
    onSavePriority(value.toString());
  };

  const changeImages = (photoList: { data_url: string }[]) => {
    setLocalPhotos(photoList);
  };

  const validationCheckName = (name: string) => {
    if (name.length < 1 || name.length > 128) {
      return false;
    }
    return true;
  };
  const validation = () => {
    if (!validationCheckName(siteName)) {
      return false;
    } else {
      return true;
    }
  };

  const saveSiteMaterials = async (localSiteMaterials: QuantityAndPrice[]) => {
    const newItems = compareMaterials(siteMaterials, localSiteMaterials, siteId);
    if (newItems.length) {
      const result = await updateSiteMaterials(newItems);
      if (!result.data.result) {
        showError(t('site_materials_update_failed_toast_text'));
      }
      await getSiteMaterialLists(siteId);
      return result.data.result;
    }
    return true;
  };

  const onChangeCoordinates = (position: { lat: number; lng: number }) => {
    setCoordinates(position);
    if (position && coordinates && position.lat != coordinates.lat && position.lng != coordinates.lng) {
      onSaveCoordinates(position);
    }
    setNewCoordinates({ lat: position.lat.toString(), lng: position.lng.toString() });
  };

  const getCoordinates = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCoordinates({ lat: latitude, lng: longitude });
          onSaveCoordinates({ lat: latitude, lng: longitude });
          setNewCoordinates({ lat: latitude.toString(), lng: longitude.toString() });
        },
        (error) => {
          console.error('Error getting location:', error.message);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error('Geolocation API notSupport.');
    }
  };
  const onChangeSiteMaterials = (data: QuantityAndPrice[]) => {
    saveSiteMaterials(data);
    setLocalSiteMaterials(data);
  };

  const photoList = useMemo(
    () =>
      ISSPhoto.map((s) => {
        return {
          data_url: `data:image/jpeg;base64,${s.compressedPhotoData}`,
          id: s.id,
        };
      }),
    [ISSPhoto]
  );

  const addAllMaterials = () => {
    if (isLoadingSiteMaterials) return;
    const newList: QuantityAndPrice[] = [...localSiteMaterials];

    currentProjectMLFormat;
    const filteredItems = materialLists.filter((item) => item.materialListFormatID == currentProjectMLFormat);

    filteredItems.forEach((item) => {
      const material = newList.find((el) => el.id === item.id);
      if (material) return;
      newList.push({
        id: item.id,
        quantity: 0,
        unitCost: item.unitCost,
        materialCode: item.materialCode,
        shortDescription: item.shortDescription,
        unitType: item.unitType,
      });
    });
    // QuantityAndPrice;
    // dispatch(setSiteMaterials(newList));
    saveSiteMaterials(newList);
    setLocalSiteMaterials(newList);
  };

  const openAddMaterialsModal = () => {
    if (isLoadingSiteMaterials) return;
    setAddMaterialsOpenModal(true);
  };
  const handleCloseMaterialsModal = () => {
    setAddMaterialsOpenModal(false);
  };
  const handleSaveMaterials = (newList: QuantityAndPrice[]) => {
    saveSiteMaterials(newList);
    setLocalSiteMaterials(newList);
  };

  const createMapLink = (latitude: string, longitude: string): string => {
    // Get user agent string for device detection
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    // Variable to store the resulting map URL
    let mapUrl: string;
    // Check if device is Android
    if (/android/i.test(userAgent)) {
      // Return geo URI scheme for Android devices
      mapUrl = `geo:${latitude},${longitude}`;
    }
    // Check if device is iOS (iPhone, iPad, or iPod)
    else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      // Return Apple Maps URL for iOS devices
      // mapUrl = `https://maps.apple.com/?ll=${latitude},${longitude}&navigate=yes`;
      mapUrl = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
    }
    // Fallback for all other devices (desktop, etc.)
    else {
      // Return Google Maps URL as default
      mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&navigate=yes`;
    }
    // Return the generated URL
    return mapUrl;
  };

  return (
    <Box>
      <Layout>
        <Header
          subTitle={siteOldName ? t('site_name_title') : ''}
          title={siteOldName ? siteOldName : t('new_site_edit_site')}
        />

        <Box sx={{ padding: '0 24px' }}>
          {isLoading && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
                zIndex: '30',
                position: 'fixed',
                top: '0px',
                opacity: '0.7',
                left: '0',
              }}
            >
              <Loader />
            </Box>
          )}
          <Box>
            <MobileInput
              label={`${t('site_name_edit_site')} *`}
              name="siteName"
              error={updateStatus.siteName.error}
              helperText={''}
              compact
              blur={onSaveName}
              onChange={(value) => onChangeName(value.target.value)}
              value={siteName}
              disabled={updateStatus.siteName.updating}
              updated={updateStatus.siteName.updated}
            />
          </Box>
          <Row spacing={'20px'} sx={{ marginTop: '20px' }}>
            <Box width="calc(30% - 10px)" minWidth="calc(30% - 10px)" maxWidth={'calc(30% - 10px)'}>
              <MobileSelect
                title={t('priority_edit_site')}
                items={PRIORITY}
                error={updateStatus.priority.error}
                noSelectLabel={t('priority_placeholder_edit_site')}
                onChange={(value) => onChangePriority(parseInt(value))}
                value={currentPriority.toString()}
                disabled={updateStatus.priority.updating}
                updated={updateStatus.priority.updated}
              />
            </Box>
            <Box width="calc(70% - 10px)" minWidth="calc(70% - 10px)" maxWidth={'calc(70% - 10px)'}>
              {/* <MobileTextAndSelect
              title={t('current_state_edit_site')}
              items={CURRENT_STATE}
              noSelectLabel={t('current_state_placeholder_edit_site')}
              onChange={(value) => onChangeCurrentState(value)}
              onBlur={onSaveCurrentState}
              onSelect={onSaveCurrentState}
              value={currentState}
              error={updateStatus.currentState.error}
              disabled={updateStatus.currentState.updating}
              updated={updateStatus.currentState.updated}
              translate
            /> */}
              <MobileSelect
                title={t('current_state_edit_site')}
                items={CURRENT_STATE}
                error={updateStatus.currentState.error}
                noSelectLabel={t('current_state_placeholder_edit_site')}
                onChange={(value) => onChangeCurrentState(value)}
                value={currentState}
                disabled={updateStatus.currentState.updating}
                updated={updateStatus.currentState.updated}
                translate
              />
            </Box>
          </Row>
          {mapKey && (
            <Row alignItems={'flex-end'}>
              <Box width={'100%'}>
                <ISSAutocomplete
                  getCoordinates={getCoordinates}
                  mapKey={mapKey}
                  onChangeCoordinates={onChangeCoordinates}
                />
              </Box>

              <Icon onClick={toggleLock} sx={{ mx: '12px' }} src={isActiveLock ? lockActiveIcon : lockIcon} />
            </Row>
          )}
          {mapKey && loadedAutocomplete && coordinates && (
            <Box
              ref={mapContainerRef}
              sx={{ top: 0, left: 0, height: '345px', position: isFullscreen ? 'fixed' : 'relative', width: '100%' }}
            >
              <ISSMap
                mapKey={mapKey}
                disabled={updateStatus.coordinates.updating}
                value={coordinates}
                onChangeCoordinates={onChangeCoordinates}
                isActiveLock={isActiveLock}
                isFullscreen={isFullscreen}
              />
            </Box>
          )}
          {newCoordinates && (
            <Box sx={{ display: 'flex', width: '100%' }} alignItems={'flex-end'}>
              <Box width="50%">
                <MobileInput
                  label={`${t('latitude_edit_site')}`}
                  name="latitude"
                  helperText={''}
                  onChange={(value) => onChangeLatitude(value.target.value)}
                  blur={setValidLatitude}
                  value={newCoordinates.lat}
                  updated={updateStatus.coordinates.updated}
                  disabled={updateStatus.coordinates.updating}
                  error={updateStatus.coordinates.error}
                />
              </Box>
              <Row sx={{ width: '20px' }} />
              <Box width="50%">
                <MobileInput
                  label={`${t('longitude_edit_site')}`}
                  name="longitude"
                  helperText={''}
                  onChange={(value) => onChangeLongitude(value.target.value)}
                  blur={setValidLongitude}
                  value={newCoordinates.lng}
                  updated={updateStatus.coordinates.updated}
                  disabled={updateStatus.coordinates.updating}
                  error={updateStatus.coordinates.error}
                />
              </Box>
              <a style={{ display: 'flex' }} href={`${createMapLink(newCoordinates.lat, newCoordinates.lng)}`}>
                <Icon sx={{ mx: '12px' }} src={navigation} />
              </a>
            </Box>
          )}

          <MobileInput
            label={`${t('required_changes_edit_site')}`}
            name="requiredChanges"
            error={updateStatus.requiredChanges.error}
            helperText={''}
            onChange={(value) => onChangeRequiredChanges(value.target.value)}
            value={currentRequiredChanges}
            autoHeight
            blur={onSaveRequiredChanges}
            disabled={updateStatus.requiredChanges.updating}
            updated={updateStatus.requiredChanges.updated}
          />
          {/* <MobileInput
          label={`${t('additional_works_edit_site')}`}
          name="additionalWorks"
          error={updateStatus.additionalWorks.error}
          helperText={''}
          onChange={(value) => onChangeAdditionalWorks(value.target.value)}
          value={currentAdditionalWorks}
          autoHeight
          blur={onSaveAdditionalWorks}
          disabled={updateStatus.additionalWorks.updating}
          updated={updateStatus.additionalWorks.updated}
        /> */}
          <MobileTextarea
            label={`${t('description_edit_site')}`}
            name="description"
            error={updateStatus.description.error}
            helperText={''}
            onChange={(value) => onChangeDescription(value.target.value)}
            value={currentDescription}
            blur={onSaveDescription}
            disabled={updateStatus.description.updating}
            updated={updateStatus.description.updated}
          />
          <Divider sx={{ mt: '18px' }} />
          <MaterialsAndPricingTable
            onChangeSiteMaterials={onChangeSiteMaterials}
            localSiteMaterials={localSiteMaterials}
            loading={isLoadingSiteMaterials}
          />
          <Row sx={{ opacity: isLoadingSiteMaterials ? 0.5 : 1 }}>
            <MobileButton btnColor="info.main" onClick={openAddMaterialsModal}>
              <Icon sx={{ mx: '6px' }} src={plusIcon} />
              <Text sx={{ color: 'black' }}>{`${t('add_item_button_edit_site')}`}</Text>
            </MobileButton>
            <Box sx={{ width: '40px' }}></Box>
            <MobileButton btnColor="info.main" onClick={addAllMaterials}>
              <Row sx={{ mx: '6px' }}>
                <Icon sx={{ mx: '2px' }} src={plusIcon} />
                <Icon src={plusIcon} />
              </Row>
              <Text sx={{ color: 'black' }}>{`${t('add_all_button_edit_site')}`}</Text>
            </MobileButton>
          </Row>
          <Divider sx={{ mt: '18px' }} />

          <Box sx={{ position: 'relative' }}>
            {isLoadingPhoto && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  background: '#ffffff',
                  zIndex: '30',
                  position: 'absolute',
                  top: '0px',
                  opacity: '0.9',
                  left: '0',
                }}
              >
                <Loader />
              </Box>
            )}

            <PhotoEdit photoList={photoList} siteId={siteId} changeImages={changeImages} />
          </Box>

          <Footer />
        </Box>
        {addMaterialsOpenModal && (
          <AddMaterialsModal
            closeMaterials={handleCloseMaterialsModal}
            saveMaterials={handleSaveMaterials}
            localSiteMaterials={localSiteMaterials}
            currentProjectMLFormat={currentProjectMLFormat}
          />
        )}
      </Layout>
    </Box>
  );
};
