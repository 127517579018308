import Split from '@uiw/react-split';
import { Box } from '@mui/material';
import localStorageService from 'shared/localStorage';
import { useEffect, useState } from 'react';
import { UseKvish6Data } from './hooks';
import { BaseTable } from 'components';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { Kvish6ControllersData } from './Kvish6Models';
import { t } from 'i18next';
import { BaseBtn, Loader, Row } from 'shared/ui';
import { LiveDataMessageObject, useControllersLiveData } from 'shared/hooks/useControllersLiveData';
import { IsOpenDoor } from './components/IsOpenDoor';
import { IsLightOn } from './components/IsLightOn';
import { StatusIcon } from './components/StatusIcon';
export const Kvish6Manager = () => {
  const [splitWidth, setSplitWidth] = useState('15%');
  const [controllersData, setControllersData] = useState<Kvish6ControllersData[]>([]);
  const { getKvish6Data, getKvish6RealTimeData, isLoading } = UseKvish6Data();
  const [splitKey, setSplitKey] = useState(0);

  const formatTableData = (controllersData: Kvish6ControllersData[]) => {
    return {
      data: controllersData.map((controller) => ({
        ...controller,
        tollGate: [controller.tollGate1, controller.tollGate2].filter(Boolean).join(', '),
        junction: [controller.junction1, controller.junction2, controller.junction3].filter(Boolean).join(', '),
      })),
    };
  };
  const fetchData = async () => {
    let controllersData = await getKvish6Data();
    controllersData = formatTableData(controllersData.data);
    const dataForRedis = controllersData.data.map((item) => ({
      deviceId: item.controllerID,
      modelId: item.modelID,
    }));
    const controllersRealTimeData = await getKvish6RealTimeData(dataForRedis);

    const liveControllerData: Kvish6ControllersData[] = controllersData.data.map((controller) => {
      const realTimeEntry = controllersRealTimeData.data.find((rt) => rt.deviceId === controller.controllerID);
      const realTimeData = realTimeEntry && realTimeEntry.state ? realTimeEntry.state : null;
      return {
        ...controller,
        currentTime_RT: realTimeData?.curretnTime ?? '',
        isDoorClose_RT: realTimeData?.door ?? '',
        lightOff_RT: realTimeData?.lightOffTime ?? '',
        lightOn_RT: realTimeData?.lightOnTime ?? '',
        isLightOn_RT: realTimeData?.lightStatus ?? '',
        isPowerConnected_RT: realTimeData?.powerStatus ?? null,
        globalStatus_RT: realTimeData?.calculated?.state ?? null,
      };
    });

    setControllersData(liveControllerData);
    connectLiveControllerData(liveControllerData);
  };

  useEffect(() => {
    const startWidth = localStorageService.kvish6SplitPosition;
    if (startWidth) setSplitWidth(`${startWidth}%`);

    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const onDragEnd = (width) => {
    let saveWidth = width;
    if (saveWidth > 95) {
      saveWidth = 95;
    }
    if (saveWidth < 5) {
      saveWidth = 5;
    }
    localStorageService.kvish6SplitPosition = saveWidth;
  };
  const resetSettingsButton = () => {
    localStorageService.kvish6SplitPosition = '';
    localStorageService.baseTableSettings = { tablePrefix: '_kvish6_grid_table' };
    setSplitWidth(`15%`);
    setSplitKey((prev) => prev + 1);
  };
  const refreshSettingsButton = () => {
    fetchData();
  };
  const onMessage = (latestMessage) => {
    if (latestMessage && latestMessage.message && controllersData) {
      if (typeof latestMessage.message == 'string') {
        return;
      }
      const message: LiveDataMessageObject = latestMessage.message;

      const newDataArray = message.Data;
      //const newControllerRegisters: Record<number, ControllerRegistersEntry[]> = { ...controllerRegisters };
      newDataArray.forEach((item) => {
        const eventID = item.Event;
        const value = item.iVal;
        //console.log('eventID=', eventID, ' value=', value);
        // Object.keys(newControllerRegisters).forEach((key) => {
        //   const entries = newControllerRegisters[key];

        //   entries.forEach((entry) => {
        //     // console.log(entry.eventID === eventID);
        //     if (entry.eventID === eventID) {
        //       entry.value = value;
        //     }
        //   });
        // });
      });
      //console.log('!!!', newControllerRegisters);
      //setControllerRegisters(newControllerRegisters);
    }
  };

  const { connectionStatus, errorMessage, disconnect, connect, isEventObjectMessage } = useControllersLiveData({
    autoDisconnect: false,
    consoleLog: false,
    onMessage: onMessage,
  });

  // console.log('currentController=', currentController.deviceId);
  const connectLiveControllerData = (data: Kvish6ControllersData[]) => {
    //const token = 'staging-api-key';

    const token = process.env.REACT_APP_REDIS_KEY;
    const url = `/arts/redis-notifications`;
    const objectIds = data.map((item) => item.controllerID.toString());
    //connect(url, token, objectIds);
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: '#ffffff',
            zIndex: '30',
            position: 'fixed',
            top: '0px',
            opacity: '0.7',
            left: '0',
          }}
        >
          <Loader />
        </Box>
      )}
      <Box width="100%">
        <Split key={splitKey} style={{ direction: 'ltr' }} onDragEnd={onDragEnd}>
          <Box
            sx={{
              minWidth: '100px',
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              width: splitWidth,
              position: 'relative',
              background: '#ccf',
              padding: '4px',
            }}
          >
            <Row sx={{ mt: '16px' }}>
              <BaseBtn onClick={resetSettingsButton}> {t('reset_button_kvish6_settings')}</BaseBtn>
            </Row>
            <Row sx={{ mt: '16px' }}>
              <BaseBtn onClick={refreshSettingsButton}> {t('refresh_button_kvish6_settings')}</BaseBtn>
            </Row>
          </Box>
          <Box
            sx={{
              minWidth: '150px',
              overflowY: 'auto',
              flex: 1,
              position: 'relative',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <BaseTable
                tableTitles={TABLE_TITLES}
                tableItems={controllersData}
                loadedItems={true}
                idField={'controllerID'}
                noItemsText={t('no_controllers_controllers_grid_table')}
                headerItemsPrefix={'_kvish6_grid_table'}
                translateColumns={TRANSLATE_COLUMNS}
                localStorageSyncWidth
                fontSize="12px"
                rowHeight={28}
                maxHeight="calc(100vh - 120px)"
                height="calc(100vh - 120px)"
                defaultScrollWidth={24}
                additionalContent={[
                  { index: 0, hideContent: true, content: (props) => <StatusIcon {...props} /> },
                  { index: 4, hideContent: true, content: (props) => <IsLightOn {...props} /> },
                  { index: 5, hideContent: true, content: (props) => <IsOpenDoor {...props} /> },
                ]}
                alignCenterColumnIndexes={[0, 4, 5, 9, 10, 11]}
                // dateColumnIndexes={[11]}
                // linkIndex={[1]}
                // cellClick={handleOpenControllerEditModal}
                // actions={actions}
                // sortModel={[{ field: 'objectName', sort: 'asc' }]}
                // checkboxSelection
                // onRowSelectionModelChange={rowSelectionModelChange}
                // hideSelectAll={isNaN(parseInt(customerFilter)) || customerFilter === '87'}
                loading={isLoading}
              />
            </Box>
          </Box>
        </Split>
      </Box>
    </>
  );
};
