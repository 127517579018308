import { Box } from '@mui/material';
import { ISSProject } from 'features/Mobile/ISS/ISSModels';
import { siteSmall } from 'features/Mobile/ISS/assets';
import { t } from 'i18next';
import { FC, useMemo } from 'react';
import { useAppSelector } from 'shared/hooks';
import { getFormattedDate } from 'shared/lib';
import { Icon, Row, Text } from 'shared/ui';
interface Props {
  project: ISSProject;
  handleClick: () => void;
}

export const ProjectCard: FC<Props> = ({ project, handleClick }) => {
  return (
    <Box
      sx={{
        mt: '8px',
        padding: '8px',
        border: '1px, solid',
        borderColor: '#707070',
        boxShadow: '0px 1px 3px 0px #0000001A',
        background: 'white',
        borderRadius: '8px',
      }}
      onClick={handleClick}
    >
      <Box>
        <Text sx={{ color: 'black', fontWeight: 'bold' }}>{project.projectName}</Text>
      </Box>
      <Box>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Row spacing={'4px'}>
            <Icon sx={{ mb: '2px' }} src={siteSmall} />
            <Text sx={{ color: 'black' }}>{project.siteCount}</Text>
            <Text sx={{ color: 'black' }}>{t('site_count_projects_page')}</Text>
          </Row>
          <Row spacing={'4px'}>
            <Text sx={{ color: 'black' }}>{t('created_date_projects_page')}</Text>
            <Text sx={{ color: 'black' }}>{getFormattedDate(project.creationDate)}</Text>
          </Row>
        </Row>
        <Box height="36px" sx={{ display: 'block' }}></Box>
      </Box>
    </Box>
  );
};
