import { Box } from '@mui/material';
import { Icon, Row, Text } from '../../../../shared/ui';
import { useAuth, useExtendedTranslation } from 'shared/hooks';
import { LoginForm } from '../Components/';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';
import { Background, MobileButton, MobileInput, MobileSelect } from '../ui';
import localStorageService from 'shared/localStorage';
import { LANG } from './Constants';
import { useNavigate } from 'react-router-dom';
import { logoutIcon } from '../assets';
import Layout from '../Components/Layout';
export const ISSSettings = () => {
  const { t } = useExtendedTranslation();
  const name = localStorageService.currentUserName ?? '';
  const lang = localStorageService.currentLang ?? 'he-iss';
  const { changeLanguage } = useExtendedTranslation();
  const push = useNavigate();
  const setCurrentValue = (lng) => {
    changeLanguage(lng);
  };
  const logout = () => {
    localStorageService.userToken = undefined;
    localStorageService.userMobileToken = undefined;
    push('/mobile/iss/login');
  };
  return (
    <Box>
      <Layout>
        <Header title={t('title_settings_page')} />
        <Box sx={{ padding: '0 24px' }}>
          <MobileInput
            label={t('user_name_settings_page')}
            name={'user_name'}
            error={false}
            helperText={''}
            value={name}
            disabled
            compact
          ></MobileInput>
          <MobileSelect
            title={t('language_selection_settings_page')}
            items={LANG}
            onChange={(value) => setCurrentValue(value)}
            value={lang}
          />
          <MobileButton btnColor={'#DC3545'} onClick={logout}>
            <Icon src={logoutIcon} sx={{ mx: '4px' }} />
            <Text>{t('logout_settings_page')}</Text>
          </MobileButton>
        </Box>
        <Background />
        <Footer />
      </Layout>
    </Box>
  );
};
