import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useSwipeable } from 'react-swipeable';

interface SwipeComponentProps extends PropsWithChildren {
  swiped: () => void;
}

export const SwipeComponent: FC<SwipeComponentProps> = ({ swiped, children }) => {
  const handlers = useSwipeable({
    onSwipedRight: swiped,
    onSwipedLeft: swiped,
  });

  return <Box {...handlers}>{children}</Box>;
};

export default SwipeComponent;
