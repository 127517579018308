import { useAppDispatch } from 'shared/hooks';
import { setCustomers } from 'shared/slices';

import { Customer } from 'shared/models';
import useAxios from 'shared/api/useAxios';

export const useCustomerPhoto = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();

  return {
    getCustomerPhoto: (customerId:number) =>
      request<undefined, { data: {image:string} }>({
        method: 'get',
        url: `/customer/photo?customerId=${customerId}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          //dispatch(setCustomers(data.data));
        },
      }),
    updateCustomerPhoto: (customerId:number, photoData: string) =>
      request<undefined, { data: {result:boolean} }>({
        method: 'put',
        url: `/customer/photo`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          //dispatch(setCustomers(data.data));
        },
        payload: {
          customerId: customerId,
          photoData: photoData 

        },
      }),



    isLoadingCustomerPhoto: loading,
  };
};
