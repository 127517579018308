import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { useISSData, useSiteMaterials } from '../hooks';
import { Header } from '../Components/Header';
import { t } from 'i18next';
import { ProjectInfoCard } from './components/ProjectInfoCard';
import { MobileButton } from '../ui';
import { Icon, Loader, Row, Text } from 'shared/ui';
import { pdfIcon } from '../assets';
import { ExportToExcel } from './components';
import { SiteMaterialsItems } from './components/ExportToExcel';
import { notosanshebrew } from 'features/ControllerManager/components/EditController/components/notosanshebrewFont';
import { ExportToPDF, SortedSiteData } from './components/ExportToPDF';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const ISSExport = () => {
  const [searchParams] = useSearchParams();
  const { ISSSites, ISSProjects, materialLists } = useAppSelector((st) => st.ISSSlice);
  const { getISSProjects, getISSSites } = useISSData();
  const { getMaterialLists, getSiteMaterialLists } = useSiteMaterials();
  const [allSitesData, setAllSitesData] = useState<SiteMaterialsItems[]>([]);
  const [sortedSitesData, setSortedSitesData] = useState<SortedSiteData[]>([]);
  const [dataISGenerated, setDataISGenerated] = useState(false);

  const realTimeDataRef = useRef<HTMLDivElement>(null);

  const initialData = async () => {
    await getISSSites();
    await getISSProjects();
    await getMaterialLists();
  };

  useEffect(() => {
    initialData();
  }, []);

  const generateDataForReport = async () => {
    const allSitesData = [];
    const sortedSitesData: SortedSiteData[] = [];
    for (const item of ISSSitesFiltered) {
      const result = await getSiteMaterialLists(item.id.toString());
      if (!result.data) continue;
      const siteMaterials = result.data;
      const siteMaterialsItems = siteMaterials.map((s) => {
        const currentMaterial = materialLists.find((item) => item.id == s.materialID);
        const materialCost = currentMaterial.unitCost * s.quantity;
        return {
          quantity: s.quantity,
          materialCode: currentMaterial.materialCode,
          unitType: currentMaterial.unitType,
          longDescription: currentMaterial.longDescription,
          unitCost: currentMaterial.unitCost,
          materialCost: materialCost,
          siteName: item.siteName,
        };
      });
      sortedSitesData.push({ data: siteMaterialsItems, siteName: item.siteName });
      allSitesData.push(...siteMaterialsItems);
    }
    setAllSitesData(allSitesData);
    setSortedSitesData(sortedSitesData);
    setDataISGenerated(true);
  };

  const currentProject = useMemo(() => {
    const projectId = searchParams.get('projectId');
    return ISSProjects.find((item) => item.id === parseInt(projectId));
  }, [ISSProjects]);

  const ISSSitesFiltered = useMemo(() => {
    const projectID = searchParams.get('projectId');
    if (!projectID) return ISSSites;
    return ISSSites.filter((item) => item.projectID.toString() === projectID);
  }, [ISSSites, currentProject]);

  const dataLoaded = useMemo(() => {
    return currentProject && !!ISSSitesFiltered.length && !!materialLists.length;
  }, [ISSSitesFiltered, materialLists, currentProject]);

  useEffect(() => {
    if (dataLoaded) {
      generateDataForReport();
    }
  }, [dataLoaded]);

  const generatePDF = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const doc = new jsPDF();

      doc.addFileToVFS('NotoSansHebrew.ttf', notosanshebrew);
      doc.addFont('NotoSansHebrew.ttf', 'NotoSansHebrew', 'normal');
      doc.setFont('NotoSansHebrew');

      const tables = ref.current.querySelectorAll('table');

      tables.forEach((table, index) => {
        if (index > 0) doc.addPage();

        const tableTitle = table.getAttribute('data-title') || `Table ${index + 1}`;
        doc.setFontSize(14);
        doc.text(tableTitle, 10, 10);

        autoTable(doc, { html: table, styles: { font: 'NotoSansHebrew' } });
      });

      doc.save('export.pdf');
    } else {
      console.error('Table not found');
    }
  };
  // useEffect(() => {
  //   if (materialLists.length && ISSSitesFiltered.length && currentProject) {
  //     generateDataForReport();
  //   }
  // }, [materialLists, ISSSitesFiltered, currentProject]);
  return (
    <Box>
      <Header title={currentProject ? currentProject.projectName : t('irrigation_system_survey')} />
      <Box sx={{ padding: '0 24px' }}>
        <Box>
          <Table
            sx={{
              border: '1px solid rgba(224, 224, 224, 1) ',
              tableLayout: 'fixed',
              width: '100%',
            }}
          >
            <TableHead>
              <TableRow sx={{ background: '#FAFAFA', maxWidth: '100%' }}>
                <TableCell sx={{ height: '60px', padding: '2px', paddingLeft: '8px', width: '40%' }}>
                  <Text sx={{ color: 'secondary.dark' }}>{t('siteName_material_table')}</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ISSSitesFiltered.map((site, index) => (
                <TableRow key={`key-${site.id}`} sx={{ background: index % 2 ? '#FAFAFA' : 'white', maxWidth: '100%' }}>
                  <TableCell
                    sx={{ height: '60px', padding: '2px', position: 'relative', width: '40%', paddingLeft: '8px' }}
                  >
                    <Box key={site.id}>{site.siteName}</Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ position: 'relative' }}>
            {!dataISGenerated && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  background: '#ffffff',
                  zIndex: '30',
                  position: 'absolute',
                  top: '0px',
                  opacity: '0.9',
                  left: '0',
                }}
              >
                <Loader />
              </Box>
            )}
            <Row spacing={'20px'}>
              <ExportToExcel
                tableData={allSitesData}
                sheetName={'Report'}
                fileName={'ReportISS'}
                buttonText={`${t('export_excel_export_page')}`}
                headerItemsPrefix={'_material_table'}
                loading={!dataISGenerated}
              />
              <MobileButton
                loading={!dataISGenerated}
                onClick={() => generatePDF(realTimeDataRef)}
                sx={{ mb: '16px' }}
                btnColor="#1C5B9C"
              >
                <Icon src={pdfIcon} sx={{ mx: '4px' }} />
                <Text>{`${t('export_pdf_export_page')}`}</Text>
              </MobileButton>
            </Row>
          </Box>

          <Box ref={realTimeDataRef} sx={{ height: '0px', overflow: 'hidden' }}>
            <ExportToPDF sortedSitesData={sortedSitesData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
