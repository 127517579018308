import { WAMessageStatus } from 'features/WAMessageStatusManager/WAMessageStatusModels';
import { useTranslation } from 'react-i18next';
import { Kvish6ControllersData } from '../Kvish6Models';
import { Icon } from 'shared/ui';
import {
  lamp_blue,
  lamp_dark_yellow,
  lamp_gray,
  lamp_green,
  lamp_lime,
  lamp_orange,
  lamp_red,
  lamp_yellow,
} from 'shared/assets';
interface Props {
  row?: Kvish6ControllersData;
}

const getStatusIcon = (status: number) => {
  switch (status) {
    case 1:
      return <Icon src={lamp_green} />;
    case 2:
      return <Icon src={lamp_blue} />;
    case 3:
      return <Icon src={lamp_orange} />;
    case 4:
      return <Icon src={lamp_red} />;
    case 5:
      return <Icon src={lamp_dark_yellow} />;
    case 6:
      return <Icon src={lamp_yellow} />;
    case 7:
      return <Icon src={lamp_lime} />;
  }
  return <Icon src={lamp_gray} />;
};
export const StatusIcon = ({ row }: Props) => {
  return <>{getStatusIcon(row.globalStatus_RT)}</>;
};
