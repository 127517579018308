import { Box, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';

import { usePlacesWidget } from 'react-google-autocomplete';
import { MobileInput } from '../../ui';
import { t } from 'i18next';
import { position } from '../../assets';

interface Props {
  onChangeCoordinates: ({ lat, lng }: { lat: number; lng: number }) => void;
  getCoordinates: () => void;
  mapKey: string;
}

export const ISSAutocomplete = ({ onChangeCoordinates, getCoordinates, mapKey }: Props) => {
  const [positionName, setPositionName] = useState<string>('');
  const [lat, setLat] = useState<number>(null);
  const [lng, setLng] = useState<number>(null);
  const { ref } = usePlacesWidget({
    apiKey: mapKey,
    onPlaceSelected: (place) => {
      setLat(place?.geometry?.location.lat());
      setLng(place?.geometry?.location.lng());
      // onChangeCoordinates({ lat, lng });
      setPositionName(place.formatted_address);
    },
  });

  const onChange = (value: string) => {
    setPositionName(value);
  };
  useEffect(() => {
    if (lat && lng) {
      onChangeCoordinates({ lat, lng });
    }
  }, [lat, lng]);

  return (
    <Box>
      <MobileInput
        label={`${t('position_edit_site')}`}
        name="position"
        error={false}
        helperText={''}
        onChange={(value) => onChange(value.target.value)}
        value={positionName}
        inputRef={ref}
        search
        placeholder={''}
        endAdornment={
          <InputAdornment sx={{ cursor: 'point' }} position="start" onClick={getCoordinates}>
            <Box component="img" src={position} />
          </InputAdornment>
        }
      />
    </Box>
  );
};
