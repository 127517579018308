import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { MobileButton, MobileInput } from '../../ui';
import { BaseCheckbox, Icon, Row, Text } from 'shared/ui';
import { t } from 'i18next';
import { useAppSelector } from 'shared/hooks';
import { QuantityAndPrice } from '../MaterialsAndPricingTable';
import { CheckBox } from '@mui/icons-material';
import { MaterialLists } from '../../ISSModels';
import { Label } from 'recharts';
import Layout from '../Layout';
import { backArrow } from '../../assets';
import { ConfirmModal } from '../ConfirmModal';
import localStorageService from 'shared/localStorage';

interface Props {
  closeMaterials: () => void;
  localSiteMaterials: QuantityAndPrice[];
  saveMaterials: (localSiteMaterials: QuantityAndPrice[]) => void;
  currentProjectMLFormat: number;
}

export const AddMaterialsModal: FC<Props> = ({
  closeMaterials,
  saveMaterials,
  localSiteMaterials,
  currentProjectMLFormat,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [newSiteMaterials, setNewSiteMaterials] = useState<QuantityAndPrice[]>([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [changedList, setChangedList] = useState<boolean>(false);
  const onChangeSearch = (value: string) => {
    setSearchText(value);
  };
  const searchQuantityAndPrice = (filteredItems: MaterialLists[], searchText: string) => {
    const searched = filteredItems.filter((c) => {
      const values = Object.values(c);

      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim()));
    });

    return searched;
  };
  const { isRTL } = localStorageService;
  useEffect(() => {
    setNewSiteMaterials(localSiteMaterials);
  }, [localSiteMaterials]);

  const { materialLists } = useAppSelector((st) => st.ISSSlice);
  const filteredQuantityAndPrice: MaterialLists[] = useMemo(() => {
    const filteredItems = materialLists.filter((item) => item.materialListFormatID == currentProjectMLFormat);
    const searchedItems = searchQuantityAndPrice(filteredItems, searchText);

    return searchedItems;
  }, [searchText, materialLists, currentProjectMLFormat]);

  const changeCheckboxStatus = (e: ChangeEvent<HTMLInputElement>, itemID: number) => {
    setChangedList(true);
    const newLocalSiteMaterials: QuantityAndPrice[] = [...newSiteMaterials];
    if (!e.target.checked) {
      const index = newLocalSiteMaterials.findIndex((item) => item.id === itemID);
      newLocalSiteMaterials.splice(index, 1);
      setNewSiteMaterials(newLocalSiteMaterials);
    } else {
      const material = materialLists.find((item) => item.id === itemID);
      const newMaterial = {
        quantity: 0,
        materialCode: material.materialCode,
        id: material.id,
        unitType: material.unitType,
        shortDescription: material.shortDescription,
        unitCost: material.unitCost,
      };
      newLocalSiteMaterials.push(newMaterial);
      setNewSiteMaterials(newLocalSiteMaterials);
    }
  };
  const handleNavigateConfirmed = () => {
    closeMaterials();
  };
  const handleBackButton = () => {
    if (changedList) {
      setShowModalConfirm(true);
    } else {
      closeMaterials();
    }
  };
  const saveSiteMaterials = () => {
    saveMaterials(newSiteMaterials);
    closeMaterials();
  };
  return (
    <Box
      sx={{
        position: 'fixed',

        height: '100vh',
        width: '100%',
        top: '0px',
        left: '0px',
        backgroundColor: 'white',
        zIndex: '500',
      }}
    >
      <Layout>
        <Box sx={{ padding: '24px 24px' }}>
          <Row
            sx={{
              justifyContent: 'space-between',
              flexDirection: isRTL ? 'row-reverse' : 'row',
              background: 'white',
              width: '100%',
              top: 0,
              cursor: 'pointer',
              maxWidth: '900px',
            }}
          >
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#F5F5F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleBackButton}
            >
              <Icon src={backArrow} />
            </Box>

            <Text
              sx={{
                width: 'calc(100% - 80px)',
                color: 'black',
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: 'bold',
              }}
            >
              {t('add_materials_title')}
            </Text>
          </Row>
          <></>
          <MobileInput
            label={`${t('materials_and_pricing_table')}`}
            name="requiredChanges"
            error={false}
            helperText={''}
            onChange={(value) => onChangeSearch(value.target.value)}
            value={searchText}
            search
            placeholder={t('materials_and_pricing_table_placeholder')}
          />
          <Box sx={{ height: 'calc(100vh - 320px)', overflowX: 'hidden', overflowY: 'auto' }}>
            <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
              <TableHead>
                <TableRow sx={{ background: '#FAFAFA' }}>
                  <TableCell sx={{ height: '60px', padding: '0px' }}></TableCell>
                  <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' } }}>
                    <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                      {t('code_material_table')}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' } }}>
                    <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                      {' '}
                      {t('description_material_table')}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ height: '60px', paddingRight: '8px', padding: { xs: '6px', sm: '12px' } }}>
                    <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                      {' '}
                      {t('unit_material_table')}
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredQuantityAndPrice.map((item, index) => (
                  <TableRow key={`key-${item.id}-${index}`} sx={{ background: index % 2 ? '#FAFAFA' : 'white' }}>
                    <TableCell sx={{ height: '60px', padding: '0px', paddingLeft: '8px', position: 'relative' }}>
                      <BaseCheckbox
                        value={!!newSiteMaterials.find((el) => el.id === item.id)}
                        onChange={(e) => changeCheckboxStatus(e, item.id)}
                        label={''}
                      />
                    </TableCell>
                    <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, position: 'relative' }}>
                      <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                        {item.materialCode}
                      </Text>
                    </TableCell>
                    <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' } }}>
                      <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                        {item.shortDescription}
                      </Text>
                    </TableCell>
                    <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' } }}>
                      <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                        {item.unitType}
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <MobileButton btnColor="#1C5B9C" onClick={saveSiteMaterials}>
            <Text>{t('save_list_material')}</Text>
          </MobileButton>
        </Box>
      </Layout>
      <ConfirmModal
        cancel={() => setShowModalConfirm(false)}
        confirm={() => handleNavigateConfirmed()}
        title={t('leave_without_saving_changes')}
        okText={t('yes_confirm_modal')}
        show={showModalConfirm}
      />
    </Box>
  );
};
