import { WAMessageStatus } from 'features/WAMessageStatusManager/WAMessageStatusModels';
import { useTranslation } from 'react-i18next';
import { Kvish6ControllersData } from '../Kvish6Models';
import { Icon } from 'shared/ui';
import { door_close, door_open } from 'shared/assets';
interface Props {
  row?: Kvish6ControllersData;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'True':
      return <Icon src={door_close} />;
    case 'False':
      return <Icon src={door_open} />;
  }
  return '';
};
export const IsOpenDoor = ({ row }: Props) => {
  return <>{getStatusIcon(row.isDoorClose_RT)}</>;
};
