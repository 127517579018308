import { useAppDispatch } from 'shared/hooks';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
export const useFilterControllers = () => {
  const setQueryParam = (key, value) =>
    setSearchParams(
      (prevState) => {
        if (!value) {
          prevState.delete(key);
          return prevState;
        }

        const currentValue = prevState.get(key);
        if (!currentValue && !value) return prevState;
        prevState.set(key, value);
        return prevState;
      },
      { replace: true }
    );

  const { t } = useExtendedTranslation();
  const { customers, customerTypes } = useAppSelector((st) => st.customers);
  const { modemTypes, modemBoardTypes } = useAppSelector((st) => st.dashboardDetails);
  const [changedFilter, setChangedFilter] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const customersFilterItems = customers.map((s) => ({
    value: s.id.toString(),
    label: s.customerName,
  }));
  const modelFilterItems = [
    {
      value: 'DM',
      label: `${t('DM_model_type')} - ${t('dam_model_description')}`,
    },
    {
      value: 'CR',
      label: `${t('CR_model_type')} - ${t('computer_room_model_description')}`,
    },
    {
      value: 'NV',
      label: `${t('NV_model_type')} - ${t('general_controller_model_description')}`,
    },
    {
      value: 'WE',
      label: `${t('WE_model_type')} - ${t('meteorological_station_model_description')}`,
    },
    {
      value: 'IP',
      label: `${t('IP_model_type')} - ${t('network_protection_model_description')}`,
    },
    {
      value: 'LT',
      label: `${t('LT_model_type')} - ${t('lighting_controller_model_description')}`,
    },
    {
      value: 'IA',
      label: `${t('IA_model_type')} - ${t('fountain_model_description')}`,
    },
    {
      value: 'I1',
      label: `${t('I1_model_type')} - ${t('irrigation_controller_model_description')}`,
    },
    {
      value: 'SG',
      label: `${t('SG_model_type')} - ${t('prismatic_sign_model_description')}`,
    },
  ];
  const deviceTypeFilterItems = [
    {
      value: 'AFIK',
      label: `${t('AFIK_device_type_item')}`,
    },
    {
      value: 'CG_AFIK_IP',
      label: `${t('CG_AFIK_IP_device_type_item')}`,
    },
    // {
    //   value: 'CG_MIT_IP',
    //   label: `${t('CG_MIT_IP_device_type_item')}` ,
    // },
    {
      value: 'OAZIS',
      label: `${t('OAZIS_device_type_item')}`,
    },
    // {
    //   value: 'FT',
    //   label: `${t('FT_device_type_item')}` ,
    // },
    {
      value: 'LT',
      label: `${t('LT_device_type_item')}`,
    },
    // {
    //   value: 'CG_OTHER',
    //   label: `${t('CG_OTHER_device_type_item')}` ,
    // },
    {
      value: 'T1',
      label: `${t('T1_device_type_item')}`,
    },
    {
      value: 'T3',
      label: `${t('T3_device_type_item')}`,
    },
    {
      value: 'OTHER',
      label: `${t('OTHER_ChartItem_DeviceType')}`,
    },
  ];

  const deviceStatusItems = [
    {
      value: 'active',
      label: `${t('active_device_status_item')}`,
    },
    {
      value: 'disabled',
      label: `${t('disabled_device_status_item')}`,
    },
  ];

  const cellularProviderFilterItems = [
    {
      value: 'CELLCOM',
      label: `${t('CELLCOM_cellular_provider_item')}`,
    },
    {
      value: 'PELEPHONE',
      label: `${t('PELEPHONE_cellular_provider_item')}`,
    },
    {
      value: 'PARTNER',
      label: `${t('PARTNER_cellular_provider_item')}`,
    },
    {
      value: 'MONOGOTO',
      label: `${t('MONOGOTO_cellular_provider_item')}`,
    },
    {
      value: 'OTHER',
      label: `${t('OTHER_cellular_provider_item')}`,
    },
  ];
  const typeFilterItems = customerTypes.map((t) => ({ value: t.customerTypeID.toString(), label: t.customerType }));

  const modemTypesItems = modemTypes.map((value) => ({ value, label: t(`${value}`) }));
  const modemBoardTypesItems = modemBoardTypes.map((value) => ({ value, label: t(`${value}`) }));

  const modelFilter = searchParams.get('modelFilter') ? searchParams.get('modelFilter').split(',') : [];
  const deviceTypeFilter = searchParams.get('deviceTypes') ? searchParams.get('deviceTypes').split(',') : [];
  const startLastCommDate = searchParams.get('startLastCommDate');
  const endLastCommDate = searchParams.get('endLastCommDate');
  const startInstallDate = searchParams.get('startInstallDate');
  const endInstallDate = searchParams.get('endInstallDate');
  const cellularProvider = searchParams.get('cellularProvider') || '';
  const customersFilter = searchParams.get('customerIdList') ? searchParams.get('customerIdList').split(',') : [];
  const deviceStatusFilter = searchParams.get('deviceStatus') || '';
  const typeFilter = searchParams.get('customerType') || '';
  const modemTypesFilter = searchParams.get('modemTypes') ? searchParams.get('modemTypes').split(',') : [];
  const modemBoardTypesFilter = searchParams.get('modemBoardTypes')
    ? searchParams.get('modemBoardTypes').split(',')
    : [];

  const onChangeModelFilter = (values: string[]) => setQueryParam('modelFilter', values.toString());
  const onChangeDeviceTypeFilter = (values: string[]) => setQueryParam('deviceTypes', values.toString());
  const onChangeStartLastCommDate = (value: string) => setQueryParam('startLastCommDate', value);
  const onChangeEndLastCommDate = (value: string) => setQueryParam('endLastCommDate', value);
  const onChangeStartInstallDate = (value: string) => setQueryParam('startInstallDate', value);
  const onChangeEndInstallDate = (value: string) => setQueryParam('endInstallDate', value);
  const onChangeCellularProvider = (value: string) => setQueryParam('cellularProvider', value);
  const onChangeCustomersFilter = (values: string[]) => {
    //  onChangeTypeFilter(null);
    setQueryParam('customerIdList', values.toString());
  };
  const onChangeDeviceStatusFilter = (value: string) => setQueryParam('deviceStatus', value);
  const onChangeTypeFilter = (value: string) => setQueryParam('customerType', value);
  const onChangeModemTypesItems = (values: string[]) => setQueryParam('modemTypes', values.toString());
  const onChangeModemBoardTypesItems = (values: string[]) => setQueryParam('modemBoardTypes', values.toString());

  return {
    customersFilterItems,
    customersFilter,
    onChangeCustomersFilter,
    modelFilter,
    modelFilterItems,
    onChangeModelFilter,
    onChangeDeviceTypeFilter,
    deviceTypeFilterItems,
    deviceTypeFilter,
    startLastCommDate,
    onChangeStartLastCommDate,
    onChangeStartInstallDate,
    onChangeEndInstallDate,
    startInstallDate,
    endInstallDate,
    endLastCommDate,
    onChangeEndLastCommDate,
    deviceStatusFilter,
    onChangeDeviceStatusFilter,
    deviceStatusItems,
    typeFilter,
    onChangeTypeFilter,
    typeFilterItems,
    cellularProvider,
    onChangeCellularProvider,
    cellularProviderFilterItems,

    modemTypesItems,
    onChangeModemTypesItems,
    modemTypesFilter,

    modemBoardTypesItems,
    onChangeModemBoardTypesItems,
    modemBoardTypesFilter,
    changedFilter,
    setChangedFilter,
    setQueryParam,
  };
};
