import { WAMessageStatus } from 'features/WAMessageStatusManager/WAMessageStatusModels';
import { useTranslation } from 'react-i18next';
import { Kvish6ControllersData } from '../Kvish6Models';
import { Icon } from 'shared/ui';
import { lamp_green, lamp_red, lamp_yellow } from 'shared/assets';
interface Props {
  row?: Kvish6ControllersData;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'True':
      return <Icon src={lamp_green} />;
    case 'False':
      return '';
  }
  return '';
};
export const IsLightOn = ({ row }: Props) => {
  return <>{getStatusIcon(row.isLightOn_RT)}</>;
};
