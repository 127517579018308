import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISSPhoto, ISSProject, ISSSite, MaterialListFormat, MaterialLists, SiteMaterials } from './ISSModels';

interface ISSState {
  ISSProjects: ISSProject[];
  ISSSites: ISSSite[];
  ISSPhoto: ISSPhoto[];
  materialLists: MaterialLists[];
  siteMaterials:SiteMaterials[]
  materialListFormats: MaterialListFormat[]
}

const initialState: ISSState = {
  ISSProjects: [],
  ISSSites:[],
  ISSPhoto:[],
  materialLists:[],
  siteMaterials:[],
  materialListFormats:[]
};

const ISSSlice = createSlice({
  name: 'ISS',
  initialState,
  reducers: {
    setISSProjects: (state, action: PayloadAction<ISSProject[]>) => {
      state.ISSProjects = action.payload;
    },
    setISSSites: (state, action: PayloadAction<ISSSite[]>) => {
      state.ISSSites = action.payload;
    },
    setISSPhotos: (state, action: PayloadAction<ISSPhoto[]>) => {
      state.ISSPhoto = action.payload;
    },
    setMaterialLists: (state, action: PayloadAction<MaterialLists[]>)=>{
      state.materialLists = action.payload;
    },
    setSiteMaterials: (state, action: PayloadAction<SiteMaterials[]>)=>{
      state.siteMaterials = action.payload;
    },
    setMaterialListFormats:(state, action: PayloadAction<MaterialListFormat[]>)=>{
      state.materialListFormats = action.payload;
    }
  },
});

export const {
  setISSProjects,
  setISSSites,
  setISSPhotos,
  setMaterialLists,
  setSiteMaterials,
  setMaterialListFormats
} = ISSSlice.actions;

export const ISSReducer = ISSSlice.reducer;
