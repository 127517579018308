import { useAppDispatch, useToast } from 'shared/hooks';

import { ISSPhoto, NewPhotoData, NewPhotosData, UpdateISSPhotoResult } from 'features/Mobile/ISS/ISSModels';
import { setISSPhotos } from 'features/Mobile/ISS/ISSSlice';
import useAxios from 'shared/api/useAxios';

export const useISSPhotos = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showSuccess } = useToast();
  return {
    getISSPhotos: (siteId: string) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'get',
        url: `/iss/photos?siteId=${siteId}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSPhotos(data?.data));
        },
      }),
    getCompressedPhotos: (siteIds: number[]) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'post',
        url: `/iss/compressedPhotos`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSPhotos(data?.data));
        },
        payload: { siteIds },
      }),
    updateISSPhotos: (photosData: NewPhotosData) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'put',
        url: `/iss/photos`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
        payload: photosData,
      }),
    updateISSPhoto: (photosData: NewPhotoData) =>
      request<undefined, { data: UpdateISSPhotoResult }>({
        method: 'put',
        url: `/iss/photo`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
        payload: photosData,
      }),
    deleteISSPhoto: (photoId: number, siteId: number) =>
      request<undefined, { data: { result: boolean } }>({
        method: 'delete',
        url: `/iss/photo`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {},
        payload: {
          siteId,
          id: photoId,
        },
      }),
    isLoadingPhoto: loading,
  };
};
