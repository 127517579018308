import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        overflow: 'hidden',
        maxWidth: '900px',

        margin: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

export default Layout;
