import { useAppDispatch, useToast } from 'shared/hooks';

import {
  ISSPhoto,
  ISSProject,
  ISSSite,
  ISSSiteChanges,
  ISSSiteCreation,
  MaterialListFormat,
  ProjectMaterialListFormat,
  SiteMaterials,
  UpdateDataProject,
} from 'features/Mobile/ISS/ISSModels';
import { setISSProjects, setISSSites, setMaterialListFormats } from 'features/Mobile/ISS/ISSSlice';
import { t } from 'i18next';
import useAxios from 'shared/api/useAxios';

export const useMaterialListFormat = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showSuccess } = useToast();
  return {
    getProjectMaterialListFormats: () =>
      request<undefined, { data: ProjectMaterialListFormat[] }>({
        method: 'get',
        url: `/iss/projectMaterialListFormats`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          //dispatch(setMaterialListFormats(data?.data));
        },
      }),

    getMaterialListFormats: () =>
      request<undefined, { data: MaterialListFormat[] }>({
        method: 'get',
        url: `/iss/materialListFormats`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setMaterialListFormats(data?.data));
        },
      }),

    isLoading: loading,
  };
};
