import { useTranslation } from 'react-i18next';
import {
  AlignmentType,
  Document,
  Packer,
  PageBreak,
  Table,
  TableCell,
  TableRow,
  Paragraph,
  TextRun,
  ImageRun,
  ExternalHyperlink,
} from 'docx';
import { saveAs } from 'file-saver';
import { ISSPhoto, ISSProject, ISSSite } from '../../ISSModels';
import { SiteMaterialsItems } from '../../ISSExport/components/ExportToExcel';
import localStorageService from 'shared/localStorage';
import { TableCellWithText } from './TableCell';
import { formatDtoDate, formatDtoDateTime, getFormattedDateAndTime } from 'shared/lib';

export const useExport = () => {
  const { t } = useTranslation();
  const { isRTL } = localStorageService;
  const currencyFormat = (value: number) => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const exportToWord = async (
    allSites: { data: ISSSite; materials: SiteMaterialsItems[]; images: ISSPhoto[] }[],
    project: ISSProject,
    fileGenerated: () => void
  ) => {
    const paragraphs = allSites.flatMap((site, index) => {
      let total = 0;

      site.materials.forEach((item) => {
        total += parseInt((item.materialCost || 0).toFixed(2));
      });
      const totalDiscount = (total * (project.discount / 100)).toFixed(2);
      const siteInfo = [
        new Paragraph({
          bidirectional: isRTL,
          children: [
            new TextRun({
              text: t('site_name_exported_file'),
              bold: true,
              underline: { type: 'single' },
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),

            new TextRun({
              text: ` ${site.data.siteName}`,
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
          ],
        }),
        new Paragraph({
          bidirectional: isRTL,
          children: [
            new TextRun({
              text: t('latitude_and_longitude_file'),
              bold: true,
              underline: { type: 'single' },
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
            new ExternalHyperlink({
              link: `https://www.google.com/maps?q=${site.data.latitude},${site.data.longitude}`,
              children: [
                new TextRun({
                  text: ` ${site.data.latitude}, ${site.data.longitude}`,
                  style: 'Hyperlink',
                  size: 24,
                  underline: { type: 'none' },
                  rightToLeft: isRTL,
                  font: 'Arial',
                  language: { value: isRTL ? 'he-IL' : 'en' },
                }),
              ],
            }),
          ],
        }),
        new Paragraph({
          bidirectional: isRTL,
          children: [
            new TextRun({
              text: t('priority_exported_file'),
              bold: true,
              underline: { type: 'single' },
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
            new TextRun({
              text: ` ${site.data.priority}`,
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
          ],
        }),

        new Paragraph({
          bidirectional: isRTL,
          children: [
            new TextRun({
              text: t('current_state_exported_file'),
              bold: true,
              underline: { type: 'single' },
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
            new TextRun({
              text: `${t(`${site.data.currentState}`)}`,
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
          ],
        }),
        new Paragraph({
          bidirectional: isRTL,
          children: [
            new TextRun({
              text: t('required_changes_exported_file'),
              bold: true,
              underline: { type: 'single' },
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
            new TextRun({
              text: ` ${site.data.requiredChanges}`,
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
          ],
        }),
        new Paragraph({
          bidirectional: isRTL,
          children: [
            new TextRun({
              text: t('description_exported_file'),
              bold: true,
              underline: { type: 'single' },
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
            new TextRun({
              text: ` ${site.data.description}`,
              size: 24,
              rightToLeft: isRTL,
              font: 'Arial',
              language: { value: isRTL ? 'he-IL' : 'en' },
            }),
          ],
        }),
      ];

      const tableRows = site.materials
        .filter((item) => item.quantity > 0)
        .map(
          (material) =>
            new TableRow({
              children: [
                TableCellWithText(currencyFormat(material.materialCost), isRTL),
                TableCellWithText(currencyFormat(material.unitCost), isRTL),
                TableCellWithText(material.quantity.toString(), isRTL),
                TableCellWithText(material.unitType, isRTL),
                TableCellWithText(material.longDescription, isRTL),
                TableCellWithText(material.materialCode, isRTL),
              ],
            })
        );

      const additionalRows = [
        new TableRow({
          children: [
            TableCellWithText(currencyFormat(total), isRTL),
            TableCellWithText('', isRTL),
            new TableCell({
              children: [
                new Paragraph({
                  bidirectional: isRTL,
                  children: [
                    new TextRun({
                      text: t('total_table_exported_file'),
                      rightToLeft: isRTL,
                      language: { value: isRTL ? 'he-IL' : 'en' },
                      font: 'Arial',
                    }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
            new TableCell({
              children: [],
              borders: {
                top: { style: 'single', size: 1, color: 'FFFFFF' },
                bottom: { style: 'single', size: 1, color: 'FFFFFF' },
                left: { style: 'single', size: 1, color: 'FFFFFF' },
                right: { style: 'single', size: 1, color: 'FFFFFF' },
              },
            }),
            new TableCell({
              children: [],
              borders: {
                top: { style: 'single', size: 1, color: 'FFFFFF' },
                bottom: { style: 'single', size: 1, color: 'FFFFFF' },
                left: { style: 'single', size: 1, color: 'FFFFFF' },
                right: { style: 'single', size: 1, color: 'FFFFFF' },
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            TableCellWithText(currencyFormat(parseFloat(totalDiscount)), isRTL),
            TableCellWithText('', isRTL),
            TableCellWithText(project.discount.toString(), isRTL),
            TableCellWithText(t('discount_table_exported_file'), isRTL),
            new TableCell({
              children: [],
              borders: {
                top: { style: 'single', size: 1, color: 'FFFFFF' },
                bottom: { style: 'single', size: 1, color: 'FFFFFF' },
                left: { style: 'single', size: 1, color: 'FFFFFF' },
                right: { style: 'single', size: 1, color: 'FFFFFF' },
              },
            }),
            new TableCell({
              children: [],
              borders: {
                top: { style: 'single', size: 1, color: 'FFFFFF' },
                bottom: { style: 'single', size: 1, color: 'FFFFFF' },
                left: { style: 'single', size: 1, color: 'FFFFFF' },
                right: { style: 'single', size: 1, color: 'FFFFFF' },
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            TableCellWithText(currencyFormat(total - parseFloat(totalDiscount)), isRTL),
            TableCellWithText('', isRTL),

            new TableCell({
              children: [
                new Paragraph({
                  bidirectional: isRTL,
                  children: [
                    new TextRun({
                      text: t('total_after_discount_table_exported_file'),
                      rightToLeft: isRTL,
                      language: { value: isRTL ? 'he-IL' : 'en' },
                      font: 'Arial',
                    }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
            new TableCell({
              children: [],
              borders: {
                top: { style: 'single', size: 1, color: 'FFFFFF' },
                bottom: { style: 'single', size: 1, color: 'FFFFFF' },
                left: { style: 'single', size: 1, color: 'FFFFFF' },
                right: { style: 'single', size: 1, color: 'FFFFFF' },
              },
            }),
            new TableCell({
              children: [],
              borders: {
                top: { style: 'single', size: 1, color: 'FFFFFF' },
                bottom: { style: 'single', size: 1, color: 'FFFFFF' },
                left: { style: 'single', size: 1, color: 'FFFFFF' },
                right: { style: 'single', size: 1, color: 'FFFFFF' },
              },
            }),
          ],
        }),
      ];

      const table = new Table({
        rows: [
          new TableRow({
            children: [
              TableCellWithText(t('material_cost_table_exported_file'), isRTL),
              TableCellWithText(t('unit_cost_table_exported_file'), isRTL),
              TableCellWithText(t('quantity_table_exported_file'), isRTL),
              TableCellWithText(t('unit_type_table_exported_file'), isRTL),
              TableCellWithText(t('long_description_table_exported_file'), isRTL, true),
              TableCellWithText(t('material_code_table_exported_file'), isRTL),
            ],
          }),
          ...tableRows,
          ...additionalRows,
        ],
      });

      const imageParagraph = [];
      const imagesPerRow = 2;
      let rowImages = [];

      const base64ToArrayBuffer = (base64: string) => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      };

      const getImageTransformation = (orientation: number, width: number, height: number) => {
        switch (orientation) {
          case 6:
            return { width: height, height: width, rotation: 90 };
          case 3:
            return { width, height, rotation: 180 };
          case 8:
            return { width: height, height: width, rotation: 270 };
          case 1:
          default:
            return { width, height, rotation: 0 };
        }
      };

      if (site.images && site.images.length) {
        try {
          site.images.forEach((image, index) => {
            const maxWidth = 270;
            const scaledWidth = maxWidth;
            const scaledHeight = (image.height / image.width) * scaledWidth;
            console.log('orient', image.orientation);
            const imageBuffer = base64ToArrayBuffer(image.photoData);

            if (index < 1) {
              imageParagraph.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '.'.repeat(150),
                      color: 'ffFFFF',
                      font: 'Arial',
                      size: 20,
                    }),
                  ],
                  bidirectional: isRTL,
                })
              );

              rowImages = [];
            }

            rowImages.push(
              new TextRun({
                text: '.'.repeat(2),
                color: 'ffFFFF',
                font: 'Arial',
                size: 20,
              })
            );
            rowImages.push(
              new ImageRun({
                type: 'jpg',
                data: imageBuffer,
                transformation: getImageTransformation(image.orientation, scaledWidth, scaledHeight),
              })
            );

            rowImages.push(
              new TextRun({
                text: '.'.repeat(2),
                color: 'ffFFFF',
                font: 'Arial',
                size: 20,
              })
            );

            if (index === 1 || index === 3) {
              rowImages.push(
                new TextRun({
                  text: '.'.repeat(150),
                  color: 'ffFFFF',
                  font: 'Arial',
                  size: 20,
                })
              );
            }
            if (rowImages.length == imagesPerRow * 3 + 1 || index === site.images.length - 1) {
              imageParagraph.push(
                new Paragraph({
                  children: rowImages,
                  bidirectional: isRTL,
                })
              );

              rowImages = [];
            }
          });
        } catch (error) {
          console.error('load error:', error);
        }
      }

      return [
        ...siteInfo,
        new Paragraph({ children: [] }),
        table,
        ...imageParagraph,
        new Paragraph({ children: [new PageBreak()] }),
      ];
    });
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: paragraphs,
        },
      ],
    });

    await Packer.toBlob(doc).then(async (blob) => {
      const currentDate = formatDtoDateTime(Date.now());

      await saveAs(blob, `${project.projectName}_${currentDate}.docx`);
      fileGenerated();
      return true;
    });
  };

  return { exportToWord };
};
