import { Box } from '@mui/material';
import { FC } from 'react';
import { Icon, Text } from 'shared/ui';

interface Props {
  iconSrc: string;
  activeIcon: string;
  text: string;
  active?: boolean;
  iconMargin?: string;
  handleClick?: () => void;
}

export const MenuButton: FC<Props> = ({ iconSrc, text, active, iconMargin, activeIcon, handleClick }) => {
  return (
    <Box
      onClick={handleClick}
      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', minWidth: '62px' }}
    >
      <Box textAlign={'center'} height={'20px'}>
        {active ? (
          <Icon sx={{ margin: iconMargin ? iconMargin : '0px' }} src={activeIcon}></Icon>
        ) : (
          <Icon sx={{ margin: iconMargin ? iconMargin : '0px' }} src={iconSrc}></Icon>
        )}
      </Box>
      <Box textAlign={'center'}>
        <Text sx={{ color: '#9DB2CE' }}>{text}</Text>
      </Box>
    </Box>
  );
};
