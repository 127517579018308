import { Box } from '@mui/material';
import { Row, Text, Icon } from '../../../../shared/ui';
import { useExtendedTranslation } from 'shared/hooks';
import { LoginForm } from '../Components/';
import { Background } from '../ui';
import { mainLogoIcon } from '../assets';
import Layout from '../Components/Layout';

export const ISSLoginPage = () => {
  const { t } = useExtendedTranslation();
  return (
    <Box>
      <Layout>
        <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
          <Box sx={{ textAlign: 'center' }}>
            <Icon src={mainLogoIcon} />
          </Box>
          <Text
            sx={{
              color: 'primary.main',
              fontFamily: 'Noto Sans Hebrew',
              mb: '20px',
              fontSize: '40px',

              textAlign: 'center',
            }}
          >
            {t('irrigation_site_survey_header_text')}
          </Text>
          <Row justifyContent="center">
            <LoginForm hideTitle={true} />
          </Row>
        </Box>
        <Background login={true} />
      </Layout>
    </Box>
  );
};
