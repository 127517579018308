import { Box, InputAdornment, useTheme } from '@mui/material';
import { BaseInput } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, MutableRefObject } from 'react';
import { searchIcon } from '../../assets';

type Props = {
  label: string;
  name: string;
  error: boolean;
  helperText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blur?: (value: string) => void;
  value: string | number;
  sx?: SxProps;
  type?: string;
  valueForNumberChanging?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
  search?: boolean;
  endAdornment?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  autoHeight?: boolean;
  updated?: boolean;
  compact?: boolean;
  startAdornment?: React.ReactNode;
  fullWidth?: boolean;
};
export const MobileInput: FC<Props> = ({
  sx = {},
  search,
  endAdornment,
  startAdornment,
  autoHeight,
  updated,
  fullWidth,
  ...props
}) => {
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';
  return (
    <BaseInput
      fullWidth={fullWidth}
      sx={{
        borderRadius: '8px',
        width: '100%',
        height: autoHeight ? 'auto' : '40px',
        '& .MuiOutlinedInput-root': {
          '& > fieldset.MuiOutlinedInput-notchedOutline': {
            border: updated ? `1px solid green` : `1px solid primary.dark`,
          },
        },
        ...sx,
      }}
      sxLabel={{
        color: '#212529',
      }}
      isTextDirectionRtl={isRtl}
      endAdornment={endAdornment}
      autoHeight={autoHeight}
      startAdornment={
        search ? (
          <InputAdornment position="start">
            <Box component="img" src={searchIcon} />
          </InputAdornment>
        ) : startAdornment ? (
          startAdornment
        ) : (
          <></>
        )
      }
      {...props}
    />
  );
};
