import { Box } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { BaseBtn, Icon, Loader, Row, Text } from 'shared/ui';
import { deletePhotoIcon, galleryIcon, makePhoto } from '../../assets';
import { NewPhotoData } from '../../ISSModels';
import { useISSPhotos } from '../../hooks';
import { ConfirmModal } from '../ConfirmModal';
import { ImageGallery } from './components';
interface PhotoData {
  data_url: string;
  id: number;
}
interface Props {
  siteId: string;
  photoList: PhotoData[];
  changeImages: (photoList: PhotoData[]) => void;
}

export const PhotoEdit = ({ siteId, photoList, changeImages }: Props) => {
  const { updateISSPhoto, deleteISSPhoto, isLoadingPhoto } = useISSPhotos();
  const [images, setImages] = useState([]);
  const [imageAddedSuccessfully, setImageAddedSuccessfully] = useState(null);
  const [imageAddedError, setImageAddedError] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [photoDeleteId, setPhotoDeleteId] = useState(null);
  const [imageDeleteError, setImageDeleteError] = useState(null);
  const [imageDeleteSuccessfully, setImageDeleteSuccessfully] = useState(null);
  const [showImageID, setShowImageID] = useState<number>(null);
  const maxNumber = 5;

  useEffect(() => {
    setImages(photoList);
  }, [photoList]);

  const clearMessage = () => {
    setImageAddedSuccessfully(false);
    setImageAddedError(false);
    setImageDeleteSuccessfully(false);
    setImageDeleteError(false);
  };

  const onChange = async (imageList) => {
    clearMessage();
    const newPhoto: NewPhotoData = {
      siteID: parseInt(siteId),
      photoData: imageList[0].data_url,
    };
    const result = await updateISSPhoto(newPhoto);
    if (result) {
      const newImage = imageList[0];
      newImage.id = result.data.id;
      setImages([...images, newImage]);
      setImageAddedSuccessfully(true);
      setImageAddedError(false);
      return;
    }
    setImageAddedSuccessfully(false);
    setImageAddedError(true);
  };
  const handleDeletePhoto = (id: number) => {
    setPhotoDeleteId(id);
    setShowModalConfirm(true);
  };

  const cancelDeletePhoto = () => {
    setPhotoDeleteId(null);
    setShowModalConfirm(false);
  };

  const deletePhoto = async (id: number) => {
    clearMessage();
    setShowModalConfirm(false);
    const result = await deleteISSPhoto(id, parseInt(siteId));
    if (result) {
      setImages(images.filter((item) => item.id != id));
      setImageDeleteSuccessfully(true);
      setImageDeleteError(false);
      return;
    }
    setImageDeleteSuccessfully(false);
    setImageDeleteError(true);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoadingPhoto && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: '#ffffff',
            zIndex: '30',
            position: 'fixed',
            top: '0px',
            opacity: '0.7',
            left: '0',
          }}
        >
          <Loader />
        </Box>
      )}

      <Box className="App" sx={{ opacity: isLoadingPhoto ? 0.5 : 1, marginTop: '16px', marginBottom: '16px' }}>
        <Row spacing={'4px'} sx={{ alignItems: 'flex-end' }}>
          <Text sx={{ color: 'black', mt: '4px' }}>{`${t('site_photos_title_edit_site')}`}</Text>
          <Text sx={{ color: 'black', mt: '4px' }}>({images.length})</Text>
        </Row>
        <Row spacing={'4px'} sx={{ alignItems: 'flex-end' }}>
          {imageAddedError && <Text sx={{ color: 'red', mt: '4px' }}>{t('site_photos_error_upload')}</Text>}
          {imageAddedSuccessfully && (
            <Text sx={{ color: 'green', mt: '4px' }}>{t('site_photos_successfully_added')}</Text>
          )}
          {imageDeleteError && <Text sx={{ color: 'red', mt: '4px' }}>{t('site_photos_error_delete')}</Text>}
          {imageDeleteSuccessfully && (
            <Text sx={{ color: 'green', mt: '4px' }}>{t('site_photos_successfully_delete')}</Text>
          )}
        </Row>
        <ImageUploading multiple={false} value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
          {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
            <Box sx={{ maxWidth: '100%' }}>
              <Row sx={{ padding: '4px', display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {imageList.length < 5 && (
                  <Box
                    onClick={() => (isLoadingPhoto ? {} : document.getElementById('cameraInput')?.click())}
                    sx={{
                      flex: '1 1 calc(33.33% - 16px)',
                      minHeight: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F8F9FA',
                      boxShadow: '0px 1px 3px 0px #0000001A, 0px 1px 2px 0px #0000001A',
                      borderRadius: '8px',
                    }}
                  >
                    <Icon sx={{ mx: '8px' }} src={makePhoto} />
                  </Box>
                )}
                {imageList.length < 5 && (
                  <Box
                    onClick={() => (isLoadingPhoto ? {} : onImageUpload())}
                    sx={{
                      flex: '1 1 calc(33.33% - 16px)',
                      minHeight: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F8F9FA',
                      boxShadow: '0px 1px 3px 0px #0000001A, 0px 1px 2px 0px #0000001A',
                      borderRadius: '8px',
                    }}
                  >
                    <Icon sx={{ mx: '8px' }} src={galleryIcon} />
                  </Box>
                )}
                {imageList.map((image, index) => (
                  <Box
                    key={index}
                    className="image-item"
                    sx={{
                      flex: '1 1 calc(33.33% - 16px)',
                      height: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F8F9FA',
                      boxShadow: '0px 1px 3px 0px #0000001A, 0px 1px 2px 0px #0000001A',
                      borderRadius: '8px',
                      position: 'relative',
                    }}
                    onClick={() => setShowImageID(image.id)}
                  >
                    <img
                      style={{ padding: '16px 0px', maxWidth: '100%', maxHeight: '100%' }}
                      height="auto"
                      width="auto"
                      src={image['data_url']}
                      alt=""
                    />
                    <Box
                      sx={{ position: 'absolute', right: '-2px', top: '-2px' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeletePhoto(image.id);
                      }}
                    >
                      <Icon src={deletePhotoIcon} />
                    </Box>
                  </Box>
                ))}
                {Array.from({ length: (3 - ((imageList.length + 2) % 3)) % 3 }).map((_, i) => (
                  <Box
                    key={`empty-${i}`}
                    sx={{
                      flex: '1 1 calc(33.33% - 16px)',
                      height: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F8F9FA',
                      boxShadow: '0px 1px 3px 0px #0000001A, 0px 1px 2px 0px #0000001A',
                      borderRadius: '8px',
                      visibility: 'hidden',
                    }}
                  />
                ))}
              </Row>
            </Box>
          )}
        </ImageUploading>

        <input
          id="cameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files?.length) {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target?.result) {
                  const newImage = [{ data_url: event.target.result as string }];
                  onChange(newImage);
                  // setImages((prev) => [...prev, newImage]);
                  // changeImages([...images, newImage]);
                }
              };
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
        <ConfirmModal
          cancel={cancelDeletePhoto}
          confirm={() => deletePhoto(photoDeleteId)}
          title={t('are_you_shure_delete_picture')}
          show={showModalConfirm}
        />
        {showImageID && (
          <ImageGallery imageId={showImageID} photoList={images} changeSelectedImage={(id) => setShowImageID(id)} />
        )}
      </Box>
    </Box>
  );
};
