import { Box } from '@mui/material';
import { BaseBtn, Icon, Loader, Row, Text } from '../../../../shared/ui';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useISSData } from '../hooks';
import { BaseSelect } from 'features/BaseSelect';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import { Background, MobileButton, MobileInput } from '../ui';
import { ProjectCard } from './components/ProjectCard';
import { plusWhiteIcon } from '../assets';
import Layout from '../Components/Layout';

export const ISSProjectPage = () => {
  const { t } = useExtendedTranslation();
  const { getISSProjects, isLoading } = useISSData();
  const { ISSProjects, ISSSites, ISSPhoto } = useAppSelector((st) => st.ISSSlice);
  const [searchText, setSearchText] = useState<string>('');

  const push = useNavigate();

  const photos = useMemo(
    () =>
      ISSPhoto.map((s) => {
        return `data:image/jpeg;base64,${s.photoData}`;
      }),
    [ISSPhoto]
  );
  useEffect(() => {
    getISSProjects();
  }, []);

  const handleSelectedProject = (id: number) => {
    push(`/mobile/iss/sites?projectId=${id}`);
  };
  const onChangeSearch = (value: string) => {
    setSearchText(value);
  };
  const handleAddProject = () => {
    push(`/mobile/iss/newProject`);
  };

  return (
    <Box>
      <Layout>
        <Header title={t('irrigation_system_survey')} hideBackButton />
        <Box sx={{ position: 'relative' }}>
          {isLoading && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
                zIndex: '30',
                position: 'absolute',
                top: '0px',
                opacity: '0.9',
                left: '0',
              }}
            >
              <Loader />
            </Box>
          )}
          <Box sx={{ padding: '0 24px' }}>
            <Row justifyContent={'space-between'} alignItems={'flex-end'} sx={{ mb: '16px' }}>
              <Text sx={{ color: 'black', fontSize: '20px' }}>{t('projects_list_project_page')}</Text>
              <Box width="40px"></Box>
              <MobileButton btnColor="#1C5B9C" sx={{ width: '50%', height: '40px' }} onClick={handleAddProject}>
                <Icon height={'10px'} sx={{ mx: '4px' }} src={plusWhiteIcon} />
                <Text>{`${t('add_project_site_page')}`}</Text>
              </MobileButton>
            </Row>
            <Box>
              <MobileInput
                label={``}
                name="search"
                error={false}
                helperText={''}
                onChange={(value) => onChangeSearch(value.target.value)}
                value={searchText}
                search
                compact
                placeholder={''}
              />
              <Row></Row>
              {ISSProjects.length ? (
                <Box sx={{ marginTop: '16px' }}>
                  {ISSProjects.map((item, index) => (
                    <Box key={`key-${item.id}`}>
                      <ProjectCard project={item} handleClick={() => handleSelectedProject(item.id)} />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        </Box>
        <Background />
        <Footer showPlus handlePlusClick={handleAddProject} />
      </Layout>
    </Box>
  );
};
