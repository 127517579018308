export const useCalculateRowsWidth = (tableTitles) => {
  const calculateUnitWidth = (
    tableRef,
    checkboxSelection: boolean,
    actions: boolean,
    options: boolean,
    defaultScroll: number
  ) => {
    const scroolWidth = defaultScroll ? defaultScroll : 10;
    const actionsWidth = actions ? 60 : 0;
    const optionsWidth = options ? 80 : 0;
    const checkboxWidth = checkboxSelection ? 60 : 0;
    let screenWidth = window.innerWidth;
    if (tableRef && tableRef.current) {
      screenWidth = tableRef.current.clientWidth - scroolWidth - checkboxWidth - actionsWidth - optionsWidth;
    }
    let sumOfStaticWidth = 0;
    let sumOfPercentWidth = 0;
    tableTitles.forEach((title) => {
      if (typeof title.width === 'number') {
        sumOfStaticWidth += title.width;
      } else if (typeof title.width === 'string') {
        sumOfPercentWidth += parseInt(title.width);
        return 20;
      }
    });

    const dinamicWidth = screenWidth - sumOfStaticWidth;
    const unitWidth = dinamicWidth / sumOfPercentWidth;
    return unitWidth;
  };

  const calculateWidthInPixels = (width: number | string, unitWidth: number): number => {
    if (typeof width === 'number') {
      return width;
    } else if (typeof width === 'string') {
      return parseInt(width) * unitWidth;
    }
    return 100;
  };

  return {
    calculateUnitWidth,
    calculateWidthInPixels,
  };
};
