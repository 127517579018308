import { Routing } from '../pages';
import { AuthWrapper, ToastWrapper, ScrollToTop } from '../processes';
import { withProviders } from './providers';
function App() {
  return (
    <AuthWrapper>
      <ToastWrapper>
        <ScrollToTop />
        <Routing />
      </ToastWrapper>
    </AuthWrapper>
  );
}

export default withProviders(App);
