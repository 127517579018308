import { Box } from '@mui/material';

import { t } from 'i18next';
import { Header } from '../Components/Header';
import { UnderConstructionBlock } from 'entities_';
import { Background, MobileButton, MobileInput } from '../ui';
import { Footer } from '../Components/Footer';
import { Row, Text } from 'shared/ui';
import { useState } from 'react';
import { useISSData } from '../hooks';
import { useToast } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';
import Layout from '../Components/Layout';

export const NewProject = () => {
  const [projectName, setProjectName] = useState('');
  const { createProject } = useISSData();
  const onChangeProjectName = (value: string) => {
    setProjectName(value);
  };
  const push = useNavigate();
  const { showSuccess } = useToast();
  const createProjectName = async () => {
    const result = await createProject(projectName);
    if (result.succeeded) {
      showSuccess(t('project_successfully_created_toast_text'));
    }

    const newProjectId = result.data.id;
    push(`/mobile/iss/sites?projectId=${newProjectId}`);
  };
  return (
    <Box>
      <Layout>
        <Header title={t('title_new_project_page')} withConfirm={!!projectName} />
        <Box sx={{ padding: '0 24px' }}>
          <Row alignItems={'flex-end'}>
            <MobileInput
              label={`${t('project_name_label_project_management')}`}
              name="project_name"
              error={false}
              helperText={''}
              onChange={(value) => onChangeProjectName(value.target.value)}
              value={projectName}
              compact
              placeholder={''}
              fullWidth
            />
            <Box width="20px"></Box>
            <MobileButton
              disabled={!projectName.trim()}
              btnColor="#1C5B9C"
              sx={{ width: '160px', height: '40px' }}
              onClick={createProjectName}
            >
              <Text>{`${t('save_project_name_project_management')}`}</Text>
            </MobileButton>
          </Row>
        </Box>
        <Background />
        <Footer withConfirm={!!projectName} />
      </Layout>
    </Box>
  );
};
