import { Box } from '@mui/material';
import { FC } from 'react';
import { MobileButton } from '../../ui';
import { Row, Text } from 'shared/ui';
import { t } from 'i18next';
interface Props {
  show: boolean;
  title: string;
  confirm: () => void;
  cancel: () => void;
  okText?: string;
}

export const ConfirmModal: FC<Props> = ({ okText, show, title, confirm, cancel }) => {
  return (
    show && (
      <Box
        sx={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.2)',
          display: 'block',
          zIndex: '700',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '320px',
            height: '160px',
            margin: 'auto',
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            background: 'white',
            padding: '8px',
            border: '1px solid #1C5B9C',
            borderRadius: '16px',
          }}
        >
          <Text
            sx={{ color: 'black', textAlign: 'center', paddingTop: '16px', paddingBottom: '20px', fontSize: '14px' }}
          >
            {title}
          </Text>
          <Row spacing={'16px'} alignItems={'flex-end'}>
            <MobileButton sx={{ width: '50%' }} btnColor="info.main" onClick={cancel}>
              <Text sx={{ color: 'black' }}>{`${t('cancel_confirm_modal')}`}</Text>
            </MobileButton>

            <MobileButton sx={{ width: '50%' }} btnColor="#1C5B9C" onClick={confirm}>
              <Text>{okText ? okText : `${t('delete_confirm_modal')}`}</Text>
            </MobileButton>
          </Row>
        </Box>
      </Box>
    )
  );
};
