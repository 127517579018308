import { useTranslation } from 'react-i18next';
import { SiteMaterialsItems } from '../ExportToExcel';
export const useFormatXlsxData = () => {
  const { t } = useTranslation();

  const formatTable = (arr: SiteMaterialsItems[]) => {
    const exportArr = arr.map((item) => {
      return {
        [t('siteName_export_table')]: item.siteName,
        [t('currentState_export_table')]: t(item.currentState),
        [t('priority_export_table')]: item.priority,
        [t('totalPrice_export_table')]: item.totalPrice,
      };
    });

    return exportArr;
  };

  return { formatTable };
};
