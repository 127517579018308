export const LANG: { value: string; label: string }[] = [
  {
    value: 'en-iss',
    label: 'EN',
  },
  {
    value: 'he-iss',
    label: 'HE',
  },
  {
    value: 'ar-SY-iss',
    label: 'SY',
  },
];
