import { Box } from '@mui/material';
import { useISSPhotos } from 'features/Mobile/ISS/hooks';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { galleryIcon } from 'shared/assets';
import { BaseBtn, Icon, Loader, Row, Text } from 'shared/ui';

interface Props {
  customerPhoto: string;
  customerId: number;
  isLoadingPhoto: boolean;
  changeImage: (img: PhotoData) => void;
}
export interface PhotoData {
  data_url: string;
  id: number;
}
export const PhotoEdit = ({ customerPhoto, changeImage, customerId, isLoadingPhoto }: Props) => {
  const [images, setImages] = useState<PhotoData[]>([]);
  const [imageAddedError, setImageAddedError] = useState(null);

  const maxNumber = 1;

  const clearMessage = () => {
    setImageAddedError(false);
  };

  useEffect(() => {
    if (customerPhoto) {
      setImages([
        {
          data_url: `data:image/jpeg;base64,${customerPhoto}`,
          id: 1,
        },
      ]);
    } else {
      setImages([]);
    }
  }, [customerPhoto]);

  const onChange = async (imageList) => {
    clearMessage();
    const newPhoto = {
      id: customerId,
      data_url: imageList[0].data_url,
    };
    changeImage(newPhoto);
    setImages([
      {
        data_url: imageList[0].data_url,
        id: 1,
      },
    ]);
  };
  const maxFileSize = 10 * 1024 * 1024; // 10MB
  const handleFileChange = (e) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      if (file.size > maxFileSize) {
        setImageAddedError(true);
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          const newImage = [{ data_url: event.target.result as string }];
          onChange(newImage);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Box sx={{ position: 'relative' }}>
      {isLoadingPhoto && (
        <Box
          sx={{
            width: '100%',
            height: '180px',
            background: '#ffffff',
            zIndex: '30',
            position: 'absolute',
            top: '0px',
            opacity: '0.7',
            left: '0',
          }}
        >
          <Loader />
        </Box>
      )}
      <Box className="App" sx={{ opacity: isLoadingPhoto ? 0.5 : 1, marginTop: '16px', marginBottom: '16px' }}>
        <Row spacing={'4px'} sx={{ alignItems: 'flex-end' }}>
          {imageAddedError && <Text sx={{ color: 'red', mt: '4px' }}>{t('max_file_size_10_photos_error_upload')}</Text>}
        </Row>
        <ImageUploading multiple={false} value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
          {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
            <Box sx={{ maxWidth: '100%' }}>
              <Row sx={{ padding: '4px', display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {imageList.length < 1 && (
                  <Box
                    onClick={() => (isLoadingPhoto ? {} : document.getElementById('cameraInput')?.click())}
                    sx={{
                      flex: '1 1 calc(33.33% - 16px)',
                      minHeight: '100px',
                      height: '180px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F8F9FA',
                      border: '1px solid ',
                      borderColor: 'primary.dark',
                      borderRadius: '8px',
                    }}
                  >
                    <Icon sx={{ mx: '8px' }} src={galleryIcon} />
                  </Box>
                )}

                {imageList.length ? (
                  <Box
                    className="image-item"
                    sx={{
                      flex: '1 1 calc(33.33% - 16px)',
                      height: '180px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F8F9FA',
                      border: '1px solid ',
                      borderColor: 'primary.dark',
                      borderRadius: '8px',
                      position: 'relative',
                    }}
                  >
                    <img
                      style={{ padding: '16px 0px', maxWidth: '100%', maxHeight: '100%' }}
                      height="auto"
                      width="auto"
                      src={images[0]['data_url']}
                      alt=""
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Row>

              {images.length ? (
                <BaseBtn
                  sx={{ mt: '14px' }}
                  onClick={() => (isLoadingPhoto ? {} : document.getElementById('cameraInput')?.click())}
                  btnColor="primary.dark"
                  fullWidth
                >
                  {t('update_logo_edit_modal')}
                </BaseBtn>
              ) : (
                <BaseBtn
                  onClick={() => (isLoadingPhoto ? {} : document.getElementById('cameraInput')?.click())}
                  sx={{ mt: '14px' }}
                  btnColor="primary.dark"
                  fullWidth
                >
                  {t('add_logo_edit_modal')}
                </BaseBtn>
              )}
            </Box>
          )}
        </ImageUploading>

        <input
          id="cameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </Box>
    </Box>
  );
};
