import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { isHebrewFont } from 'shared/lib';

interface Props extends ButtonProps {
  loading?: boolean;
  btnColor?: string;
}

export const BaseBtn: FC<Props> = ({ loading, children, sx, onClick, type, disabled, fullWidth, btnColor }) => {
  const isHebrew = isHebrewFont(children?.toString() ?? '');
  return (
    <Button
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      disableFocusRipple
      sx={{
        fontFamily: isHebrew ? 'Noto Sans Hebrew' : 'Inter',
        textTransform: 'capitalize',
        borderRadius: '10px',
        height: '40px',
        px: '15px',
        color: btnColor === 'info.main' ? 'primary.dark' : 'info.main',
        borderStyle: `solid`,
        borderWidth: `1px`,
        borderColor: btnColor === 'info.main' || !btnColor ? 'primary.dark' : btnColor,
        bgcolor: btnColor ?? 'primary.dark',
        transition: 'all 0.3s',
        '&:hover': {
          bgcolor: btnColor ?? 'primary.dark',
          boxShadow: '0px 0px 4px #245AA5',
        },
        '&:disabled': {
          color: btnColor === 'info.main' ? 'primary.dark' : 'info.main',
          opacity: loading ? 1 : 0.5,
        },
        ...sx,
      }}
    >
      {loading ? (
        <CircularProgress size={25} sx={{ color: btnColor === 'info.main' ? 'primary.dark' : 'info.main' }} />
      ) : (
        children
      )}
    </Button>
  );
};
