import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, CustomerSelect, PagePreloader } from 'components';
import { BaseDataPicker } from 'features/BaseDataPicker';
import { Filter } from 'features/Filter';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { action_start, refresh } from 'shared/assets';
import { useAppSelector, useToast } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { BaseBtn, BigTitle, Icon, Row, SearchInput, TotalRows, Text } from 'shared/ui';
import { WAMessageStatus } from './WAMessageStatusModels';
import { DeleteMessages, WaMessageStatus } from './components';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { useDeleteWAMessages, useFilter, useFormatXlsxData, useSearch, useWAMessageStatus } from './hooks';
export const WAMessageStatusManager = () => {
  const { showError, showSuccess } = useToast();
  const { searchValue, changeSearch } = useSearch();
  const { getWAMessageStatus, isLoading } = useWAMessageStatus();
  const { deleteWAMessages, deleteInProgress } = useDeleteWAMessages();
  const { wAMessageStatuses } = useAppSelector((st) => st.wAMessageStatus);
  const { isRTL } = localStorageService;
  useEffect(() => {
    const fetchData = () => {
      const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      getWAMessageStatus(dateFrom, dateTo, timezoneOffsetInMinutes);
    };
    fetchData();
    return () => {
      onChangeCustomerFilter('');
    };
  }, []);

  const [selectedMessagesID, setSelectedMessagesID] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {
    customerFilter,
    onChangeCustomerFilter,
    statusFilter,
    onChangeStatusFilter,
    statusFilterItems,
    onChangeDateTo,
    onChangeDateFrom,
    dateFrom,
    dateTo,
    actionsFilter,
    onChangeActionsFilter,
    actionsFilterItems,
  } = useFilter();
  const { formatTable } = useFormatXlsxData();

  const onChangeDateToAndUpdate = (value: string) => {
    onChangeDateTo(value);
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
    getWAMessageStatus(dateFrom, value, timezoneOffsetInMinutes);
  };
  const onChangeDateFromAndUpdate = (value: string) => {
    onChangeDateFrom(value);
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
    getWAMessageStatus(value, dateTo, timezoneOffsetInMinutes);
  };

  const getSearchedWAMessageStatuses = (wAMessageStatuses: WAMessageStatus[], searchValue: string) => {
    const searched = wAMessageStatuses.filter((c) => {
      const values = Object.values(c);
      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });
    return searched;
  };

  const filteredWAMessageStatuses = useMemo(() => {
    const serchedWAMessageStatuses = getSearchedWAMessageStatuses(wAMessageStatuses, searchValue);
    let filteredWAMessageStatuses = serchedWAMessageStatuses.filter((item) =>
      customerFilter ? item.customerID.toString() === customerFilter : true
    );
    filteredWAMessageStatuses = filteredWAMessageStatuses.filter((item) =>
      statusFilter ? item.status === statusFilter : true
    );
    return filteredWAMessageStatuses;
  }, [searchValue, wAMessageStatuses, customerFilter, statusFilter]);

  const rowSelectionModelChange = useCallback((rows) => {
    setSelectedMessagesID(rows);
  }, []);

  const onClickActionStart = () => {
    if (actionsFilter === '1') {
      setOpenDeleteModal(true);
      return;
    }
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    return;
  };
  const handleDelete = async () => {
    const result = await deleteWAMessages(selectedMessagesID);
    if (result.data.success) {
      setOpenDeleteModal(false);
      showSuccess(t('messages_deleted_toast_text'));
      const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      getWAMessageStatus(dateFrom, dateTo, timezoneOffsetInMinutes);
      return;
    }

    // const sendResult = JSON.parse(result.data.sendResult);
    // showError();
  };
  const reloadData = () => {
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
    getWAMessageStatus(dateFrom, dateTo, timezoneOffsetInMinutes);
  };

  const BaseExportToExcelComponent = useMemo(
    () => (
      <BaseExportToExcel
        tableData={filteredWAMessageStatuses}
        sheetName="Message statuses"
        fileName="message_status.xlsx"
        buttonText={t('message_status_export_btn')}
        formattedTable={formatTable(filteredWAMessageStatuses)}
        tableTitles={TABLE_TITLES}
        headerItemsPrefix={'_wa_message_status_user_grid_table'}
        stringColumnIndexes={[6]}
      />
    ),
    [filteredWAMessageStatuses]
  );

  return (
    <>
      <PagePreloader loading={isLoading} />
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('wa_message_status_manager_title')}</BigTitle>
        <Row sx={{ flexGrow: 1 }} />
        <BaseBtn onClick={reloadData} sx={{ mt: '16px', height: '29px' }}>
          <Row>
            <Icon src={refresh} />
            <Text
              noWrap
              sx={{
                fontSize: '14px',
                height: '24px',
                margin: '0 4px',
                fontFamily: 'Noto Sans Hebrew !@important',
              }}
            >
              {t('reload_wa_message_status')}
            </Text>
          </Row>
        </BaseBtn>
      </Row>
      <SearchInput
        value={searchValue}
        onChange={(e) => changeSearch(e.target.value)}
        placeholder={`${t('search_wa_message_status_management_page')}`}
      />
      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <CustomerSelect
          title={`${t('customer_label')}`}
          value={customerFilter}
          onChange={onChangeCustomerFilter}
          hideAllButton
          showHomeButton
          showOnlyResetButton
          withSearch
          sx={{ width: '220px' }}
        />
        <Box sx={{ mr: '10px' }}>
          <BaseDataPicker
            label={`${t('date_From_wa_message_status_filter_label')}`}
            name="createdDate"
            value={dateFrom}
            onChange={(name, value) => {
              onChangeDateFromAndUpdate(value);
            }}
            error={null}
            clearIcon
          />
        </Box>
        {/* <Icon src={arrow_right} sx={{ py: '7px', mr: '10px', transform: isRTL ? 'rotate(180deg)' : 'rotate(0deg)' }} /> */}
        <Box sx={{ mr: '10px', fontWeight: 700, fontSize: '20px', color: '#888' }}>{t('arrow_symbol')}</Box>
        <Box sx={{ mr: '20px' }}>
          <BaseDataPicker
            label={`${t('date_to_wa_message_status_filter_label')}`}
            name="createdDate"
            value={dateTo}
            onChange={(name, value) => {
              onChangeDateToAndUpdate(value);
            }}
            error={null}
            clearIcon
          />
        </Box>
        <Filter
          title={`${t('wa_message_status_filter')}`}
          value={statusFilter}
          onChange={onChangeStatusFilter}
          items={statusFilterItems}
          hideAllButton
          sx={{ width: '220px' }}
          messageEmptyFilter={`${t('please_select_an_wa_message_status')}`}
          placeholder={`${t('status_filter_search_wa_message_status__placeholder')}`}
        />
        <TotalRows count={selectedMessagesID.length} />
        <Filter
          title={`${t('actions_filter')}`}
          value={actionsFilter}
          onChange={onChangeActionsFilter}
          items={actionsFilterItems}
          hideAllButton
          sx={{ width: '220px' }}
          messageEmptyFilter={`${t('actions_please_select_an_action')}`}
          placeholder={`${t('filter_search_controller_page_placeholder')}`}
        />
        <BaseBtn
          onClick={onClickActionStart}
          disabled={!actionsFilter || !selectedMessagesID.length}
          sx={{ mt: '16px', height: '29px' }}
        >
          {t('controllers_action_start')}
          <Icon sx={{ ml: '6px' }} src={action_start} />
        </BaseBtn>
        <Row sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
          <TotalRows count={filteredWAMessageStatuses.length} />

          {BaseExportToExcelComponent}
        </Box>
      </Row>
      <Box>
        <BaseTable
          tableTitles={TABLE_TITLES}
          tableItems={filteredWAMessageStatuses}
          loadedItems={true}
          idField={'id'}
          dateTimeColumnIndexes={[0, 3]}
          noItemsText={t('no_message_statuses_grid_table')}
          headerItemsPrefix={'_wa_message_status_user_grid_table'}
          sortModel={[{ field: 'createdAt', sort: 'desc' }]}
          translateColumns={TRANSLATE_COLUMNS}
          checkboxSelection
          onRowSelectionModelChange={rowSelectionModelChange}
          additionalContent={[{ index: 2, content: (props) => <WaMessageStatus {...props} /> }]}
          loading={isLoading}
          stringColumnIndexes={[6]}
        />
      </Box>
      <DeleteMessages
        openDeleteModal={openDeleteModal}
        onCloseModal={onCloseDeleteModal}
        handleDelete={handleDelete}
        loading={deleteInProgress}
        selectedMessages={selectedMessagesID.length}
      />
    </>
  );
};
