import { FC, useState, useRef, useEffect } from 'react';
import { Box, FormControl, Popover, RadioGroup } from '@mui/material';
import { CustomFormControlLabel, Text } from 'shared/ui';
import { Icon } from 'shared/ui';
import { MobileInput, MobileSelect } from 'features/Mobile/ISS/ui';
import { chevron } from 'features/Mobile/ISS/assets';
import { QUANTITY } from '../../constants';
import localStorageService from 'shared/localStorage';

interface EditableCellProps {
  value: number;
  loading: boolean;
  onChange: (newValue: number) => void;
}

export const EditableCell: FC<EditableCellProps> = ({ value, loading, onChange }) => {
  const [currentValue, setCurrentValue] = useState<string>(value.toString());
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null); // Реф для MobileInput
  //const { isRTL } = localStorageService;
  // useEffect(() => {
  //   if (showEdit && inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [showEdit]);

  const finishEditQuantity = () => {
    setShowEdit(false);
    const newValue = currentValue.replace(/[^0-9]/g, '');
    if (newValue) {
      onChange(parseInt(newValue));
      setCurrentValue(newValue);
    }
  };

  const selectItem = (value: string) => {
    setShowEdit(false);
    onChange(parseInt(value));
    setCurrentValue(value);
  };

  return (
    <Box>
      {showEdit ? (
        <Box>
          <Box
            onClick={() => setShowEdit(false)}
            sx={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.2)',
              display: 'block',
              zIndex: '1',
            }}
          ></Box>
          <Box
            sx={{
              zIndex: '2',
              position: 'absolute',
              top: '0px',
              width: '45px',
              left: '8px',
              marginTop: '-6px',

              //right: '8px',
            }}
          >
            <MobileInput
              label={``}
              name="Quantity"
              error={false}
              helperText={''}
              onChange={(value) => setCurrentValue(value.target.value)}
              blur={finishEditQuantity}
              value={currentValue}
              inputRef={inputRef}
              sx={{
                background: 'white',
                padding: '0px',
                px: '2px',
                height: '32px',
                border: '1px solid white',
                borderRadius: '8px 8px 0 0',
                '& fieldset': {
                  borderColor: `#00000000 !important`,
                },
                '& input': {
                  padding: '0',
                },
                fontSize: '12px',
              }}
            />
            <Box>
              <Box>
                <Box sx={{ borderTop: '1px solid rgba(199, 199, 199, 1)' }}>
                  {
                    <FormControl sx={{ width: '100%' }}>
                      <RadioGroup value={value} sx={{ width: '100%' }}>
                        <>
                          <Box
                            sx={{
                              maxHeight: '500px',
                            }}
                          >
                            {QUANTITY.map(({ value, label }) => (
                              <CustomFormControlLabel
                                key={value}
                                onChange={() => selectItem(value)}
                                value={currentValue}
                                labels={{ label }}
                                sx={{ background: 'white', width: '45px', ml: '0', mr: '0', px: '6px' }}
                                hideValue
                              />
                            ))}
                          </Box>
                        </>
                      </RadioGroup>
                    </FormControl>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <MobileSelect
            title={''}
            items={QUANTITY}
            noSelectLabel={''}
            onChange={(value) => setCurrentValue(value)}
            value={currentValue}
            translate
          /> */}
        </Box>
      ) : (
        <Box
          sx={{
            height: '20px',
            py: '16px',
            px: '6px',
            boxShadow: '0px 1px 3px 0px #0000001A,  0px 1px 2px 0px #0000001A',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '45px',
            overflow: 'hidden',
            background: 'white',
            fontSize: '12px',
          }}
          onClick={() => (loading ? {} : setShowEdit(true))}
        >
          <Box sx={{ width: '45px' }}>
            <Text sx={{ color: 'secondary.dark', fontSize: '12px' }}>{value}</Text>
          </Box>
          <Icon src={chevron} />
        </Box>
      )}
    </Box>
  );
};
