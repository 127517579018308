import { Box } from '@mui/material';
import { BaseMultiselect, BaseTable } from 'components';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { refresh } from 'shared/assets';
import { BaseBtn, Icon, Loader, Row, SearchInput, Text } from 'shared/ui';
import { useFilter } from './hooks/useFilter';
import { useAppSelector, useToast } from 'shared/hooks';
import { useWeatherStationsManager } from 'features/WeatherStationsManager/hooks/api';
import { Filter } from 'features';
import { TABLE_TITLES } from './constants';
import { relative } from 'path';
import { LinkedControllersConfirm } from './components/LinkedControllersConfirm';
import { IrrigationControllerDataForUpdate } from 'features/WeatherStationsManager/WeatherStationModels';
interface Props {
  customerID: number;
  objectID: number;
  isChangedIrrigationControllers: (status: boolean) => void;
}

export const IrrigationControllers: FC<Props> = ({ customerID, objectID, isChangedIrrigationControllers }) => {
  const { showSuccess } = useToast();
  const { getGeographicZone, getIrrigationControllers, updateIrrigationControllers, loading } =
    useWeatherStationsManager();

  const handleOpenUpdateIrrigationControllers = async () => {
    if (!controllersForUpdate.length) {
      //getIrrigationControllers(objectID, customerID);
      return;
    }
    const success = await updateIrrigationControllers(controllersForUpdate);

    if (success.data.result) {
      await getIrrigationControllers(objectID, customerID);
      showSuccess(t('update_successful_toast_text'));
      setApprovedIrrigationControllers([]);
    }
  };

  const { irrigationControllers } = useAppSelector((st) => st.weatherStation);

  const [searchValue, setSearchValue] = useState('');
  const [selectedIrrigationControllers, setSelectedIrrigationControllers] = useState([]);
  const [activeIrrigationControllers, setActiveIrrigationControllers] = useState([]);
  const [approvedIrrigationControllers, setApprovedIrrigationControllers] = useState([]);
  const [linkedAndSelectedControllersForApprove, setLinkedAndSelectedControllersForApprove] = useState([]);
  const [highlightCheckboxes, setHighlightCheckboxes] = useState([]);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const {
    onChangeGeographicZone,
    geographicZoneFilter,
    geographicZoneItems,
    irrigationControllerStatus,
    onChangeIrrigationControllerStatus,
    irrigationControllerStatusItems,
    getSearchedIrrigationController,
  } = useFilter();

  useEffect(() => {
    getGeographicZone(customerID);
    getIrrigationControllers(objectID, customerID);
  }, [customerID]);

  useEffect(() => {
    if (irrigationControllers) {
      const selectedControllers = [];
      const highlightCheckboxes = [];
      irrigationControllers.forEach((item) => {
        if (item.statusEx == 1) {
          selectedControllers.push(item.objectID);
        }
        if (item.statusEx == -1) {
          highlightCheckboxes.push(item.objectID);
        }
      });
      setActiveIrrigationControllers(selectedControllers);
      setSelectedIrrigationControllers(selectedControllers);
      setHighlightCheckboxes(highlightCheckboxes);
    }
  }, [irrigationControllers]);

  const controllersForUpdate = useMemo(() => {
    const controllers = irrigationControllers.filter((controller) => {
      const isSelected = selectedIrrigationControllers.includes(controller.objectID);
      if ([-1, 0, null].includes(controller.statusEx) && isSelected) {
        return true;
      }

      if (controller.statusEx > 0 && !isSelected) {
        return true;
      }

      return false;
    });

    const controllersForUpdate: IrrigationControllerDataForUpdate[] = controllers.map((item) => {
      const isActive = selectedIrrigationControllers.includes(item.objectID);
      return {
        objectID: customerID,
        status: isActive ? 1 : 0,
        weObjectID: objectID,
        i1ObjectID: item.objectID,
      };
    });
    return controllersForUpdate;
  }, [irrigationControllers, selectedIrrigationControllers, customerID, objectID]);

  useEffect(() => {
    isChangedIrrigationControllers(!controllersForUpdate.length);
  }, [controllersForUpdate]);

  const filteredIrrigationControllers = useMemo(() => {
    if (!irrigationControllers) return [];

    setActiveIrrigationControllers(selectedIrrigationControllers);

    let filteredItems = irrigationControllers.filter((c) => geographicZoneFilter.includes(c?.zoneID?.toString()));
    if (!geographicZoneFilter.length) {
      filteredItems = [...irrigationControllers];
    }

    filteredItems = irrigationControllerStatus
      ? filteredItems.filter((c) => {
          if (irrigationControllerStatus === '1' && (c?.statusEx == 1 || c?.statusEx == -1)) {
            return true;
          }
          if (irrigationControllerStatus === '0' && !c?.statusEx) {
            return true;
          }
        })
      : filteredItems;
    const searchedCustomers = getSearchedIrrigationController(filteredItems, searchValue);

    const searchedCustomersWithHiddenNames = searchedCustomers.map((item) => {
      return {
        ...item,
        wE_ObjectName: item.statusEx ? item.wE_ObjectName : '',
      };
    });

    return searchedCustomersWithHiddenNames;
  }, [searchValue, geographicZoneFilter, irrigationControllerStatus, irrigationControllers]);

  const checkAndSelectControllers = (selected: number[]) => {
    const showedIds = filteredIrrigationControllers.map((item) => item.objectID);
    const newSelected = selected.filter((id) => showedIds.includes(id));
    const newUnselected = showedIds.filter((id) => !selected.includes(id));
    const localSelectedIrrigationControllers = [...selectedIrrigationControllers];
    newSelected.forEach((id) => {
      if (!localSelectedIrrigationControllers.includes(id)) {
        localSelectedIrrigationControllers.push(id);
      }
    });
    const changedSelectedIrrigationControllers = localSelectedIrrigationControllers.filter(
      (id) => !newUnselected.includes(id)
    );
    //
    setSelectedIrrigationControllers(changedSelectedIrrigationControllers);
  };

  const rowSelectionModelChange = (selected: number[]) => {
    const notApprovedControllers = highlightCheckboxes.filter((id) => !approvedIrrigationControllers.includes(id));
    const linkedAndSelected = notApprovedControllers.filter((value) => selected.includes(value));
    const allSelectedExist = selected.every((id) => activeIrrigationControllers.includes(id));
    if (linkedAndSelected.length && !allSelectedExist) {
      setLinkedAndSelectedControllersForApprove(selected);
      //setActiveIrrigationControllers(selected);

      return;
    }
    setActiveIrrigationControllers(selected);

    checkAndSelectControllers(selected);
  };

  const cancelSelectLinkedControllers = () => {
    setLinkedAndSelectedControllersForApprove([]);
  };
  const confirmSelectLinkedControllers = () => {
    const newApprovedIrrigationControllers = Array.from(
      new Set([...approvedIrrigationControllers, ...linkedAndSelectedControllersForApprove])
    );

    setApprovedIrrigationControllers(newApprovedIrrigationControllers);

    const newSelectedIrrigationControllers = Array.from(
      new Set([...activeIrrigationControllers, ...linkedAndSelectedControllersForApprove])
    );
    setActiveIrrigationControllers(newSelectedIrrigationControllers);
    checkAndSelectControllers(newSelectedIrrigationControllers);
    setLinkedAndSelectedControllersForApprove([]);
  };

  return (
    <>
      <Box>
        <Row spacing="20px" alignItems="end" justifyContent={'space-between'}>
          <Box>
            <Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>
              {t('irrigation_controllers_meteorological_station_modal')}
            </Text>
          </Box>
          <BaseBtn
            onClick={(e) => {
              handleOpenUpdateIrrigationControllers();
            }}
            sx={{ height: '29px' }}
            disabled={!controllersForUpdate.length}
          >
            {`${t('update_max_evaporation_rates')}`}
          </BaseBtn>
        </Row>
        <Row alignItems="end" sx={{ margin: '8px 0' }}>
          <SearchInput
            value={searchValue}
            onChange={handleChangeSearch}
            placeholder={`${t('search_irrigation_controllers')}`}
          />
        </Row>
        <Row spacing="20px" alignItems="end">
          <Text sx={{ fontSize: '20px', m: 0, fontWeight: 900, color: 'primary.dark' }}>
            {t('filters_details_title')}
          </Text>
          <Box>
            <Row spacing="20px" alignItems="end">
              <Box sx={{ minWidth: '40%' }}>
                <BaseMultiselect
                  title={`${t('geographic_zone_label')}`}
                  values={geographicZoneFilter}
                  onChange={onChangeGeographicZone}
                  // applyFilter={onSelectFilter}
                  items={geographicZoneItems}
                  withSearch
                  showTooltip
                  width={103}
                  sx={{ marginRight: '0px', width: '180px', maxWidth: '180px', minWidth: '180px' }}
                />
              </Box>
              <Box sx={{ minWidth: '40%' }}>
                <Filter
                  title={`${t('irrigation_controller_status_label')}`}
                  value={irrigationControllerStatus}
                  onChange={(value) => {
                    onChangeIrrigationControllerStatus(value);
                  }}
                  items={irrigationControllerStatusItems}
                  fullWidth
                  messageEmptyFilter="display_all_irrigation_controller_status"
                  marginRight={'0px'}
                  sx={{ width: '180px' }}
                />
              </Box>
            </Row>
          </Box>
        </Row>
        <Box height={'350px'} sx={{ overflow: 'hidden', width: '100%', position: 'relative' }}>
          {loading && (
            <Box
              sx={{
                background: '#ffffffcc',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
                position: 'absolute',
                zIndex: 50,
              }}
            >
              <Loader />
            </Box>
          )}
          <BaseTable
            tableTitles={TABLE_TITLES}
            //translateColumns={TRANSLATE_COLUMNS}
            tableItems={filteredIrrigationControllers}
            loadedItems={true}
            idField={'objectID'}
            noItemsText={t('no_irrigation_controllers')}
            headerItemsPrefix={'_irrigation_controllers_grid_table'}
            onRowSelectionModelChange={rowSelectionModelChange}
            selectedIDs={activeIrrigationControllers}
            highlightCheckboxes={highlightCheckboxes}
            maxHeight={'300px'}
            checkboxSelection
            defaultScrollWidth={32}
          />
        </Box>
      </Box>
      <LinkedControllersConfirm
        openModal={!!linkedAndSelectedControllersForApprove.length}
        onCloseModal={cancelSelectLinkedControllers}
        confirmed={confirmSelectLinkedControllers}
      />
    </>
  );
};
