import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import localStorageService from 'shared/localStorage';
import { Icon, Row, Text } from 'shared/ui';
import { deleteIcon, iconDeleteRed, plusIcon } from '../../assets';
import { MobileInput, SwipeComponent } from '../../ui';
import { MobileSwitch } from '../../ui/MobileSwitch';
import { ConfirmModal } from '../ConfirmModal';
import { EditableCell } from './components';

interface Props {
  loading?: boolean;
  sx?: SxProps;
  onChangeSiteMaterials: (newMaterialLists: QuantityAndPrice[]) => void;
  localSiteMaterials: QuantityAndPrice[];
}
export interface QuantityAndPrice {
  quantity: number;
  id: number;
  unitCost: number;
  materialCode: string;
  shortDescription: string;
  unitType: string;
}

export const MaterialsAndPricingTable: FC<Props> = ({
  sx = {},
  onChangeSiteMaterials,
  localSiteMaterials,
  loading,
  ...props
}) => {
  const [totalCost, setTotalCost] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [showDeleteElemId, setShowDeleteElemId] = useState<number>(null);
  const [showAllMaterials, setShowAllMaterials] = useState<boolean>(true);

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalDeleteRows, setShowModalDeleteRows] = useState(false);

  const [materialDeleteId, setMaterialDeleteId] = useState(null);

  useEffect(() => {
    const newTotalCost = localSiteMaterials.reduce((sum, item) => sum + item.quantity * item.unitCost, 0);
    setTotalCost(newTotalCost);
    //  onChangeSiteMaterials(localSiteMaterials);
  }, [localSiteMaterials]);

  const onChangeQuantity = (value: number, id: number) => {
    const newLocalSiteMaterials = [...localSiteMaterials];
    const index = newLocalSiteMaterials.findIndex((elem) => elem.id === id);
    newLocalSiteMaterials[index].quantity = value;
    onChangeSiteMaterials(newLocalSiteMaterials);
  };

  const onChangeSearch = (value: string) => {
    setSearchText(value);
  };

  const searchQuantityAndPrice = (filteredItems: QuantityAndPrice[], searchText: string) => {
    const searched = filteredItems.filter((c) => {
      const values = Object.values(c);

      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim()));
    });

    return searched;
  };

  const filteredQuantityAndPrice: QuantityAndPrice[] = useMemo(() => {
    const filteredItems = showAllMaterials
      ? localSiteMaterials
      : localSiteMaterials.filter((item) => item.quantity > 0);
    const searchedItems = searchQuantityAndPrice(filteredItems, searchText);

    return searchedItems;
  }, [searchText, showAllMaterials, localSiteMaterials]);

  const handleSwiped = (id: number) => {
    if (loading) return;
    if (showDeleteElemId === id) {
      setShowDeleteElemId(null);
      return;
    }
    setShowDeleteElemId(id);
  };

  const deleteMaterial = (id: number) => {
    const newLocalSiteMaterials: QuantityAndPrice[] = [...localSiteMaterials];
    const index = newLocalSiteMaterials.findIndex((item) => item.id === id);
    newLocalSiteMaterials.splice(index, 1);
    onChangeSiteMaterials(newLocalSiteMaterials);
    setShowDeleteElemId(null);
    setShowModalConfirm(false);
    setMaterialDeleteId(null);
  };

  const handleDeleteMaterial = (id: number) => {
    setMaterialDeleteId(id);
    setShowModalConfirm(true);
  };

  const cancelDeleteMaterial = () => {
    setMaterialDeleteId(null);
    setShowModalConfirm(false);
  };

  const onChangeShaw = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowAllMaterials(checked);
    //localStorageService.showAllMaterials = checked;
    //const [showAllMaterials, setShowAllMaterials] = useState<boolean>(false);
  };
  const openModalDeleteRows = () => {
    setShowModalDeleteRows(true);
  };

  const cancelDeleteRows = () => {
    setShowModalDeleteRows(false);
  };
  const deleteRows = () => {
    const newLocalSiteMaterials = localSiteMaterials.filter((item) => item.quantity !== 0);
    onChangeSiteMaterials(newLocalSiteMaterials);
    setShowModalDeleteRows(false);
  };

  return (
    <Box maxWidth={'100%'} sx={{ opacity: loading ? 0.5 : 1 }}>
      <Text sx={{ color: 'black', mt: '16px' }}>{`${t('materials_and_pricing_table')}`}</Text>
      <MobileSwitch
        value={showAllMaterials}
        onChange={onChangeShaw}
        title={`${t('show_all_materials_pricing_table')}`}
        titleSelected={`${t('hide_unused_materials_pricing_table')}`}
      />

      <Row sx={{ cursor: 'pointer' }} onClick={openModalDeleteRows}>
        <Icon src={iconDeleteRed} />
        <Box sx={{ mx: '8px' }}>{t('delete_rows_without_quantity')}</Box>
      </Row>

      <MobileInput
        label={``}
        name="requiredChanges"
        error={false}
        helperText={''}
        onChange={(value) => onChangeSearch(value.target.value)}
        value={searchText}
        search
        placeholder={t('materials_and_pricing_table_placeholder')}
      />
      <Table sx={{ border: '1px solid rgba(224, 224, 224, 1) ', tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow sx={{ background: '#FAFAFA', maxWidth: '100%' }}>
            <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, paddingLeft: '8px', width: '40%' }}>
              <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                {t('code_material_table')}
              </Text>
            </TableCell>
            <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, width: '30%' }}>
              <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                {' '}
                {t('description_material_table')}
              </Text>
            </TableCell>
            <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, width: '10%' }}>
              <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                {' '}
                {t('unit_material_table')}
              </Text>
            </TableCell>
            <TableCell sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, paddingLeft: '8px', width: '20%' }}>
              <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                {' '}
                {t('qty_material_table')}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredQuantityAndPrice.map((item, index) => (
            <TableRow
              key={`key-${item.id}-${index}`}
              sx={{ background: index % 2 ? '#FAFAFA' : 'white', maxWidth: '100%' }}
            >
              <TableCell
                onClick={() => handleSwiped(item.id)}
                sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, position: 'relative', width: '40%' }}
              >
                <Box
                  className={showDeleteElemId === item.id ? 'slide-in' : 'slide-out'}
                  sx={{
                    height: '100%',
                    position: 'absolute',
                    background: 'red',
                    top: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    left: 0,
                    '&.slide-in': {
                      transform: 'translateX(0)',
                      transition: 'transform 0.3s ease-in-out',
                    },

                    '&.slide-out': {
                      transform: 'translateX(-150%)',
                      transition: 'transform 0.3s ease-in-out',
                    },
                  }}
                  onClick={() => handleDeleteMaterial(item.id)}
                >
                  <Box sx={{ height: '18px', mx: '4px' }}>
                    <Icon src={deleteIcon} />
                  </Box>
                  <Text>{t('delete_material_table')}</Text>
                </Box>
                <SwipeComponent swiped={() => handleSwiped(item.id)}>
                  <Text sx={{ color: 'secondary.dark', paddingLeft: '8px', fontSize: { xs: '12px', sm: '14px' } }}>
                    {item.materialCode}
                  </Text>
                </SwipeComponent>
              </TableCell>

              <TableCell
                onClick={() => handleSwiped(item.id)}
                sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, width: '30%' }}
              >
                <SwipeComponent swiped={() => handleSwiped(item.id)}>
                  <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>
                    {item.shortDescription}
                  </Text>
                </SwipeComponent>
              </TableCell>
              <TableCell
                onClick={() => handleSwiped(item.id)}
                sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, width: '10%' }}
              >
                <SwipeComponent swiped={() => handleSwiped(item.id)}>
                  <Text sx={{ color: 'secondary.dark', fontSize: { xs: '12px', sm: '14px' } }}>{item.unitType}</Text>
                </SwipeComponent>
              </TableCell>
              <TableCell
                sx={{ height: '60px', padding: { xs: '6px', sm: '12px' }, position: 'relative', width: '20%' }}
              >
                <EditableCell
                  value={item.quantity}
                  loading={loading}
                  onChange={(value) => onChangeQuantity(value, item.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmModal
        cancel={cancelDeleteMaterial}
        confirm={() => deleteMaterial(materialDeleteId)}
        title={t('are_you_shure_delete_material')}
        show={showModalConfirm}
      />
      <ConfirmModal
        cancel={cancelDeleteRows}
        confirm={deleteRows}
        title={t('are_you_shure_delete_rows_without_quantity')}
        show={showModalDeleteRows}
      />
    </Box>
  );
};
